import { useMemo, useState } from "react";
import clsx from "clsx";
import { Building2, CreditCard, User } from "lucide-react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "saphir";
import { Button, ChoiceBox, ComboBox, fonts, Radio, TextField, Toggle } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { GetCompanyPaymentInfoResponse, updateCompanyPaymentInfo } from "@lib/api";
import env from "@lib/env";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { getLocale } from "@lib/utils/getLocale";
import {
    CardElement,
    Elements,
    IbanElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, SetupIntentResult, StripeElementsOptions } from "@stripe/stripe-js";
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { getBillingCountryOptions } from "./components/billing-countries";
import SaveBar from "./components/partials/SaveBar";
import { ScrollableSettingsContent } from "./components/Settings";

import "./PaymentInformation.module.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

const style = {
    base: {
        "iconColor": "#9aa1aaff", // grey-300,
        "color": "#2c3036ff", // grey-900
        "fontWeight": "500",
        "fontFamily": "Roboto, Open Sans, Segoe UI, sans-serif",
        "fontSize": "16px",
        "fontSmoothing": "antialiased",
        ":-webkit-autofill": {
            color: "#fce883",
        },
        "::placeholder": {
            fontStyle: "italic",
            color: "#9aa1aaff", // grey-300
        },
    },
    invalid: {
        iconColor: "##f23c1cff", // red-500
        color: "##f23c1cff", // red-500
    },
};

export function PaymentInformation() {
    const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
    const { data: paymentInfo } = useSuspenseQuery(
        queries.company.paymentInfo(organisationId ?? ""),
    );

    const options = {
        // passing the client secret obtained from the server
        clientSecret: paymentInfo.clientSecret,
        locale: (localStorage.getItem(`side_team_locale`) as "fr" | "en") || "fr",
    } satisfies StripeElementsOptions;

    return (
        <ScrollableSettingsContent>
            <Elements stripe={stripePromise} options={options}>
                <div className='p-10 text-[var(--sui-grey-900)]'>
                    <h1 className='mb-6 text-[40px] leading-[48px] text-gray-900 typography-heading-xl-semibold'>
                        {i18n.settings_payment_title()}
                    </h1>
                    <PaymentInformationForm paymentInfo={paymentInfo} />
                </div>
            </Elements>
        </ScrollableSettingsContent>
    );
}

function PaymentInformationForm({ paymentInfo }: { paymentInfo: GetCompanyPaymentInfoResponse }) {
    // we define the schema in the component to be able to use I18n.t
    const locale = getLocale();
    const schema = useMemo(() => {
        return z
            .object({
                accountingInfo: z.object({
                    firstName: z.string().min(1, { message: i18n.error_field_is_required() }),
                    lastName: z.string().min(1, { message: i18n.error_field_is_required() }),
                    email: z.string().min(1, { message: i18n.error_field_is_required() }).email(),
                    phoneNumber: z
                        .string()
                        .min(1, { message: i18n.error_field_is_required() })
                        .regex(/[0-9 ]{6,15}/, {
                            message: i18n.settings_payment_error_phone_number_format(),
                        }),
                }),
                billingAddress: z.object({
                    street: z.string().min(1, { message: i18n.error_field_is_required() }),
                    postalCode: z
                        .string()
                        .min(1, { message: i18n.error_field_is_required() })
                        .min(4, { message: i18n.settings_payment_error_postal_code_format() })
                        .max(10, { message: i18n.settings_payment_error_postal_code_format() })
                        .regex(/^[-a-zA-z0-9]+$/, {
                            message: i18n.settings_payment_error_postal_code_format(),
                        }),
                    city: z.string().min(1, { message: i18n.error_field_is_required() }),
                    country: z.string().min(1, { message: i18n.error_field_is_required() }),
                }),
                wantOrderForm: z.boolean(),
                tvaNumber: z.string().optional(),
            })
            .superRefine((val, ctx) => {
                const regex = /[a-zA-Z]{2} [0-9a-zA-Z]{8,12}/;

                if (
                    (val.billingAddress.country !== "FR" && !val.tvaNumber) ||
                    (val.billingAddress.country !== "FR" && !regex.test(val.tvaNumber ?? ""))
                ) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: i18n.settings_payment_error_tva_number_format(),
                        path: ["tvaNumber"],
                    });
                }
            });
    }, [locale]);
    type Inputs = z.infer<typeof schema>;

    const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
    const billingCountryOptions = useMemo(() => getBillingCountryOptions(), []);

    const {
        control,
        register,
        handleSubmit,
        formState: { isDirty, errors },
        watch,
        reset,
    } = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            accountingInfo: {
                firstName: paymentInfo.accountingInfo.firstName || "",
                lastName: paymentInfo.accountingInfo.lastName || "",
                email: paymentInfo.accountingInfo.email || "",
                phoneNumber: paymentInfo.accountingInfo.phoneNumber || "",
            },
            billingAddress: {
                street: paymentInfo.billingAddress.street || "",
                postalCode: paymentInfo.billingAddress.postalCode || "",
                city: paymentInfo.billingAddress.city || "",
                country:
                    billingCountryOptions.find(
                        (opt) => opt.value === paymentInfo.billingAddress.country,
                    )?.value || "",
            },
            tvaNumber: paymentInfo.tvaNumber || undefined,
            wantOrderForm: paymentInfo.wantOrderForm || false,
        },
    });

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: async (args: Parameters<typeof updateCompanyPaymentInfo>[0]["data"]) => {
            const result = await handleStripe();

            let paymentMethodId: string | undefined;
            if (result?.error) {
                toast.error(result.error.message);
                return Promise.reject(result.error.message);
            } else if (result) {
                paymentMethodId = result.setupIntent?.payment_method as string;
            }

            const organisationId = localStorage.getItem(`side_team_activeOrganisationId`) || "";
            await updateCompanyPaymentInfo({
                organisationId,
                data: { ...args, ...(paymentMethodId ? { paymentMethodId: paymentMethodId } : {}) },
            });
        },
        onSuccess: (_, variables) => {
            reset(variables);
            toast.success(i18n.settings_submit_success());
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.paymentInfo(organisationId ?? ""));
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        mutate(data);
    };

    const stripe = useStripe();
    const elements = useElements();

    const { data: user } = useQuery(queries.user.detail());
    const isUserInsider = user?.isInsider;

    const paymentMethodOptions =
        paymentInfo.paymentMethod.type === `collectionStatement`
            ? ([
                  {
                      value: "collectionStatement",
                      label: i18n.settings_payment_method_cs_label(),
                      description: i18n.settings_payment_method_cs_desc(),
                  },
              ] as const)
            : ([
                  {
                      value: "card",
                      label: i18n.settings_payment_method_card_label(),
                      description: i18n.settings_payment_method_card_desc(),
                  },
                  {
                      value: "sepa_debit",
                      label: i18n.settings_payment_method_sepa_label(),
                      description: i18n.settings_payment_method_sepa_desc(),
                  },
              ] as const);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
        (typeof paymentMethodOptions)[number]["value"] | null
    >(
        paymentMethodOptions.find((option) => option.value === paymentInfo.paymentMethod.type)
            ?.value || null,
    );

    const cardNumber =
        (paymentInfo.paymentMethod &&
            paymentInfo.paymentMethod.type === "card" &&
            paymentInfo.paymentMethod.last4 &&
            `${Array(3).fill("XXXX ").join("")}${paymentInfo.paymentMethod.last4}`) ||
        "";

    const sepaIban =
        (paymentInfo.paymentMethod &&
            paymentInfo.paymentMethod.type === "sepa_debit" &&
            paymentInfo.paymentMethod.last4 &&
            `${Array(23).fill("X").join("")}${paymentInfo.paymentMethod.last4}`) ||
        "";

    const [billingCountryInputValue, setBillingCountryInputValue] = useState(
        billingCountryOptions.find((c) => c.value === paymentInfo.billingAddress.country)?.label ||
            "",
    );

    const [cardError, setCardError] = useState("");
    const [ibanError, setIbanError] = useState("");
    const [showFilledStripeElement, setShowFilledStripeElement] = useState(false);
    const [stripeElementChanged, setStripeElementChanged] = useState(false);

    const selectedBillingCountry = watch("billingAddress.country");

    function resetForm() {
        reset();
        setCardError("");
        setIbanError("");
        setShowFilledStripeElement(false);
        setStripeElementChanged(false);
        setBillingCountryInputValue(
            billingCountryOptions.find((c) => c.value === paymentInfo.billingAddress.country)
                ?.label || "",
        );
        setSelectedPaymentMethod(
            paymentMethodOptions.find((option) => option.value === paymentInfo.paymentMethod.type)
                ?.value || null,
        );
    }

    async function handleStripe() {
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const card = elements.getElement("card");
        const iban = elements.getElement("iban");

        let setupIntentResult: SetupIntentResult | null = null;

        if (selectedPaymentMethod === "card" && card !== null) {
            await stripe
                // https://docs.stripe.com/payments/save-and-reuse-cards-only?platform=web&payment-ui=direct-api#confirm-the-setupintent
                .confirmCardSetup(paymentInfo.clientSecret, {
                    payment_method: {
                        card: card,
                    },
                })
                .then(function (result) {
                    // Handle result.error or result.setupIntent
                    if (result.error && result.error.message) {
                        setCardError(result.error.message);
                    } else {
                        setCardError("");
                    }
                    setupIntentResult = result;
                })
                .finally(() => {
                    setStripeElementChanged(false);
                });
        }

        if (selectedPaymentMethod === "sepa_debit" && iban !== null) {
            const firstName = watch("accountingInfo.firstName");
            const lastName = watch("accountingInfo.lastName");
            const email = watch("accountingInfo.email");

            await stripe
                // https://docs.stripe.com/payments/sepa-debit/set-up-payment?platform=web&payment-ui=direct-api#web-submit-payment-method
                .confirmSepaDebitSetup(paymentInfo.clientSecret, {
                    payment_method: {
                        sepa_debit: iban,
                        billing_details: {
                            name: `${firstName} ${lastName}`,
                            email: email,
                        },
                    },
                })
                .then(function (result) {
                    // Handle result.error or result.setupIntent
                    if (result.error && result.error.message) {
                        setIbanError(result.error.message);
                    } else {
                        setIbanError("");
                    }
                    setupIntentResult = result;
                })
                .finally(() => {
                    setStripeElementChanged(false);
                });
        }

        return setupIntentResult as SetupIntentResult | null;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}>
            {/* PAYMENT METHOD */}
            <div>
                <div className='mb-4 flex items-center gap-2'>
                    <div className='flex h-8 w-8 items-center justify-center rounded-full bg-[var(--sui-blue-20)] text-[var(--sui-blue-500)]'>
                        <CreditCard className='h-4 w-4' />
                    </div>
                    <h2 className='typography-heading-m-semibold'>
                        {i18n.settings_payment_method_title()}
                    </h2>
                </div>
                <p className='mb-6 text-[var(--sui-grey-300)]'>
                    {i18n.settings_payment_method_subtitle()}
                </p>
                <p className={clsx(fonts.sans18Medium, "mb-2")}>
                    {i18n.settings_payment_method_label()}
                </p>
                <div className='mb-4 flex flex-col gap-2'>
                    {[...paymentMethodOptions].map((option, index) => {
                        return (
                            <ChoiceBox
                                className='w-full'
                                selectionControl={
                                    <Radio
                                        name='paymentMethod'
                                        key={index}
                                        value={option.value}
                                        checked={selectedPaymentMethod === option.value}
                                        onChange={(event) => {
                                            setSelectedPaymentMethod(
                                                event.target.value as
                                                    | "collectionStatement"
                                                    | "card"
                                                    | "sepa_debit",
                                            );
                                        }}
                                    />
                                }
                                key={index}
                                label={option.label}
                                description={option.description}
                            />
                        );
                    })}
                </div>
                {/* CARD */}
                {selectedPaymentMethod === `card` ? (
                    <div className='flex flex-col gap-4 rounded bg-[var(--sui-blue-20)] p-4'>
                        {cardNumber && !showFilledStripeElement ? (
                            SavedPaymentDetails({
                                title: i18n.settings_payment_method_card_saved(),
                                body: cardNumber,
                                buttonLabel: i18n.settings_payment_method_card_modify(),
                                buttonAction: () => {
                                    setShowFilledStripeElement(true);
                                    setStripeElementChanged(true);
                                },
                            })
                        ) : (
                            <label className={clsx(fonts.sans18Medium, "mb-2")}>
                                <div className='mb-2'>
                                    {i18n.settings_payment_method_card_number()}
                                </div>
                                <CardElement
                                    options={{
                                        style,
                                        hidePostalCode: true,
                                    }}
                                    onChange={(e) => {
                                        if (e.complete) setStripeElementChanged(true);
                                    }}
                                />
                                {cardError && (
                                    <p className='text-[16px] font-normal text-[var(--sui-red-500)]'>
                                        {cardError}
                                    </p>
                                )}
                            </label>
                        )}
                    </div>
                ) : null}
                {/* IBAN */}
                {selectedPaymentMethod === `sepa_debit` ? (
                    <div className='flex flex-col gap-4 rounded bg-[var(--sui-blue-20)] p-4'>
                        {sepaIban && !showFilledStripeElement ? (
                            SavedPaymentDetails({
                                title: i18n.settings_payment_method_sepa_saved(),
                                body: sepaIban,
                                buttonLabel: i18n.settings_payment_method_sepa_modify(),
                                buttonAction: () => {
                                    setShowFilledStripeElement(true);
                                    setStripeElementChanged(true);
                                },
                            })
                        ) : (
                            <label className={clsx(fonts.sans18Medium, "mb-2")}>
                                <div className='mb-2'>{i18n.settings_payment_method_sepa()}</div>
                                <IbanElement
                                    options={{
                                        style,
                                        supportedCountries: ["SEPA"],
                                        placeholderCountry: "FR",
                                    }}
                                    onChange={(e) => {
                                        if (e.complete) setStripeElementChanged(true);
                                    }}
                                />
                                {ibanError && (
                                    <p className='text-[16px] font-normal text-[var(--sui-red-500)]'>
                                        {ibanError}
                                    </p>
                                )}
                                {/* Display mandate acceptance text. */}
                                {/* https://docs.stripe.com/payments/sepa-debit/set-up-payment?platform=web&payment-ui=direct-api#add-and-configure-an-ibanelement-component */}
                                <p className='mt-2 text-[16px] font-normal text-[var(--sui-grey-900)]'>
                                    {i18n.settings_payment_method_sepa_direct_debit_mandate()}
                                </p>
                            </label>
                        )}
                    </div>
                ) : null}
                {paymentInfo.paymentMethod.type !== `collectionStatement` && (
                    <p className='mb-6 text-[16px] italic text-[var(--sui-grey-300)]'>
                        {i18n.settings_payment_method_stripe()}
                    </p>
                )}
                {isUserInsider && (
                    <label className={clsx(fonts.sans18Medium, "flex items-center gap-2")}>
                        <Toggle {...register("wantOrderForm")} />
                        {i18n.settings_payment_method_order_form()}
                    </label>
                )}
                <div className='mx-[-40px] my-10 h-[1px] bg-[var(--sui-grey-50)]' />
            </div>
            {/* BILLING ADDRESS */}
            <div>
                <div className='mb-4 flex items-center gap-2'>
                    <div className='flex h-8 w-8 items-center justify-center rounded-full bg-[var(--sui-blue-20)] text-[var(--sui-blue-500)]'>
                        <Building2 className='h-4 w-4' />
                    </div>
                    <h2 className='typography-heading-m-semibold'>
                        {i18n.settings_payment_billing_title()}
                    </h2>
                </div>
                <p className='mb-6 text-[var(--sui-grey-300)]'>
                    {i18n.settings_payment_billing_subtitle()}
                </p>
                <div className='mb-4 flex gap-2'>
                    <TextField
                        {...register("billingAddress.street")}
                        className='w-[80%]'
                        label={i18n.settings_payment_billing_address()}
                        placeholder={i18n.settings_payment_billing_address_placeholder()}
                        size='small'
                        error={errors.billingAddress?.street?.message}
                    />
                    <TextField
                        {...register("billingAddress.postalCode")}
                        className='w-[20%]'
                        label={i18n.settings_payment_billing_postal_code()}
                        placeholder={i18n.settings_payment_billing_postal_code_placeholder()}
                        size='small'
                        error={errors.billingAddress?.postalCode?.message}
                    />
                </div>
                <div className='flex gap-2'>
                    <TextField
                        {...register("billingAddress.city")}
                        className='flex-grow'
                        label={i18n.settings_payment_billing_city()}
                        placeholder={i18n.settings_payment_billing_city_placeholder()}
                        size='small'
                        error={errors.billingAddress?.city?.message}
                    />
                    <Controller
                        name='billingAddress.country'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <ComboBox
                                className='flex-grow'
                                size='small'
                                label={i18n.settings_payment_billing_country()}
                                placeholder={i18n.settings_payment_billing_country_placeholder()}
                                options={billingCountryOptions}
                                selection={
                                    billingCountryOptions.find((c) => c.value === field.value) ||
                                    null
                                }
                                onChange={(country) => {
                                    field.onChange(country?.value);
                                }}
                                inputValue={billingCountryInputValue}
                                onInputChange={setBillingCountryInputValue}
                                openOnInitialClick
                            />
                        )}
                    />
                </div>
                {selectedBillingCountry && selectedBillingCountry !== "FR" ? (
                    <div className='mt-4 flex flex-col gap-4 rounded bg-[var(--sui-blue-20)] p-4'>
                        <p className='tva-number-intro'>
                            {i18n.settings_payment_billing_tva_intro()}
                        </p>
                        <TextField
                            {...register("tvaNumber")}
                            id='tvaNumber'
                            label={i18n.settings_payment_billing_tva_label()}
                            placeholder={i18n.settings_payment_billing_tva_placeholder()}
                            size='small'
                            error={errors.tvaNumber?.message}
                        />
                    </div>
                ) : null}
                <div className='mx-[-40px] my-10 h-[1px] bg-[var(--sui-grey-50)]' />
            </div>
            {/* ACCOUNTING INFOS */}
            <div>
                <div className='mb-4 flex items-center gap-2'>
                    <div className='flex h-8 w-8 items-center justify-center rounded-full bg-[var(--sui-blue-20)] text-[var(--sui-blue-500)]'>
                        <User className='h-4 w-4' />
                    </div>
                    <h2 className='typography-heading-m-semibold'>
                        {i18n.settings_payment_accounting_title()}
                    </h2>
                </div>
                <p className='mb-6 text-[var(--sui-grey-300)]'>
                    {i18n.settings_payment_accounting_subtitle()}
                </p>
                <div className='mb-4 flex gap-2'>
                    <TextField
                        {...register("accountingInfo.firstName")}
                        className='flex-grow'
                        label={i18n.settings_payment_accounting_firstname()}
                        placeholder={i18n.settings_payment_accounting_firstname_placeholder()}
                        size='small'
                        error={errors.accountingInfo?.firstName?.message}
                    />
                    <TextField
                        {...register("accountingInfo.lastName")}
                        className='flex-grow'
                        label={i18n.settings_payment_accounting_name()}
                        placeholder={i18n.settings_payment_accounting_name_placeholder()}
                        size='small'
                        error={errors.accountingInfo?.lastName?.message}
                    />
                </div>
                <div className='flex gap-2'>
                    <TextField
                        {...register("accountingInfo.email")}
                        className='flex-grow'
                        label={i18n.settings_payment_accounting_email()}
                        placeholder={i18n.settings_payment_accounting_email_placeholder()}
                        size='small'
                        error={errors.accountingInfo?.email?.message}
                    />
                    <TextField
                        {...register("accountingInfo.phoneNumber")}
                        className='flex-grow'
                        label={i18n.settings_payment_accounting_phone_number()}
                        placeholder={i18n.settings_payment_accounting_phone_number_placeholder()}
                        size='small'
                        error={errors.accountingInfo?.phoneNumber?.message}
                    />
                </div>
            </div>
            {/* SAVE BAR */}
            {isDirty || stripeElementChanged ? (
                <SaveBar
                    resetAction={() => resetForm()}
                    saveAction={() => {}}
                    canSave={stripe && elements}
                    loading={isPending}
                />
            ) : null}
        </form>
    );
}

function SavedPaymentDetails({
    title,
    body,
    buttonLabel,
    buttonAction,
}: {
    title: React.ReactNode;
    body: React.ReactNode;
    buttonLabel: React.ReactNode;
    buttonAction: () => void;
}) {
    return (
        <>
            <p className={clsx(fonts.sans18Medium, "mb-2")}>{title}</p>
            <div className='flex items-center justify-between'>
                <div>{body}</div>
                <div>
                    <Button onClick={buttonAction}>{buttonLabel}</Button>
                </div>
            </div>
        </>
    );
}
