import { useEffect, useState } from "react";
import { toast } from "saphir";
import { Button, Dialog, IconButton, Loader, ModalsService } from "side-ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { deactivateUser } from "@lib/api/deactivateUser";
import { getOrganisationUsers } from "@lib/api/getOrganisationUsers";
import { inviteUser } from "@lib/api/inviteUser";
import { patchOrganisationUser } from "@lib/api/patchOrganisationUser";
import { resendActivationLink } from "@lib/api/resendActivationLink";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import { ScrollableSettingsContent } from "../components/Settings";

import AddUserModal from "./AddUserModal/AddUserModal";
import EditUserModal from "./EditUserModal/EditUserModal";

import "./Team.scss";

const Team = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const { data: user } = useQuery(queries.user.detail());

    const organisationID = localStorage.getItem(`side_team_activeOrganisationId`);

    useEffect(() => {
        setLoading(true);
        getOrganisationUsers()
            .then((res) => {
                if (res) {
                    setUsers(res.users);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <ScrollableSettingsContent>
            <div className='team-settings-team p-10'>
                <div className='team-settings-team__header'>
                    <h2 className='text-gray-900 typography-heading-xl-semibold'>
                        {i18n.settings_team_title()}
                    </h2>
                    {user?.role === "admin" && (
                        <Button
                            color='blue'
                            iconBefore='UserAdd'
                            action={() => {
                                ModalsService.openModal({
                                    id: "AddUserModal",
                                    content: (
                                        <AddUserModal
                                            addUser={(usr) => {
                                                inviteUser(usr).then((res) => {
                                                    if (res) {
                                                        setUsers(res.users);
                                                        toast.success(
                                                            i18n.settings_team_add_user_success(),
                                                        );
                                                        ModalsService.closeModal("AddUserModal");
                                                    }
                                                });
                                            }}
                                            hideModal={() =>
                                                ModalsService.closeModal("AddUserModal")
                                            }
                                        />
                                    ),
                                });
                            }}
                        >
                            {i18n.settings_team_add_user()}
                        </Button>
                    )}
                </div>

                <div className='team-settings-team__table'>
                    <div className='team-settings-team__table__header'>
                        <div className='team-settings-team__table__header__cell'>
                            {i18n.settings_team_user()}
                        </div>
                        <div className='team-settings-team__table__header__cell'>
                            {i18n.settings_team_role()}
                        </div>
                        <div className='team-settings-team__table__header__cell' />
                    </div>
                    <div className='team-settings-team__table__body'>
                        {users.map((u) => (
                            <div key={u.id} className='team-settings-team__table__body__row'>
                                <div className='team-settings-team__table__body__cell team-settings-team__table__body__cell--user'>
                                    <div className='team-settings-team__table__body__cell--user__name'>
                                        {u.firstName}
                                        &nbsp;
                                        {u.lastName}
                                    </div>
                                    <div className='team-settings-team__table__body__cell--user__email'>
                                        {u.email}
                                    </div>
                                </div>
                                <div className='team-settings-team__table__body__cell team-settings-team__table__body__cell--role'>
                                    {i18nDK(`settings_team_role_${u.role}`)}
                                </div>
                                <div className='team-settings-team__table__body__cell team-settings-team__table__body__cell--actions'>
                                    {user?.role === "admin" &&
                                        !u.validated &&
                                        u.sourceOrganisationId === organisationID && (
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <IconButton
                                                        icon='Send'
                                                        color='grey'
                                                        action={() => {
                                                            resendActivationLink(u.id).then(
                                                                (res) => {
                                                                    if (res) {
                                                                        toast.success(
                                                                            i18n.settings_team_reinvited_success(),
                                                                        );
                                                                    }
                                                                },
                                                            );
                                                        }}
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent type='action'>
                                                    {i18n.settings_team_resend_activation_link()}
                                                </TooltipContent>
                                            </Tooltip>
                                        )}
                                    {user?.role === "admin" &&
                                        (!u.sourceOrganisation ||
                                            u.sourceOrganisation.id === organisationID) &&
                                        u.role !== "admin" &&
                                        u.validated && (
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <IconButton
                                                        icon='PenXL'
                                                        color='grey'
                                                        action={() => {
                                                            ModalsService.openModal({
                                                                id: "EditUserModal",
                                                                content: (
                                                                    <EditUserModal
                                                                        user={u}
                                                                        editUser={(req) => {
                                                                            patchOrganisationUser(
                                                                                u.id,
                                                                                req,
                                                                            ).then((res) => {
                                                                                if (res) {
                                                                                    setUsers(
                                                                                        res.users,
                                                                                    );
                                                                                    toast.success(
                                                                                        i18n.settings_team_edit_user_success(),
                                                                                    );
                                                                                    ModalsService.closeModal(
                                                                                        "EditUserModal",
                                                                                    );
                                                                                }
                                                                            });
                                                                        }}
                                                                        hideModal={() =>
                                                                            ModalsService.closeModal(
                                                                                "EditUserModal",
                                                                            )
                                                                        }
                                                                    />
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent type='action'>
                                                    {i18n.settings_team_edit_role()}
                                                </TooltipContent>
                                            </Tooltip>
                                        )}
                                    {user?.role === "admin" &&
                                        (!u.sourceOrganisation ||
                                            u.sourceOrganisation.id === organisationID) &&
                                        u.role !== "admin" && (
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <IconButton
                                                        icon='CrossXL'
                                                        color='grey'
                                                        action={() => {
                                                            ModalsService.openModal({
                                                                id: "DeleteUserDialog",
                                                                content: (
                                                                    <Dialog
                                                                        title={i18n.settings_team_deactivate_user()}
                                                                        validateLabel={i18n.confirm()}
                                                                        cancelLabel={i18n.cancel()}
                                                                        cancelAction={() =>
                                                                            ModalsService.closeModal(
                                                                                "DeleteUserDialog",
                                                                            )
                                                                        }
                                                                        validateAction={() => {
                                                                            deactivateUser(
                                                                                u.id,
                                                                            ).then((res) => {
                                                                                if (res) {
                                                                                    setUsers(
                                                                                        res.users,
                                                                                    );
                                                                                    toast.success(
                                                                                        i18n.settings_team_deactivate_user_success(),
                                                                                    );
                                                                                }
                                                                            });
                                                                            ModalsService.closeModal(
                                                                                "DeleteUserDialog",
                                                                            );
                                                                        }}
                                                                    >
                                                                        {i18n.settings_team_deactivate_user_dialog_content(
                                                                            {
                                                                                name: `${u.firstName} ${u.lastName}`,
                                                                            },
                                                                        )}
                                                                        <br />
                                                                    </Dialog>
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent type='action'>
                                                    {i18n.settings_team_deactivate_user()}
                                                </TooltipContent>
                                            </Tooltip>
                                        )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ScrollableSettingsContent>
    );
};

export default Team;
