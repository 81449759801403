import { useState } from "react";
import { differenceInMinutes, format, isAfter, isBefore, isEqual } from "date-fns";
import { ChevronRight, Clock, X, Zap } from "lucide-react";
import { Button, RadioGroup, Select, SelectionCard } from "saphir";
import { SimpleHeader, Tip } from "side-ui";
import { Tag, User } from "sui";

import { i18n, i18nDK } from "@lib/i18n";
import trackEvent from "@lib/trackers";
import { getLocale } from "@lib/utils/getLocale";

import { filterColors, setStatusLabel, sumShiftBreaks } from "../../../../utils";
import { convertMinutesToHours } from "../../../../utils/dates";

import AttendanceEdit from "./AttendancesEdit/AttendanceEdit";

import "./TimesheetsTablePanel.scss";

const TimesheetsTablePanel = ({
    siderName,
    history,
    attendanceId,
    closePanel,
    taskName,
    status,
    slotDuration,
    slotConflict,
    breakConflict,
    setSiderDidntWork,
    disputeAttendance,
    companyName,
    shiftId,
    readOnly,
    isFlexiblePlanning,
}) => {
    const locale = getLocale();
    const currentVersion = history[history.length - 1];
    const shiftVersion = history.find(({ origin }) => origin === "shift");
    const [attendanceEditState, setAttendanceEditState] = useState({
        startDate: new Date(currentVersion?.startDate),
        endDate: new Date(currentVersion?.endDate),
        breaks: currentVersion?.breaks,
    });
    const [formState, setFormState] = useState({
        dirty: false,
        error: "",
    });
    // handle edge case when insider added a version to a dispute between sider and company
    // we should prevent company from adding a new dispute => only validation is possible
    const fullDisputeFlow = ["shift", "sider", "company", "insider"];
    const origins = history.map(({ origin }) => origin);
    const preventDisputeAgain = fullDisputeFlow.every((origin) => origins.includes(origin));

    function lookForBreakErrors({ startDate, endDate }, breaks) {
        if (breaks && breaks.length) {
            breaks.forEach((b) => {
                if (isBefore(new Date(b.startDate), new Date(startDate))) {
                    setFormState((prevState) => ({
                        ...prevState,
                        error: "before",
                    }));
                } else if (isAfter(new Date(b.endDate), new Date(endDate))) {
                    setFormState((prevState) => ({
                        ...prevState,
                        error: "after",
                    }));
                } else {
                    // remove error if any; otherwise let React do nothing
                    setFormState((prevState) => ({
                        ...prevState,
                        error: "",
                    }));
                }
            });
        }
    }

    function setAttendanceHours(hours) {
        if (areHoursDirty(attendanceEditState, hours)) {
            setAttendanceEditState((prevState) => {
                setFormState((prev) => ({
                    ...prev,
                    dirty: true,
                }));

                return {
                    ...prevState,
                    ...hours,
                };
            });
            // passing hours instead of state.start/endDate because state update is asynchronous
            // and is thus not yet updated
            lookForBreakErrors(hours, attendanceEditState.breaks);
        }

        trackEvent({
            name: "timesheet - edits attendance",
            params: {
                organisationId: localStorage.getItem("side_team_activeOrganisationId"),
                organisationName: companyName,
                shiftId,
            },
        });
    }

    function setAttendanceBreaks(breaks) {
        if (areBreaksDirty(attendanceEditState.breaks, breaks)) {
            setAttendanceEditState((prevState) => {
                setFormState((prev) => ({
                    ...prev,
                    dirty: true,
                }));

                return {
                    ...prevState,
                    breaks,
                };
            });

            lookForBreakErrors({ ...attendanceEditState }, breaks);
        }

        trackEvent({
            name: "timesheet - edits attendance",
            params: {
                organisationId: localStorage.getItem("side_team_activeOrganisationId"),
                organisationName: companyName,
                shiftId,
            },
        });
    }

    function initialVersionDuration() {
        if (!currentVersion) {
            return 0;
        }

        const siderSlot = differenceInMinutes(
            new Date(currentVersion?.endDate),
            new Date(currentVersion?.startDate),
        );

        return convertMinutesToHours(siderSlot - sumShiftBreaks(currentVersion.breaks)) || 0;
    }

    // sider did not work means startDate and endDate are the same
    const siderDidNotWork = isEqual(
        new Date(currentVersion.startDate),
        new Date(currentVersion.endDate),
    );

    const [radioValue, setRadioValue] = useState(
        siderDidNotWork ? "siderDidNotWork" : "siderWorked",
    );
    const [absenceJustification, setAbsenceJustification] = useState(
        currentVersion.absenceJustification ?? "",
    );

    function handleSubmit() {
        if (radioValue === "siderWorked") {
            if (readOnly || formState.error) return;
            disputeAttendance(
                attendanceId,
                { ...attendanceEditState },
                "timesheet - confirms attendance edit", // tracker name
            );
            closePanel();
        } else if (radioValue === "siderDidNotWork") {
            if (readOnly || preventDisputeAgain) return;
            if (isFlexiblePlanning) {
                setSiderDidntWork(attendanceId, absenceJustification);
            } else {
                // default to absenceJustification = "other" for precise planning
                setSiderDidntWork(attendanceId, "other");
            }
        }
    }

    const submitDisabled = (() => {
        if (radioValue === "siderWorked") {
            return !formState.dirty || Boolean(formState.error) || readOnly || preventDisputeAgain;
        } else if (radioValue === "siderDidNotWork") {
            if (isFlexiblePlanning) {
                return !absenceJustification || readOnly || preventDisputeAgain;
            } else return readOnly || preventDisputeAgain;
        }
    })();

    return (
        <section className='timesheets-table__panel flex h-full flex-col'>
            <SimpleHeader className='timesheets-table__panel__header'>
                <div className='timesheets-table__panel__header__informations__wrapper'>
                    <div className='timesheets-table__panel__header__title__wrapper'>
                        <p className='timesheets-table__panel__title'>{siderName}</p>

                        <p className='timesheets-table__panel__subtitle'>
                            {new Date(currentVersion.startDate).toLocaleDateString(locale.code)}
                            &nbsp;-&nbsp;
                            {taskName}
                        </p>
                    </div>

                    <div className='timesheets-table__panel__header__skiplink'>
                        <button onClick={closePanel} type='button'>
                            <X className='h-4 w-4 text-gray-500' />
                        </button>
                    </div>
                </div>
                <div className='timesheets-table__panel__header__tags'>
                    <Tag color={filterColors[status]}>{setStatusLabel(status)}</Tag>
                    {isFlexiblePlanning ? (
                        <Tag color='grey'>{i18n.planning_type_flexible_title()}</Tag>
                    ) : null}
                </div>
            </SimpleHeader>

            {/* Scheduled hours (only for precise planning because in the case of flexible planning we do not know)  */}
            {!isFlexiblePlanning ? (
                <div className='timesheets-table__panel__list__item'>
                    <span className='timesheets-table__panel__list__item__origin timesheets-table__panel__list__item__section'>
                        <span className='timesheets-table__panel__list__item__origin__icon'>
                            <Zap className='h-4 w-4 text-gray-300' />
                        </span>

                        <span>
                            <span className='timesheets-table__panel__list__item__origin__date'>
                                {i18n.ts_panel_scheduled_hours()}
                            </span>
                            &nbsp;-&nbsp;
                            <span className='timesheets-table__panel__list__item__origin__type'>
                                {slotDuration}
                            </span>
                        </span>
                    </span>

                    <span
                        className={`timesheets-table__panel__list__item__time timesheets-table__panel__list__item__section`}
                    >
                        <span className='timesheets-table__panel__list__item__time__icon'>
                            <Clock className='h-4 w-4 text-gray-300' />
                        </span>

                        <span className='timesheets-table__panel__list__item__time__wrapper'>
                            <span className='timesheets-table__panel__list__item__time__start'>
                                {format(new Date(shiftVersion.startDate), "HH:mm", {
                                    locale,
                                })}
                            </span>

                            <ChevronRight className='h-4 w-4 text-gray-300' />

                            <span className='timesheets-table__panel__list__item__time__end'>
                                {format(new Date(shiftVersion.endDate), "HH:mm", {
                                    locale,
                                })}
                            </span>
                        </span>
                        {shiftVersion.breaks && (
                            <span className='timesheets-table__panel__list__item__time__break'>
                                ({convertMinutesToHours(sumShiftBreaks(shiftVersion.breaks))})
                            </span>
                        )}
                    </span>
                </div>
            ) : null}

            {/* Edit Attendance */}
            <>
                <div className='flex-grow p-4'>
                    <span className='timesheets-table__panel__list__item__origin timesheets-table__panel__list__item__section'>
                        <span className='timesheets-table__panel__list__item__origin__icon'>
                            <User className='timesheets-table__panel__list__item__origin__icon__user' />
                        </span>

                        <span>
                            <span className='timesheets-table__panel__list__item__origin__date'>
                                {i18n.ts_panel_sider_hours()}
                            </span>
                            &nbsp;-&nbsp;
                            <span className='timesheets-table__panel__list__item__origin__type'>
                                {initialVersionDuration()}
                            </span>
                        </span>
                    </span>
                    {currentVersion ? (
                        <RadioGroup.Root
                            value={radioValue}
                            onValueChange={setRadioValue}
                            disabled={readOnly}
                        >
                            <SelectionCard.Root variant={readOnly ? "default" : "interactive"}>
                                <SelectionCard.Label>
                                    <SelectionCard.Control>
                                        <RadioGroup.Item value='siderWorked' />
                                    </SelectionCard.Control>
                                    {i18n.ts_panel_sider_worked()}
                                </SelectionCard.Label>
                                {radioValue === "siderWorked" ? (
                                    <SelectionCard.Content>
                                        <AttendanceEdit
                                            currentVersion={attendanceEditState}
                                            editAttendanceHours={setAttendanceHours}
                                            editAttendanceBreaks={setAttendanceBreaks}
                                            slotConflict={!isFlexiblePlanning && slotConflict}
                                            breakConflict={!isFlexiblePlanning && breakConflict}
                                            readOnly={readOnly || preventDisputeAgain}
                                        />
                                    </SelectionCard.Content>
                                ) : null}
                            </SelectionCard.Root>
                            <SelectionCard.Root variant={readOnly ? "default" : "interactive"}>
                                <SelectionCard.Label>
                                    <SelectionCard.Control>
                                        <RadioGroup.Item value='siderDidNotWork' />
                                    </SelectionCard.Control>
                                    {i18n.ts_panel_sider_didnt_work()}
                                </SelectionCard.Label>
                                {/* Flexible Planning can have day off, for precise planning we do not specify the justification */}
                                {radioValue === "siderDidNotWork" && isFlexiblePlanning ? (
                                    <SelectionCard.Content>
                                        <Select
                                            value={absenceJustification}
                                            onValueChange={setAbsenceJustification}
                                            placeholder={i18n.ts_panel_sider_didnt_work_placeholder()}
                                        >
                                            <Select.Item value='dayOff'>
                                                {i18n.ts_panel_sider_didnt_work_dayOff()}
                                            </Select.Item>
                                            <Select.Item value='other'>
                                                {i18n.ts_panel_sider_didnt_work_other()}
                                            </Select.Item>
                                        </Select>
                                    </SelectionCard.Content>
                                ) : null}
                            </SelectionCard.Root>
                        </RadioGroup.Root>
                    ) : null}

                    {formState.error ? (
                        <span className='timesheets-table__panel__list__item--edit__wrapper timesheets-table__panel__list--error'>
                            <Tip
                                small={true}
                                isWarning={true}
                                content={i18nDK(`ts_edit_form_break_error_${formState.error}`)}
                            />
                        </span>
                    ) : null}
                </div>
                <footer className='flex justify-end border-t border-gray-50 p-4'>
                    <Button onClick={handleSubmit} disabled={submitDisabled}>
                        {i18n.ts_panel_submit()}
                    </Button>
                </footer>
            </>
        </section>
    );
};

export default TimesheetsTablePanel;

function areBreaksDirty(prevBreaks = [] as any[], breaks) {
    if (prevBreaks.length !== breaks.length) {
        return true;
    }

    return prevBreaks.some((prevBreak, index) => {
        const breakItem = breaks[index];
        return (
            new Date(prevBreak.startDate).toISOString() !==
                new Date(breakItem.startDate).toISOString() ||
            new Date(prevBreak.endDate).toISOString() !== new Date(breakItem.endDate).toISOString()
        );
    });
}

function areHoursDirty(prevHours, { startDate, endDate }) {
    return !isEqual(prevHours.startDate, startDate) || !isEqual(prevHours.endDate, endDate);
}
