import { Check } from "lucide-react";
import { Button } from "saphir";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQueryClient } from "@tanstack/react-query";

const SaveBar = ({ resetAction, saveAction, canSave, loading }) => {
    const queryClient = useQueryClient();

    return (
        <div className='fixed bottom-0 left-0 right-0 flex w-full items-center justify-center gap-6 bg-blue-500 p-3 text-white'>
            <div className='typography-body-m-regular'>{i18n.settings_actions_content()}</div>
            <div className='flex'>
                {resetAction && (
                    <Button variant='primary' loading={loading} onClick={resetAction}>
                        {i18n.settings_actions_discard()}
                    </Button>
                )}
                <Button
                    variant='primary-outlined'
                    onClick={() => {
                        queryClient.invalidateQueries(queries.company.completionStatus());
                        saveAction();
                    }}
                    disabled={!canSave}
                    loading={loading}
                    type='submit'
                >
                    {i18n.settings_actions_save()}
                    <Check className='h-5 w-5' />
                </Button>
            </div>
        </div>
    );
};

export default SaveBar;
