import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Dialog, TextArea, TextField, toast } from "saphir";
import { Tip } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { GetCompanyDepartmentsResponse } from "@lib/api/getCompanyDepartments";
import { postCompanyDepartment } from "@lib/api/postCompanyDepartment";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import normalizeString from "@lib/utils/normalizeString";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type DepartmentDialogProps = {
    departments: GetCompanyDepartmentsResponse;
    departement?: GetCompanyDepartmentsResponse[0];
};

const regex = /^(?!.* {2})(?!^ $)[a-zA-Z0-9À-ÖØ-öø-ÿ\- ]*$/;
export function DepartmentDialog(props: DepartmentDialogProps & { trigger: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <DepartmentDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}
export function DepartmentDialogContent({
    departments,
    departement,
    setOpen,
}: DepartmentDialogProps & { setOpen: (value: boolean) => void }) {
    const queryClient = useQueryClient();
    const { data: company } = useQuery(queries.company.detail());
    const maxNameLength = 60;
    const maxCodeLength = 20;

    const { mutate: postDepartment, isPending } = useMutation({
        mutationFn: postCompanyDepartment,
        onSuccess: (data) => {
            toast.success(i18n.settings_departments_success());
            trackEvent({
                name: `services - service created`,
                params: {
                    serviceId: data.id,
                    name: data.name,
                    code: data.code,
                    organisationId: data.organisationId,
                    organisationName: company?.organisation.name,
                },
            });
            setOpen(false);
        },
        onError: () => {
            toast.error(i18n.settings_departments_error());
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.departments());
        },
    });
    const schema = z.object({
        name: z
            .string()
            .min(1, { message: i18n.settings_departments_name_error_required() })
            .max(maxNameLength, { message: i18n.settings_departments_name_error_max() })
            .regex(regex, {
                message: i18n.settings_departments_error_special_chars(),
            })
            .refine(
                (valueName) => {
                    const normalizedValue = normalizeString(valueName);
                    return !departments.some(
                        (department) => normalizeString(department.name) === normalizedValue,
                    );
                },
                { message: i18n.settings_departments_name_error_duplicate() },
            ),
        code: z
            .string()
            .max(maxCodeLength, {
                message: i18n.settings_departments_code_error_max(),
            })
            .regex(regex, {
                message: i18n.settings_departments_error_special_chars(),
            }),
    });

    type Inputs = z.infer<typeof schema>;

    const { formState, control, handleSubmit, watch, trigger } = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            name: departement?.name ?? "",
            code: departement?.code ?? "",
        },
    });
    const { name, code } = watch();

    useEffect(() => {
        if (formState.isDirty) {
            // Need to use trigger to make a proper fields validation
            trigger();
        }
    }, [name, code]);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        postDepartment({
            name: data.name.trim(),
            code: data.code?.trim() ?? undefined,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Dialog.Header>
                <Dialog.Title>{i18n.settings_departments_dialog_title()}</Dialog.Title>
            </Dialog.Header>
            <Dialog.Main className='flex flex-col gap-4'>
                <Tip>
                    <Dialog.Description className='text-gray-600'>
                        {i18n.settings_departments_dialog_description()}
                    </Dialog.Description>
                </Tip>

                <div className='flex flex-col gap-1'>
                    <Controller
                        name='name'
                        control={control}
                        render={({ field }) => (
                            <TextArea
                                required
                                label={i18n.settings_departments_dialog_name_label()}
                                value={field.value}
                                onChange={(e) => {
                                    field.onChange(e.target.value.replace(/(\r\n|\n|\r)/gm, ""));
                                }}
                                onBlur={field.onBlur}
                                error={formState.errors.name?.message ?? undefined}
                                placeholder={i18n.settings_departments_dialog_name_placeholder()}
                                // @ts-expect-error fieldSizing is not a valid prop yet but will be implemented in Tailwind V4
                                style={{ fieldSizing: "content" }}
                            />
                        )}
                    />
                    {!formState.errors.name?.message ? (
                        <p className='flex justify-end text-gray-300 typography-body-s-medium'>
                            {i18n.settings_departments_remaining_chars({
                                count: maxNameLength - name.length,
                                plural: maxNameLength - name.length > 1 ? "s" : "",
                            })}
                        </p>
                    ) : null}
                </div>
                <div className='flex flex-col gap-1'>
                    <Controller
                        name='code'
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label={i18n.settings_departments_dialog_code_label()}
                                tooltip={i18n.settings_departments_dialog_code_tooltip()}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                error={formState.errors.code?.message ?? undefined}
                                placeholder={i18n.settings_departments_dialog_code_placeholder()}
                            />
                        )}
                    />
                    {!formState.errors.code?.message ? (
                        <p className='flex justify-end text-gray-300 typography-body-s-medium'>
                            {i18n.settings_departments_remaining_chars({
                                count: maxCodeLength - (code?.length ?? 0),
                                plural: maxCodeLength - (code?.length ?? 0) > 1 ? "s" : "",
                            })}
                        </p>
                    ) : null}
                </div>
            </Dialog.Main>
            <Dialog.Footer className='flex justify-end gap-2'>
                <Button variant='neutral' onClick={() => setOpen(false)}>
                    {i18n.cancel()}
                </Button>
                <Button
                    variant='primary'
                    disabled={!formState.isValid || !formState.isDirty}
                    loading={isPending}
                    type='submit'
                >
                    {i18n.save()}
                </Button>
            </Dialog.Footer>
        </form>
    );
}
