import React, { ReactElement, ReactNode } from "react";
import { cn } from "saphir";

type SectionHeaderProps = {
    title: string | ReactNode;
    icon: ReactElement<any>;
    description?: string | ReactNode;
    className?: string;
};
export function SectionHeader({ title, icon, description, className }: SectionHeaderProps) {
    const Icon = React.cloneElement(icon, {
        className: cn(icon.props.className, "h-5 w-5"),
    });

    return (
        <div className={cn("mb-8 flex flex-col gap-3", className)}>
            <div className='flex items-center gap-4'>
                <div className='grid h-8 w-8 shrink-0 place-items-center rounded-full bg-blue-50 text-blue-500'>
                    {Icon}
                </div>
                {title ? (
                    <p className='text-gray-900 typography-heading-m-semibold'>{title}</p>
                ) : null}
            </div>
            {description ? (
                <p className='text-gray-300 typography-body-m-regular'>{description}</p>
            ) : null}
        </div>
    );
}
