// import { Button, CTAButton } from "side-ui";
import { CircleCheckBig, CircleSlash2 } from "lucide-react";
import { Button, Tooltip } from "saphir";

import { i18n } from "@lib/i18n";

function TimesheetsTableActionBar({
    attendances,
    selectedAttendances,
    setSiderDidntWork,
    validateAttendances,
    navigationMode,
    readOnly,
}) {
    const hasAttendanceWithABsenceJustificationSelected = attendances.some(
        (attendance) =>
            selectedAttendances.includes(attendance.id) && attendance.absenceJustification,
    );
    return (
        <footer
            className={`timesheets-table__action-bar ${selectedAttendances.length ? "timesheets-table__action-bar--open" : ""} ${
                navigationMode && // update left given navigationMode
                `timesheets-table__action-bar--${navigationMode}`
            } `}
        >
            {selectedAttendances.length ? (
                <>
                    <div className='timesheets-table__action-bar__mobile-cta h-full'>
                        <Button
                            onClick={readOnly ? () => null : validateAttendances}
                            className='h-full w-full rounded-none'
                        >
                            {selectedAttendances.length === 1
                                ? i18n.ts_footer_tovalidate_attendance()
                                : i18n.ts_footer_tovalidate_attendances({
                                      count: selectedAttendances.length,
                                  })}
                        </Button>
                    </div>
                    <div className='timesheets-table__action-bar__wrapper'>
                        <span className='timesheets-table__action-bar__sider-count'>
                            {selectedAttendances.length === 1
                                ? i18n.ts_footer_selected_sider()
                                : i18n.ts_footer_selected_siders({
                                      count: selectedAttendances.length,
                                  })}
                        </span>

                        <span className='timesheets-table__action-bar__submit__wrapper'>
                            <Tooltip
                                content={
                                    hasAttendanceWithABsenceJustificationSelected
                                        ? i18n.ts_footer_absence_justification_tooltip()
                                        : ""
                                }
                                className='z-[1000]'
                                delayDuration={0}
                            >
                                <Button
                                    variant='destructive-outlined'
                                    onClick={readOnly ? () => null : setSiderDidntWork}
                                    disabled={
                                        readOnly || hasAttendanceWithABsenceJustificationSelected
                                    }
                                >
                                    <CircleSlash2 />
                                    {selectedAttendances.length === 1
                                        ? i18n.ts_footer_sider_didnt_work()
                                        : i18n.ts_footer_siders_didnt_work({
                                              count: selectedAttendances.length,
                                          })}
                                </Button>
                            </Tooltip>

                            <Button
                                onClick={readOnly ? () => null : validateAttendances}
                                disabled={readOnly}
                            >
                                <CircleCheckBig />
                                {i18n.ts_footer_validate_hours()}
                            </Button>
                        </span>
                    </div>
                </>
            ) : null}
        </footer>
    );
}

export default TimesheetsTableActionBar;
