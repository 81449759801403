import { useContext, useEffect, useRef, useState } from "react";
import { useSetAtom } from "jotai";
import { FileText, Plus, Trash2 } from "lucide-react";
import { cn } from "saphir";
import { fonts, Select, Tip, useConfirmDialog } from "sui";

import { SectionHeader } from "@components/SectionHeader";
import { deleteCompanyMotive } from "@lib/api/deleteCompanyMotive";
import { useEffectSkipMount } from "@lib/hooks/useEffectSkipMount";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { taskFormRoute } from "@routes/TaskPosting/route";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { PostingTrackingContext } from "../context/PostingTrackingContext";
import { disabledSubmitAtom, orderDataToSendAtom } from "../TaskFormFooter";

import { ActivityIncreaseModal } from "./ActivityIncreaseModal";
import { RecruitmentModal } from "./RecruitmentModal";
import { ReplacementModal } from "./ReplacementModal";
import { SeasonalJobModal } from "./SeasonalJobModal";
import { TemporaryContractModal } from "./TemporaryContractModal";

import styles from "./MotiveStep.module.css";

export const RECRUITMENT = "waitingRecruitment";
export const REPLACEMENT = "replacement";
export const ACTIVITY_INCREASE = "activityIncrease";
export const TEMPORARY_CONTRACT = "temporaryContract";
export const SEASONAL_ACTIVITY = "seasonalActivity";

export function MotiveStep() {
    const setOrderDataToSend = useSetAtom(orderDataToSendAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useSuspenseQuery(queries.task.detail(taskId));
    const { data: motives } = useSuspenseQuery(queries.company.companyMotives());
    const { data: company } = useSuspenseQuery(queries.company.detail());

    const activityIncreaseModalRef = useRef<HTMLDialogElement>(null);
    const replacementModalRef = useRef<HTMLDialogElement>(null);
    const recruitmentModalRef = useRef<HTMLDialogElement>(null);
    const temporaryContractModalRef = useRef<HTMLDialogElement>(null);
    const seasonalJobModalRef = useRef<HTMLDialogElement>(null);

    const motivesOptions = [
        {
            label: i18n.order_motives_label_replacement(),
            value: REPLACEMENT,
            modal: replacementModalRef,
        },
        {
            label: i18n.order_motives_label_waiting_recruitment(),
            value: RECRUITMENT,
            modal: recruitmentModalRef,
        },
        {
            label: i18n.order_motives_label_temporary_contract(),
            value: TEMPORARY_CONTRACT,
            modal: temporaryContractModalRef,
        },
        {
            label: i18n.order_motives_label_seasonal_activity(),
            value: SEASONAL_ACTIVITY,
            modal: seasonalJobModalRef,
        },
        {
            label: i18n.order_motives_label_activity_increase(),
            value: ACTIVITY_INCREASE,
            modal: activityIncreaseModalRef,
        },
    ] as const;

    const [selectedMotive, setSelectedMotive] = useState<(typeof motivesOptions)[number] | null>(
        () => {
            const taskMotive = motives.find((motive) => motive.id === task.motiveId);
            if (taskMotive) {
                return motivesOptions.find((option) => option.value === taskMotive.reason) ?? null;
            }
            return null;
        },
    );

    const queryClient = useQueryClient();
    const {
        mutate: deleteCompanyMotiveMutation,
        isPending,
        variables,
    } = useMutation({
        mutationFn: deleteCompanyMotive,
        onSuccess: (_, variables) => {
            if (selectedJustification?.value === variables.id) {
                setSelectedJustification(null);
            }
            trackEvent({
                name: `companymotives - deleted justification`,
                params: {
                    orgId: company.organisation.id,
                    reason: selectedMotive?.value,
                },
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.companyMotives());
        },
    });

    const justificationsOptions = motives
        .filter((motive) => motive.reason === selectedMotive?.value)
        .map((motive) => ({
            label: motive.justification,
            value: motive.id,
            render: (
                <ListItem
                    justification={motive.justification}
                    onDelete={() => deleteCompanyMotiveMutation({ id: motive.id })}
                />
            ),
        }));

    const [selectedJustification, setSelectedJustification] = useState<{
        label: string;
        value: string;
    } | null>(() => {
        const motive = motives.find((motive) => motive.id === task.motiveId);
        if (!motive) return null;
        const justification = justificationsOptions.find(
            (justification) => justification.value === motive.id,
        );
        return justification ?? null;
    });

    // Once motive and justification selected, enable submit button
    useEffect(() => {
        if (selectedMotive && selectedJustification) {
            setDisabledSubmit(false);
        } else {
            setDisabledSubmit(true);
        }
    }, [selectedMotive, selectedJustification]);

    // Clear justification selection if motive changes, but not on init
    useEffectSkipMount(() => {
        setSelectedJustification(null);
        setOrderDataToSend({});
    }, [selectedMotive]);

    // Open modal when selectedMotive changes and there is no justification
    useEffect(() => {
        if (selectedMotive && !justificationsOptions.length) {
            selectedMotive.modal.current?.showModal();
        }
    }, [selectedMotive]);

    // Update order data to send when justification changes
    useEffect(() => {
        setOrderDataToSend({
            motiveId: selectedJustification?.value,
            motive: motives.find((motive) => motive.id === selectedJustification?.value),
        });
    }, [selectedJustification, motives]);

    // Send tracker for VMS task posting edited
    const { setShouldSendTracker } = useContext(PostingTrackingContext);

    return (
        <div className={styles.motiveStep}>
            <SectionHeader
                title={i18n.order_motives_title()}
                description={i18n.order_motives_subtitle()}
                icon={<FileText />}
                className='mb-8 px-10 pt-8'
            />
            <div className={styles.container}>
                <Select
                    label={i18n.order_motives_label()}
                    placeholder={i18n.order_motives_placeholder()}
                    options={[...motivesOptions]}
                    selection={selectedMotive}
                    onChange={(selection) => {
                        setSelectedMotive(selection);
                        setShouldSendTracker(true);
                    }}
                />
                {selectedMotive ? (
                    <div className={styles.justifications}>
                        <Select
                            label={i18n.order_justifications_label()}
                            placeholder={i18n.order_justifications_placeholder()}
                            options={
                                isPending
                                    ? justificationsOptions.filter(
                                          (option) => option.value !== variables.id,
                                      )
                                    : justificationsOptions
                            }
                            selection={selectedJustification}
                            onChange={(selection) => {
                                setSelectedJustification(selection);
                                setShouldSendTracker(true);
                            }}
                            dropdownFooterComponent={
                                <button
                                    className={styles.addJustificationFooter}
                                    onClick={() => {
                                        selectedMotive.modal.current?.showModal();
                                    }}
                                >
                                    <Plus className='h-4 w-4' />
                                    <span className={fonts.sans18Medium}>
                                        {i18n.order_justifications_add()}
                                    </span>
                                </button>
                            }
                        />
                        <Tip>{i18n.order_justifications_tip()}</Tip>
                    </div>
                ) : null}
            </div>
            <ReplacementModal
                ref={replacementModalRef}
                setSelectedJustification={setSelectedJustification}
            />
            <RecruitmentModal
                ref={recruitmentModalRef}
                setSelectedJustification={setSelectedJustification}
            />
            <TemporaryContractModal
                ref={temporaryContractModalRef}
                setSelectedJustification={setSelectedJustification}
            />
            <SeasonalJobModal
                ref={seasonalJobModalRef}
                setSelectedJustification={setSelectedJustification}
            />
            <ActivityIncreaseModal
                ref={activityIncreaseModalRef}
                setSelectedJustification={setSelectedJustification}
            />
        </div>
    );
}

function ListItem({ justification, onDelete }: { justification: string; onDelete: () => void }) {
    const confirm = useConfirmDialog();
    return (
        <div className={styles.listItem}>
            <span>{justification}</span>
            <Trash2
                className={cn(styles.deleteIcon, "h-4 w-4")}
                onClick={(e) => {
                    e.stopPropagation();
                    confirm({
                        title: i18n.order_justifications_delete_confirmation(),
                        message: "",
                    }).then(() => onDelete());
                }}
            />
        </div>
    );
}
