/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_AUDITIVE_PROTECTION = () => `Auditive protection`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_BIKE = () => `Bike`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_FACIAL_SCREEN = () => `Facial screen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_GLOVES = () => `Gloves`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_HARD_HAT = () => `Hard hat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_HEADPHONES_AND_MIC = () => `Headphones & microphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_LAPTOP = () => `Laptop`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_PROTECTIVE_CLOTHING = () => `Protective clothing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_PROTECTIVE_GLASSES = () => `Protective glasses`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_RESPIRATORY_PROTECTION = () => `Respiratory protection`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_SAFETY_SHOES = () => `Safety shoes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_SMARTPHONE = () => `Smartphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_YELLOW_JACKET = () => `Yellow jacket`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_1 = () => `CACES 1`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_3 = () => `CACES 3`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_5 = () => `CACES 5`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_DRIVING_LICENSE_B = () => `Driving license`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_HEAVY = () => `Heavy load (+15kg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_LIGHT = () => `Light load (less than 5kg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_MEDIUM = () => `Medium load (5 to 15kg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_CHILL = () => `Chill (3 to 5°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_COLD = () => `Cold (0 to 3°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_HOT = () => `Hot (>=30°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_NEGATIVE_COLD = () => `Negative cold (<0°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_ALTERNATING_TEAMS = () => `Work in successive alternating teams`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_CHEMICALS_DUST_FUMES = () => `Chemical agents including dusts and fumes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_EXTREME_TEMPERATURES = () => `Extreme temperatures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_HARD_POSTURES = () => `Hard Postures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_HYPERBARIC_ENV = () => `Activites practised in hyperbaric environment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_MANUAL_HANDLING = () => `Manual handling`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_NIGHT_WORK = () => `Night work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_NOISE = () => `Noise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_REPETITIVE_WORK = () => `Repetitive work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_VIBRATIONS = () => `Mechanical vibrations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_EXCEL = () => `Excel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_GOOGLE_DRIVE = () => `Google Drive`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_INTERCOM = () => `Intercom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_NOTION = () => `Notion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_POWER_POINT = () => `Powerpoint`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_SLACK = () => `Slack`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_ZENDESK = () => `Zendesk`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_cancel = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_confirm = () => `Confirm`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized = () => `Unauthorized`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized_extended = () => `You are not authorized to perform this action.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized_extended_logas = () => `You cannot perform this action in log as.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_form_title = () => `Activate my account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_link_broken = () => `Your activation link seems not to work anymore.\\n\\n

Ask a coworker to send you an other activation link.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_log_in = () => `Activate my account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_logo_company = () => `Company`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_or = () => `or`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_password_placeholder = () => `Password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_title = () => `Sign in to Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_with_google = () => `Activate account with google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add = () => `Add`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const advanced = () => `Advanced`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alb = () => `Albanian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ara = () => `Arabic`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arm = () => `Armenian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const at = () => `at`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aym = () => `Aymara`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aze = () => `Azerbaijani`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const back = () => `Back`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const beginner = () => `Beginner`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bel = () => `Belarusian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ben = () => `Bengali`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bul = () => `Bulgarian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bur = () => `Burmese`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cancel = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cat = () => `Catalan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chi = () => `Chinese`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cnr = () => `Montenegrin`


/**
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_subtitle_contact_plural = (params) => `Find the ${params.siderNumber} Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_subtitle_contact_singular = () => `Find the Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_title_selection_done = () => `Mission completed!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_task_cell_managed_by = () => `Managed by `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_title = () => `Completed Missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const confirm = () => `Confirm`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_contestation_confirmation_toast = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_agreement = () => `By signing the contracts, you accept our <a href='https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/cgu-uk.pdf' target='_blank'>terms and conditions</a>.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_checkbox = () => `I read and checked all information of all the contracts in this document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_error = () => `There was an issue while signing the contracts!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_notice = () => `Once the contracts are signed, you agree to pay the Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_number = () => `Number of contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_title = () => `Contracts signature`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_toast = () => `The document has just been signed. You can find your contracts in the signed section.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_button_label = () => `Go back to the contracts list`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_subtitle = () => `The document is actually unavailable. If the problem persists, please contact the support.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_title = () => `An error occured!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_pagination_last_page = () => `Go to last page of the document in order to sign your contracts. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_phone_number_required = () => `You must first configure your phone number in your settings to be able to sign contracts.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_regenerate_document_label = () => `Re-generate the document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_regeneration_success = () => `The document is being re-generated. This can takes few seconds.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_report_button_label = () => `An error on a contract?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_see_contestation_button_label = () => `See the contestation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_sign_button_label = () => `Sign the contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_date = () => `Start date: `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_manager = () => `Managed by`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_number = () => `contract(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_contested = () => `contested`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_contested_button_label = () => `See`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_disputed_tooltip_content = () => `You contested this document, we'll soon come back to you with an updated version.`


/**
 * @param {{ contestationDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_disputed_tooltip_title = (params) => `Since ${params.contestationDate}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed = () => `signed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed_button_label = () => `Download`


/**
 * @param {{ signatureAuthorName: NonNullable<unknown>, signatureDate: NonNullable<unknown>, signatureTime: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed_tooltip_content = (params) => `Signed by ${params.signatureAuthorName} on ${params.signatureDate} at ${params.signatureTime}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign = () => `to sign`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_button_label = () => `Review and sign`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_late_tooltip_content = () => `Contracts must be signed within 48h after the beginning of the task`


/**
 * @param {{ numberOfDays: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_late_tooltip_title = (params) => `${params.numberOfDays} days late`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_date = () => `Start date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_manager = () => `Manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_number = () => `Number of contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_task = () => `Task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_signed_blank_state_content = () => `You don’t have any signed contracts.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_signed_blank_state_title = () => `Nothing to see here!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_tosign_blank_state_content = () => `You have no pending contracts to sign.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_tosign_blank_state_title = () => `All good here!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_cancel = () => `Cancel`


/**
 * @param {{ contestationDate: NonNullable<unknown>, contestationAuthorName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_details = (params) => `Contested on ${params.contestationDate} by ${params.contestationAuthorName}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_input_label = () => `Contestation motive`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_input_placeholder = () => `Tell us what is wrong with the document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_send = () => `Send`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_subtitle_contested = () => `The contracts of this task have been contested, we'll soon come back to you with an updated version.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_subtitle_tocontest = () => `Explain why you contest this contract so that our team can get back to you with a corrected version of the document.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_title = () => `Contest contract information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_top_menu_filter_signed = () => `Signed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_top_menu_filter_to_sign = () => `To sign`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_belgium = () => `Belgium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_denmark = () => `Denmark`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_estonia = () => `Estonia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_france = () => `France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_germany = () => `Germany`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_great_britain = () => `United Kingdom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_italy = () => `Italy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_netherlands = () => `Netherlands`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_portugal = () => `Portugal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_spain = () => `Spain`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_albania = () => `Albania`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_algeria = () => `Algeria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_andorra = () => `Andorra`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_angola = () => `Angola`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_antigua_and_barbuda = () => `Antigua And Barbuda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_argentina = () => `Argentina`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_armenia = () => `Armenia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_australia = () => `Australia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_austria = () => `Austria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_azerbaijan = () => `Azerbaijan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bahamas = () => `Bahamas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bahrain = () => `Bahrain`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bangladesh = () => `Bangladesh`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_barbados = () => `Barbados`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belarus = () => `Belarus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belgium = () => `Belgium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belize = () => `Belize`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_benin = () => `Benin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bhutan = () => `Bhutan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bolivia = () => `Bolivia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bosnia_and_herzegovina = () => `Bosnia And Herzegovina`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_botswana = () => `Botswana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_brazil = () => `Brazil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_brunei = () => `Brunei`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bulgaria = () => `Bulgaria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_burkina_faso = () => `Burkina Faso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_burundi = () => `Burundi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cambodia = () => `Cambodia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cameroon = () => `Cameroon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_canada = () => `Canada`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cape_verde = () => `Cape Verde`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_chad = () => `Chad`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_chile = () => `Chile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_china = () => `China`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_colombia = () => `Colombia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_comoros = () => `Comoros`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_congo_brazzaville = () => `Congo Brazzaville`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_congo_kinshasa = () => `Congo Kinshasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cook_islands = () => `Cook Islands`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_costa_rica = () => `Costa Rica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_croatia = () => `Croatia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cuba = () => `Cuba`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cyprus = () => `Cyprus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_czech_republic = () => `Czech Republic`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_denmark = () => `Denmark`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_djibouti = () => `Djibouti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_dominica = () => `Dominica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ecuador = () => `Ecuador`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_egypt = () => `Egypt`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_el_salvador = () => `El Salvador`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_equatorial_guinea = () => `Equatorial Guinea`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_eritrea = () => `Eritrea`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_estonia = () => `Estonia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ethiopia = () => `Ethiopia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_fiji = () => `Fiji`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_finland = () => `Finland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_france = () => `France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_gabon = () => `Gabon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_gambia = () => `Gambia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_georgia = () => `Georgia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_germany = () => `Germany`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ghana = () => `Ghana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_greece = () => `Greece`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_grenada = () => `Grenada`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guatemala = () => `Guatemala`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guinea = () => `Guinea`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guinea_bissau = () => `Guinea Bissau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guyana = () => `Guyana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_haiti = () => `Haiti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_honduras = () => `Honduras`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_hungary = () => `Hungary`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iceland = () => `Iceland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_india = () => `India`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_indonesia = () => `Indonesia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iran = () => `Iran`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iraq = () => `Iraq`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ireland = () => `Ireland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_israel = () => `Israel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_italy = () => `Italy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_jamaica = () => `Jamaica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_japan = () => `Japan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_jordan = () => `Jordan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kazakhstan = () => `Kazakhstan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kenya = () => `Kenya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kiribati = () => `Kiribati`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kosovo = () => `Kosovo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kuwait = () => `Kuwait`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kyrgyzstan = () => `Kyrgyzstan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_laos = () => `Laos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_latvia = () => `Latvia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lebanon = () => `Lebanon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lesotho = () => `Lesotho`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_liberia = () => `Liberia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_libya = () => `Libya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_liechtenstein = () => `Liechtenstein`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lithuania = () => `Lithuania`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_luxembourg = () => `Luxembourg`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_macedonia = () => `Macedonia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_madagascar = () => `Madagascar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malawi = () => `Malawi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malaysia = () => `Malaysia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_maldives = () => `Maldives`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mali = () => `Mali`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malta = () => `Malta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_marshall_islands = () => `Marshall Islands`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mauritania = () => `Mauritania`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mauritius = () => `Mauritius`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mexico = () => `Mexico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_micronesia = () => `Micronesia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_moldova = () => `Moldova`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_monaco = () => `Monaco`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mongolia = () => `Mongolia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_montenegro = () => `Montenegro`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_morocco = () => `Morocco`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mozambique = () => `Mozambique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_myanmar = () => `Myanmar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_namibia = () => `Namibia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nauru = () => `Nauru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nepal = () => `Nepal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_netherlands = () => `Netherlands`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_new_zealand = () => `New Zealand`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nicaragua = () => `Nicaragua`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_niger = () => `Niger`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nigeria = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_north_korea = () => `North Korea`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_norway = () => `Norway`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_oman = () => `Oman`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_pakistan = () => `Pakistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_palau = () => `Palau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_palestine = () => `Palestine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_panama = () => `Panama`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_papua_new_guinea = () => `Papua New Guinea`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_paraguay = () => `Paraguay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_peru = () => `Peru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_philippines = () => `Philippines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_poland = () => `Poland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_portugal = () => `Portugal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_qatar = () => `Qatar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_romania = () => `Romania`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_russia = () => `Russia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_rwanda = () => `Rwanda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_kitts_and_nevis = () => `Saint Kitts And Nevis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_lucia = () => `Saint Lucia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_vincent_and_the_grenadines = () => `Saint Vincent And The Grenadines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_samoa = () => `Samoa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_san_marino = () => `San Marino`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sao_tome_and_principe = () => `Sao Tome And Principe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saudi_arabia = () => `Saudi Arabia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_senegal = () => `Senegal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_serbia = () => `Serbia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_seychelles = () => `Seychelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sierra_leone = () => `Sierra Leone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_singapore = () => `Singapore`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_slovakia = () => `Slovakia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_slovenia = () => `Slovenia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_solomon_islands = () => `Solomon Islands`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_somalia = () => `Somalia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_africa = () => `South Africa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_korea = () => `South Korea`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_sudan = () => `South Sudan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_spain = () => `Spain`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sri_lanka = () => `Sri Lanka`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sudan = () => `Sudan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_suriname = () => `Suriname`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_swaziland = () => `Swaziland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sweden = () => `Sweden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_switzerland = () => `Switzerland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_syria = () => `Syria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tajikistan = () => `Tajikistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tanzania = () => `Tanzania`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_thailand = () => `Thailand`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_togo = () => `Togo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tonga = () => `Tonga`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_trinidad_and_tobago = () => `Trinidad And Tobago`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tunisia = () => `Tunisia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_turkey = () => `Turkey`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_turkmenistan = () => `Turkmenistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tuvalu = () => `Tuvalu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uganda = () => `Uganda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ukraine = () => `Ukraine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_arab_emirates = () => `United Arab Emirates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_kingdom = () => `United Kingdom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_states = () => `United States`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uruguay = () => `Uruguay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uzbekistan = () => `Uzbekistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vanuatu = () => `Vanuatu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vatican = () => `Vatican`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_venezuela = () => `Venezuela`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vietnam = () => `Vietnam`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_yemen = () => `Yemen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_zambia = () => `Zambia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_zimbabwe = () => `Zimbabwe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cze = () => `Czech`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dan = () => `Danish`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deadline = () => `Deadline`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const delete_draft = () => `Delete draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_hoursTypesInformations = () => `Remuneration policy - Hour types`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_indemnitiesInformations = () => `Remuneration policy - Benefits`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_legalInformations = () => `Legal information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_paymentInformations = () => `Payment information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_profile = () => `Company profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_status_incomplete = () => `Incomplete`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_validate_label = () => `Complete my information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_contracts = () => `Contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_contracts_signature = () => `Contracts signature`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_home = () => `Home`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_invoices = () => `Invoices`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_job_descriptions = () => `Job Descriptions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_logas = () => `Admin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_not_found = () => `Not found`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_posting = () => `New task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_preselection = () => `Preselection`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_settings = () => `Settings`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_tasks = () => `Missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_timesheets = () => `Timesheets`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_deleted_success = () => `Draft deleted !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_status_cell_subtitle = () => `Finish draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_status_cell_title = () => `Draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_task_cell_created_by = () => `Created by `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_title = () => `Drafts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate = () => `Duplicate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate_draft = () => `Duplicate draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate_task = () => `Duplicate task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_all_subtitle = () => `All your mission will appear here.<br>To start, click the \\"+ Post a new task\\" button at the bottom of the left navigation.`


/**
 * @param {{ firstName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_all_title = (params) => `Hello ${params.firstName}!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_completed_subtitle = () => `You'll be able to find here every completed missions.<br>
One of the Siders did a great job? Don't hesitate to \\"Duplicate the mission\\", if he/she is ok and available, you'll be able to directly ask for him/her.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_completed_title = () => `No completed mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_draft_subtitle = () => `Any unfinished needs? You'll be able to find and complete them here.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_draft_title = () => `No pending draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_ongoing_subtitle = () => `You'll be able to find here every mission on which the Siders are working.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_ongoing_title = () => `No ongoing task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_cta = () => `Post a new mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_subtitle = () => `You don't have any mission planned this week. Display an other week or post a new one`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_title = () => `Nothing planned for this week. You can relax!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_upcoming_subtitle = () => `You'll be able to find every upcoming mission here.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_upcoming_title = () => `No upcoming task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eng = () => `English`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_confirmation_not_equal_password = () => `The two passwords are different`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_is_required = () => `This field is required.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_is_required_checkbox = () => `This answer is required. Please select at least one option.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_required = () => `This field is mandatory`


/**
 * @param {{ maxSize: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_file_size = (params) => `File size exceeds the limit of ${params.maxSize}MB`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_invalid_format = () => `Invalid format`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_invalid_shift = () => `The shift you created is not valid. Its duration must be between 2h and 10h of work break included.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_no_auth = () => `An error occured: we could not authenticate you.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_password_length_8 = () => `Your password must be at least 8 characters long`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_password_special_char = () => `Your password must contain at least one special character`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_phone_number_invalid = () => `Invalid phone number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_email_conflict = () => `Email already in use. Please connect instead`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_generic = () => `An error occured`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_organisation_forbidden_blocked = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_shift_conflict_duplicated = () => `This shift has already been posted, you can't duplicate it.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_shift_forbidden_post_in_the_past = () => `This task includes shifts posted in the past, you can either delete them or get in touch with our support team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_sider_conflict_already_exists = () => `This sider already exists on Side's platform. Please reach the support team if you still want to add it to your Side Flash siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_slot_forbidden_requested_siders_count = () => `Oops, you requested too many Siders given the number of slots available for this task.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_task_forbidden_not_enough_siders_selected = () => `Not enough Siders selected`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_task_salary_forbidden_too_low = () => `The job description couldn't be created, the pay must be equal or above the current minimum wage.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_token_forbidden = () => `Your activation link is expired or invalid.\\n\\n

Ask a coworker to send you an other activation link.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_user_unauthorized_login_failed = () => `Failed to log in. Check your credentials and try again.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const est = () => `Estonian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const expert = () => `Expert`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_dialog_content = () => `Changes you made won’t be taken into account.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_dialog_title = () => `Don't save changes?`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question = (params) => `Do you have anything to add about ${params.name}?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question_label = () => `Private comment (optional)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question_placeholder = () => `E.g. I really liked how dynamic he was but he could be more thorough...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_1 = () => `Yes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_1_notice = () => `I'm satisfied`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_2 = () => `No`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_2_notice = () => `I'm not satisfied`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_question = (params) => `Would you be ready to work again with ${params.name} on similar tasks?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_1 = () => `Professionalism`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_1_notice = () => `The Sider's behaviour wasn't professional enough`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_2 = () => `Performance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_2_notice = () => `The Sider couldn't reach his goals.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_description = () => `We are sorry to hear it didn't match with your expectations.Please tell us what went wrong with this Sider.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_question = (params) => `What was the issue with ${params.name}?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_send_no_comment = () => `Send<span class=\\"send-hide-mobile\\"> without any comment</span>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_exit_description = () => `Changes you made won’t be taken into account.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_exit_title = () => `Don’t save changes ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_title = () => `Your feedback about...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_description = () => `Tell us if you were satisfied with this Sider. This will help us suggest to you only the profiles that you liked. You can edit this feedback whenever you want.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_description_rated = () => `Don't forget to tell us whether you were satisfied or not with your Siders. This will help us suggest to you only the profiles that you liked. You can edit this feedback whenever you want.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_title = () => `Would you be ready to work with this Sider again?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_title_rated = () => `Have you changed your mind about this Sider?`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_not_satisfied = (params) => `You aren't satisfied with ${params.name}'s service. The Sider has been removed from your favourite Siders's list`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_not_saved = (params) => `Your feedback about ${params.name} wasn't saved.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_satisfied = (params) => `You are satisfied with ${params.name}'s service! The Sider has been added to your favourite Siders' list, you'll be able to request this Sider in priority.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tooltip_not_satisfied = () => `I'm not satisfied with this Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tooltip_satisfied = () => `I'm satisfied with this Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fin = () => `Finnish`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const finish = () => `Finish`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const finish_draft = () => `Finish draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluent = () => `Fluent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fre = () => `French`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ger = () => `German`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const go_to_contracts_signature_page = () => `Sign contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gre = () => `Greek`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grn = () => `Guarani`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_incomplete = () => `incomplete`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_legal = () => `Legal information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_payment = () => `Payment information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_policy_hours = () => `Salary policy - Hours type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_policy_indemnities = () => `Salary policy - Benefits`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_profile = () => `Company profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_submit = () => `Update my information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_subtitle = () => `Complete your profile to post missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_title = () => `Update your information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_backhome = () => `Home`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_contracts = () => `Contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_conversations = () => `Messages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_cta_task = () => `Post a new task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_cta_team = () => `My team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dashboard = () => `Dashboard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_departments = () => `Departments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_help = () => `Help center`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_language = () => `Voir en Français`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_logout = () => `Sign out`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_payment_settings = () => `Payment information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_personal_settings = () => `Personal information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_profile = () => `Company profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_settings = () => `Entity settings`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_team = () => `Team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_invoices = () => `Invoices`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_job_descriptions = () => `Job Descriptions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_payments = () => `Payments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_search_company = () => `Search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_settings = () => `Settings`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_siders = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_switch_search_placeholder = () => `Search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_tasks = () => `Tasks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_team = () => `Team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_timesheets = () => `Timesheets`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heb = () => `Hebrew`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hin = () => `Hindi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_company_logo_button_tooltip = () => `Update logo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connecttochild_button = () => `Access the workspace`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_search_child_label = () => `Search for an entity`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_search_child_placeholder = () => `Name or address of the entity`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_welcome_subtitle = () => `Post and manage your tasks by accessing your entity's workspace`


/**
 * @param {{ groupName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_welcome_title = (params) => `Welcome to ${params.groupName}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_0 = () => `First qualified CVs in <b>1 week</b>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_1 = () => `<b>Qualified profiles</b> in video of at least 30 mins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_2 = () => `<b>Transparent</b> feedback at every stage`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_title = () => `A dedicated recruitment expert`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_message = () => `A Side recruitment expert is now dedicated to you to discuss it and develop your tailor-made offer with you.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_primary_action = () => `Book a call`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_secondary_action = () => `Later`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_title = () => `Thank you! We have noted your need for a permanent contract!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_differentiate_title = () => `What sets us apart`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_address_label = () => `Address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_commentary_label = () => `Further information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_commentary_placeholder = () => `Link to a job description or other element to share`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_confirmation = () => `Your permanent contract requirement has been submitted. We will get back to you as soon as possible!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_document_label = () => `Document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_label = () => `Job description or job offer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_link_label = () => `Link`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_none_label = () => `Not available at the moment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobtitle_label = () => `Job title`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_positions_label = () => `Number of permanent contract requirements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_salary_label = () => `Gross annual salary`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_startdate_label = () => `Planned start date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_title = () => `Need to recruit on a permanent basis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_nav_button_desc = () => `Discover our offer!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_nav_button_title = () => `Need a permanent contract?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_0 = () => `A <b>dedicated</b> mobile application for candidates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_1 = () => `<b>100K candidates</b> looking for permanent contracts in France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_2 = () => `History of candidates who have <b>already</b> worked with us`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_title = () => `A unique & motivated pool`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_0 = () => `Charged <b>only</b> on success`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_1 = () => `Based on <b>gross annual salary</b> excluding tax`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_2 = () => `No deposit or exclusivity`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_3 = () => `<b>Guarantee</b> 30 days`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_0 = () => `Submit your need`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_1 = () => `Develop a tailor-made offer with your dedicated expert`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_2 = () => `Receive the best CVs and interview reports`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_3 = () => `Continue your internal recruitment process`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_4 = () => `Our talent is now yours!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_steps_title = () => `5 key moments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_subtitle = () => `Recruit on a permanent basis using a simple, efficient and transparent process.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_title = () => `Recruit on a permanent contract with the Horizon offer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hrv = () => `Croatian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hun = () => `Hungarian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ice = () => `Icelandic`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ind = () => `Indonesian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_logistics = () => `Logistics`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_office = () => `Office`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_retail = () => `Retail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const intermediate = () => `Intermediate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_amount = () => `Amount Incl. tax`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_date = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_download = () => `Download the invoice`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_download_error = () => `Invoice unavailable`


/**
 * @param {{ month: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_download = (params) => `Download all invoices for ${params.month}`


/**
 * @param {{ month: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_download_failed = (params) => `Download available invoices for ${params.month}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_error_body = () => `Please contact our online support for more information.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_error_title = () => `One invoice or more couldn't be issued.`


/**
 * @param {{ month: NonNullable<unknown>, amount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_notice = (params) => `In ${params.month} you spent ${params.amount} € on the platform. We bundled all your invoices for your accounting right here!`


/**
 * @param {{ month: NonNullable<unknown>, amount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_notice_failed = (params) => `In ${params.month} you spent ${params.amount} € on the platform. We bundled your available invoices for your accounting right here!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_number = () => `Document number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_sider = () => `Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status_paid = () => `Paid`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status_unpaid = () => `To pay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_task = () => `Task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type = () => `Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type_creditNote = () => `Credit note`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type_invoice = () => `Invoice`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ita = () => `Italian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_copy_id = () => `ID copied!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_create_button = () => `Create`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_characters = () => `400 signs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_placeholder = () => `You'll be part of a team of 5 and your scope will include...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_title = () => `Context and goals`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_subtitle = () => `You can give some indications about the context to help Siders understand the mission better.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_title = () => `About`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_company = () => `Provided by the company`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_side = () => `Provided by Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_sider = () => `Provided by the Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_table_header_label = () => `Equipment type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_table_header_value = () => `Details`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_dresscode_placeholder = () => `If needed, indicate the required dress code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_dresscode_title = () => `Dress code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_add_button = () => `Add equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_add_custom_button = () => `Add a custom element`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_custom_modal_input_label = () => `Equipment name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_custom_modal_tip = () => `If you didn't find the equipment you want in the list, you can add a custom one just below.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_delete_button = () => `Delete this equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_subtitle = () => `Select the equipment that the Siders will need to bring.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_title = () => `Other equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_label1 = () => `Occupational health risks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_label2 = () => `Additional information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_options = () => `Select an option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_placeholder = () => `Add any detail you might find important to take the risks into account.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_subtitle = () => `Indicate the occupational health risks for this mission, they are set by labor law and require to implement prevention measures.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_title = () => `Risks and difficult working conditions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_add_button = () => `Add PPE`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_add_custom_button = () => `Add a custom item`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_custom_modal_input_label = () => `Name of PPE`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_custom_modal_tip = () => `If you didn't find the equipment you want in the list, you can add a custom one just below`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_delete_button = () => `Delete this equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_choice_no = () => `No`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_choice_yes = () => `Yes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_label = () => `Do you provide this equipment?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_subtitle = () => `Indicate if you provide the safety equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_subtitle = () => `Select the necessary PPE to ensure Siders safety.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_title = () => `Safety Equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_subtitle = () => `Indicate the Personal Protective Equipment (PPE) and other items that are necessary for the mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_title = () => `Equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_step_title = () => `Work conditions`


/**
 * @param {{ numberOfTasks: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_subtasks = (params) => `${params.numberOfTasks} tasks selected`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_subtitle = () => `This information will allow us to select Siders that will match the job.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_title = () => `Job title`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_name_title = () => `Name of the job description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_step_title = () => `Job`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_add_button = () => `Add a custom element`


/**
 * @param {{ searchInput: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_add_button_input = (params) => `Add '${params.searchInput}'`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_label = () => `Task name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_placeholder = () => `Custom task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_subtitle = () => `If you didn't find the task you need in the list, you can add a custom one just below.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_title = () => `Add a custom element`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_subtitle = () => `Select tasks that Siders will need to fill during the mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_title = () => `Tasks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_location_step_title = () => `Location`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_error = () => `Please check the value you entered`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_error_underflow = () => `Please input a salary that is equal to or above the minimal wage`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_label = () => `Base salary (hourly)`


/**
 * @param {{ minimumHourlyRate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_placeholder = (params) => `Superior or equal to ${params.minimumHourlyRate}€/hour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_label2 = () => `Hourly rates and coefficient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_raw = () => `Gross hourly rate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_step_title = () => `Salary`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_subtitle = () => `Indicate the base salary for this job, it will allow us to give you a price estimate for your next orders. According to the equality of treatment principle, enter the base salary that matches your company's grid for permanent employees.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_title = () => `Pay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_label = () => `Type of work experience`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_placeholder = () => `We wish to find profiles that know about the industry...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_subtitle = () => `Indicate if you wish Siders to have specific work exeperience, this will enable Siders to assess chances to be selected for the mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_title = () => `Experience`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_add_button = () => `Add a language`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_edit_disclaimer = () => `Click the Save button to delete all languages.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_label = () => `Level required`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_placeholder = () => `Select an option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_subtitle = () => `Indicate the required level, it will be used to assess oral and written skills.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_title = () => `Languages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_subtitles = () => `Select the languages that the Siders should master.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_title = () => `Languages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_tools_subtitle = () => `Indicate whether the Siders need to master any specific languages or tools.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_tools_title = () => `Languages and tools`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_add_button = () => `Add a licence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_label = () => `Licence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_subtitle = () => `Indicate which types of licence are needed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_title = () => `Documents`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_step_title = () => `Skills`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_add_button = () => `Add a tool`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_add_custom_button = () => `Add a custom element`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_label = () => `Tool name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_placeholder = () => `Photoshop, Teams, Salesforce...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_tip = () => `In case you didn't find the tool you want in the list, you can add a custom one just below.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_excel_feedback = () => `- Débutant : Saisie de données, formules et calculs simples, impression de document, mise en forme de cellules.
- Intermédiaire : Formules (SI, MAX, MIN, SOMME.SI, NB.SI), graphiques (création, mise en forme), fonctions de tri/filtres, création d’un tableau croisé dynamique, recherche de données, validation de données.
- Avancé : Automatisation de tâches : exécuter des macros enregistrées, calculs avancés (calculs multi-feuilles/classeurs), fonctions avancées et formules imbriquées, fonctions de base de données, graphiques de TCD.
- Expert : VBA, formules complexes (matricielles, financières), maîtrise experte de la mise en forme, calculs, TCD, outils d’analyse.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_placeholder = () => `Select an option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_subtitle = () => `Indicate the level needed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_subtitle = () => `Select the tools that the Siders should master.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_title = () => `Tools and software`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_status_subtitle = () => `The status of your Siders will be reported in their employment contract and will determine the amount of social security contributions to be paid.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_status_title = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_success = () => `Your job description was successfully created`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_draft = () => `Draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_context_subtitle = () => `You can edit the indications about the context and the goals of the mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_devices_subtitle = () => `You can edit the equipment that the Siders will need to bring.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_dresscode_subtitle = () => `You can change the required dress code.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_experiences_subtitle = () => `You can edit the work experience you wish the Siders to have.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_jobTitle_subtitle = () => `You can change the job title, you will need to select the tasks related to this job title again.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_languages_subtitle = () => `You can edit the languages or tools the Siders should know.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_licences_subtitle = () => `You can edit which types of licence are needed.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_location_subtitle = () => `You can edit the information related to the mission location.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_manager_subtitle = () => `You can edit the name of the manager for this job.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_name_subtitle = () => `You can rename your job description to easily differentiate it, the custom name will be used as a reference on your invoices`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_preselection = () => `Pre-selection of candidates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_risks_subtitle = () => `You can edit the occupational health risks for this mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_safetyEquipment_subtitle = () => `You can edit the list of safety equipment and other material required for the mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_salary_subtitle = () => `You can edit the base salary for this job.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_status_subtitle = () => `You can modify the contract status as well as the corresponding remuneration for the mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_subtasks_subtitle = () => `You can edit the tasks that Siders will fill during this mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_success = () => `Your job description was successfully updated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_tools_subtitle = () => `You can edit the tools that the Siders should master.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_create_button = () => `Create a job description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_subtitle = () => `Your job descriptions will allow you to place an order.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_subtitle_link_label = () => `Learn more`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_title = () => `No job description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions = () => `In order to place a new order, please select an existing job description or create the job description that fits your needs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions_account = () => `In order to place a new order, please select an existing job description or create the job description that fits your needs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions_network = () => `You can create and update job descriptions that fit your needs.\\nThey will be usable directly by every account in your group.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_jobtitle = () => `Job title`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_list_network_description = () => `These job descriptions come from your network, you can use them directly to create your orders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_new_title = () => `Create a job description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_rename_field_placeholder = () => `Custom task name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_rename_tip = () => `This new name will not be visible by Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_ETAM = () => `Technician employee and Supervisor (ETAM)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_executive = () => `Executive`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_worker = () => `Worker`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_context_title = () => `Context`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_devices_title = () => `Other material`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_dresscode_title = () => `Dress code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_experiences_title = () => `Experiences`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_jobTitle_title = () => `Job Title`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_languages_title = () => `Languages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_licences_title = () => `Licence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_location_title = () => `Address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_manager_title = () => `Manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_name_title = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_risks_title = () => `Risks and difficult working conditions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_safetyEquipment_title = () => `Safety equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_salary_title = () => `Pay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_status_title = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_subtasks_title = () => `Tasks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_tools_title = () => `Tools and software`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_custom = () => `You can’t update your job descriptions because you benefited from a custom configuration. Contact us to operate changes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_description = () => `You are not allowed to edit this job description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_account = () => `Please select a new one in order to use this job description.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_missing = () => `The selected manager is no longer part of the company.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_network = () => `The manager must be changed from the group space.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network = () => `This job description comes from your network, you are not authorized to update it.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network_redirect_description = () => `You can edit this job description from the network space`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network_redirect_title = () => `Action unavailable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_contact_content = () => `Contact us using the chat or at <a href='mailto:in@side.co'>in@side.co</a>, we might be able to help you!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_contact_title = () => `Can’t find what you're looking for?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_more = () => `And more...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_subtitle = () => `Side selects the best profiles for your company within hours and automates all the admin work.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_title = () => `Create a new task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jpn = () => `Japanese`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_status_cell_subtitle = () => `Assessing your needs...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_status_cell_title = () => `Selection is about to start...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_task_cell_managed_by = () => `Managed by `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_start_date = () => `Start date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_status = () => `Information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_task = () => `Mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_title = () => `Task in preparation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const khm = () => `Khmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kon = () => `Kongo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kor = () => `Korean`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_continue = () => `Continue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_delete = () => `Delete`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_new = () => `New`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_task_title = () => `Latest mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lav = () => `Latvian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const learn_more = () => `Learn more`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lin = () => `Lingala`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lit = () => `Lithuanian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_addition = () => `This address was successfully added to you favorite list`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_deletion = () => `This address was successfully deleted from your favorite list`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_update = () => `Your favorite list was successfully updated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_no_street_number = () => `Add a street number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_label = () => `Address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_title_label = () => `Title`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_title_placeholder = () => `Site #1`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_label = () => `Favorite addresses`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_placeholder = () => `Enter the address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_subtitle = () => `Enter the most common addresses you will use.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_title = () => `Add a favorite address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_label1 = () => `Address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_label2 = () => `Additional details`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_option1 = () => `Remote work is allowed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_option2 = () => `Siders need to have their own transport`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_placeholder1 = () => `Enter an address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_placeholder2 = () => `Add details that could help Siders to find their way to the workplace.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_subtitle = () => `These information will allow us to select Siders who can easily get access to the mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_title = () => `Work location`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_impersonate_button = () => `Log as `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_input_label = () => `Name, email or id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_logout_button = () => `Log out`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mac = () => `Macedonian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_choice_later = () => `Select later`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_choice_now = () => `Select now`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_label = () => `Select the manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_subtitle = () => `Select the task manager, this person will get all the information about the task and will be in charge of approving timesheets.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_title = () => `Manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_update_success = () => `Manager updated !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const may = () => `Malay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mlg = () => `Malagasy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_1 = () => `Once your order has been approved by our team, selection starts immediately. You'll receive a confirmation email specifying the date and time of the final selection. You'll also be able to follow the exact selection process directly on your Side account.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_2 = () => `Once your task has been validated, we guarantee to fill all the shifts you requested. If one of your Siders is missing due to a major unexpected event, they'll be replaced by another equally qualified Sider. On Side, 90% of tasks are filled within 24 hours.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_3 = () => `During the selection process, Siders enter their education and previous experience. They go through an interview and must pass targeted tests according to each job type. Data analysis enables our team to select the profiles that are best suited to match your needs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_4 = () => `On Side, we implement a weekly payment cycle. From Monday to Sunday, Siders enter their hours thanks to a clock in/clock out system. Time entries are automatically sent to your account and you can edit those hours if you need to. Payments are triggered every Tuesday and a weekly invoice is generated automatically.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_description = () => `What's next?<br />Here are 4 questions you might be asking yourself right now.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_faq = () => `<a href='https://support.side.co/b2b/fr/' target='_blank'>Check out our FAQs</a> for any questions you might have. In cases of emergency contact us via our online chat and we'll get back to you very quickly!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_1 = () => `What happens once I post my task?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_2 = () => `How do I know all my shifts will be filled?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_3 = () => `What is the selection process?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_4 = () => `How and when do I pay?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_subtitle = () => `Roger that`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_tip_4 = () => `If you think you'd benefit from a custom-made payment solution adapted to your accounting system, please email in@side.co. A team member will review your request and be in touch as soon as possible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_title = () => `We've received your task!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_description = () => `Are you sure you want to delete this draft?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_job_description_content = () => `Once deleted, you will not be able to access this job description anymore`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_job_description_title = () => `Do you really want to delete this job description ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_title = () => `Delete draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_duplicate_description = () => `Don’t worry, you’ll be able to edit it before it gets published!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_duplicate_title = () => `Are you sure you want to duplicate this task?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_manager_update_field_tip = () => `This person will have the responsibility to validate the hours worked by the Siders, so please make sure that she/he will be available to do it.<br>
Without this validation, we can not pay the Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_button = () => `Go to job descriptions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_description = () => `The mission command system has changed. To make a new request, follow the following steps.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_step_1 = () => `Go the <em>Job descriptions</em> page`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_step_2 = () => `Create a new Job description or choose the one of your choice to post your future shifts.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_title = () => `Unable to duplicate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_button_cancel = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_button_ok = () => `Continue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_description = () => `If you wish, you can give a customised name to this task in order to differentiate it from the others. This new name will be used as a reference on your invoices.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_error_too_long = () => `New name can't be longer than 25 characters.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_label = () => `Task name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_placeholder = () => `Customised name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_tip = () => `This new name won't be visible to Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_title = () => `How do you want to call this task?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_update_manager_input_label = () => `Task manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_update_manager_title = () => `Change task manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modify = () => `Modify`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mon = () => `Mongolian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_link_label = () => `in this article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_tip = () => `For the contract to be compliant, you need to explain in details why you have an activity increase. Learn more `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_label = () => `Justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_length = () => `remaining characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_placeholder = () => `E.g: Peak activity, sales, annual store inventory...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_subtitle = () => `Here you must specify as much as possible the reason why the company is experiencing a temporary workload. More information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_title = () => `Temporary activity increase`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_validation = () => `Type at least 6 characters`


/**
 * @param {{ index: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_count = (params) => `Hired person n°${params.index}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_date_label = () => `Arrival date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_date_placeholder = () => `dd/mm/yyyy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_link_label = () => `in this article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_tip = () => `For the contract to be compliant, you need to fill the following information to justify your motive choice. Learn more`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_name_label = () => `Name of new hire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_name_placeholder = () => `E.g. Hugo Marchand`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_position_label = () => `Position of new hire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_position_placeholder = () => `E.g. cashier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_subtitle = () => `This reason for appeal is only possible while awaiting the arrival of a person already recruited on a permanent contract. More information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_title = () => `Waiting for an employee on permanent contract`


/**
 * @param {{ index: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_count = (params) => `Person to replace n°${params.index}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_link_label = () => `in this article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_tip = () => `For the contract to be compliant, you need to fill the following information to justify your motive choice. Learn more `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_name_label = () => `First and lastname`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_name_placeholder = () => `E.g. Hugo Marchand`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_position_label = () => `Occupation of replaced employee`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_position_placeholder = () => `E.g. cashier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_reason_label = () => `Reason for absence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_reason_placeholder = () => `E.g. sick leave`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_subtitle = () => `You must indicate in your Sider's contract who he or she must replace and why this person is absent. More information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_title = () => `Replacement of a missing employee`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_link_label = () => `in this article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_tip = () => `For the contract to be compliant, you have to explain in details why your need justifies the use of temporary work. Learn more`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_label = () => `Specify why you need Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_length = () => `remaining characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_placeholder = () => `E.g: September harvest, winter sport (ski instructor)...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_subtitle = () => `You can only use this reason for certain activities that repeat each year at the same time. More information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_title = () => `Seasonal employment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_validation = () => `Type at least 6 characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_link_label = () => `in this article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_tip = () => `For the contract to be compliant, you have to explain in details why your need justifies the use of temporary work. Learn more `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_label = () => `Specify why you need Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_length = () => `remaining characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_placeholder = () => `E.g: Leisure and vacation centers, audiovisual...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_subtitle = () => `Temporary customary works are only possible in strictly limited cases and determined by article D1251-1 of the labor code. More information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_title = () => `Temporary contract`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_validation = () => `Type at least 6 characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const native = () => `Native`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nld = () => `Dutch`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no = () => `No`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_invoice_company = () => `You haven't received any invoice so far.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_invoice_month = () => `You didn't get any invoice this month.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_result = () => `No result.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nor = () => `Norwegian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const oci = () => `Occitan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_intro = () => `You are the first administrator in your company. You can already invite your co-workers and assign roles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_1_content = () => `Complete your profile, settings and put your first task online.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_1_title = () => `Share your needs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_2_content = () => `Side takes care of finding the exact profiles you need within hours. You also get access to your real-time planning and sign your contracts online.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_2_title = () => `Follow the hiring process and sign your contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_3_content = () => `You can find all your Siders' timesheets in one-single place. Benefit from our simple validation system and automatically get your invoices weekly.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_3_title = () => `Validate hours and receive your invoices`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_title = () => `Welcome to Side!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_understood = () => `Understood`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_display_planning = () => `Display planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_display_preselection = () => `Display selection`


/**
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_subtitle_contact_plural = (params) => `View the ${params.siderNumber} Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_subtitle_contact_singular = () => `View the Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_title_selection_done = () => `Selection done!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_task_cell_managed_by = () => `Managed by `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_title = () => `Ongoing Missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const optional = () => `optional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_label = () => `Additional info (optional)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_placeholder = () => `Eg: mask, gel and gloves, travel can be covered, etc.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_service_description = () => `Categorize your missions by associating them with a service (site, department, etc.). Add new services at any time on the <a href="/settings/departments" target="_blank">“Services” tab of your settings.</a>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_service_placeholder = () => `Select a service`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_service_title = () => `Service`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_subtitle = () => `Write additional information that Siders should have`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_title = () => `Additionnal information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_add = () => `Add a new justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_create_action = () => `Create a justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_delete_confirmation = () => `Are you sure you want to delete?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_label = () => `Justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_placeholder = () => `Select a justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_tip = () => `The choice of justification will be reported in your siders' contract. If you renew a contract using the same justification, a contract prolongation will be automatically generated.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_add = () => `Add a substitute`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_error_6_chars = () => `Type at least 6 characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label = () => `Reason`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_activity_increase = () => `Temporary activity increase`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_replacement = () => `Replacement of one employee`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_seasonal_activity = () => `Seasonal employment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_temporary_contract = () => `Temporary contract`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_waiting_recruitment = () => `Waiting for an employee on permanent contract`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_placeholder = () => `Select a reason`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_show_details = () => `Show details`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_subtitle = () => `Select the reason why you are asking for one or multiple Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_title = () => `Reason`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_button_label = () => `Continue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_subtitle = () => `You'll find here the profiles with whom you liked working next time.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_title = () => `No Siders yet?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_cancel_label = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_coefficient_column = () => `Coefficient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_delegation_description = () => `Our recruiters support you in recruiting temporary workers, with whom you have already worked or not.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_delegation_label = () => `I am looking for temporary workers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_gestion_description = () => `Add the profiles you source to Side and automate 100% of your administrative procedures.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_gestion_label = () => `I already have my profiles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_hourlyrate_column = () => `Cost`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_hourtype_column = () => `Hour type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_increaserate_column = () => `Increase rate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_modal_title = () => `Create a new mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_pricing_table_title = () => `Hourly rates and indicative coefficient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_subtitle = () => `Select the option that fits your needs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_label = () => `Personalize the name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_optional = () => `optional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_placeholder = () => `Add a suffix to the mission name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_tip = () => `Mission name customization will not be visible to Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_tasktype_label = () => `Define my needs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_title = () => `New order`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_delegation_label = () => `Side Source (Hiring)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_gestion_label = () => `Side Flash (Payroll Management)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_label = () => `Mission type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_validate_label = () => `Continue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_price = () => `Cost (€/h)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_quantity = () => `Qty`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_total_ht = () => `Total (w/o VAT)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_type = () => `Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_shifts_subtitle = () => `Specify the shifts and the number of Siders per shift for this task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_shifts_title = () => `Shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_details = () => `Details`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_motive = () => `Motive`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_shifts = () => `Shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_summary = () => `Summary`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_workers = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_succes_display_planning = () => `Display my planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_1 = () => `Your mission is live and will start automatically`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_2 = () => `Our team is selecting the most pertinent Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_3 = () => `You can check the status of the selection on your planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_title = () => `Your mission has been posted`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_address_additional_infos = () => `Address - additional information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_address_title = () => `Address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_base_wage_disclaimer = () => `Base wage is configured in your job description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_button = () => `Confirm`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_cancellation_fees = () => `Once your order is confirmed, cancellation or edition fees may be applied`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_checkbox = () => `I understand the cancellation policy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_footnote = () => `You will be charged based on the validated timesheets of your temp workers. Once your need is validated, you will receive a confirmation e-mail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_sidenote = () => `Note for Side team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_sidenote_placeholder = () => `Enter your comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_title = () => `Confirm your order`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_base_wage = () => `Base wage (normal hours)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_details = () => `Show details`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_disclaimer = () => `This estimate includes end of mission fees and paid holidays but does not include bonus and additional salary which can impact the final price`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_planning_tip = () => `As this mission has a flexible schedule, the total estimate is likely to change depending on the hours actually worked.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_title = () => `Price Estimate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_total_with_taxes = () => `Total Estimate (20% VAT)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_total_without_taxes = () => `Total Estimate excl. VAT`


/**
 * @param {{ startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_from_to_date = (params) => `from ${params.startDate} to ${params.endDate}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_info_title = () => `Additional information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_justification_title = () => `Justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_manager_title = () => `Manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_motive_title = () => `Reason`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_motorized_title = () => `Motorized Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_name_title = () => `Order name`


/**
 * @param {{ siders: NonNullable<unknown>, weeklyVolume: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_1 = (params) => `${params.siders} siders at ${params.weeklyVolume} hours per week`


/**
 * @param {{ startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_2 = (params) => `from ${params.startDate} to ${params.endDate}`


/**
 * @param {{ amplitudeStart: NonNullable<unknown>, amplitudeEnd: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_3 = (params) => `from ${params.amplitudeStart} to ${params.amplitudeEnd} maximum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_and = () => `and`


/**
 * @param {{ total: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_figures = (params) => `That is ${params.total} hours over`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_holidays = () => `(incl. public holidays)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_saturday = () => `saturday`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_sunday = () => `sunday`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_weekDays = () => `the week`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_title = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_remote_title = () => `Remote`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_risks_additionnal_infos = () => `Risks - additional details`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_title = () => `Shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_FAQ_link = () => `https://support.side.co/b2b/fr/`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_holiday = () => `You have added a slot on a public holiday whose remuneration is increased according to your remuneration policy. Check that this day is well worked in your company.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_holiday_interval = () => `Your mission period includes a public holiday. Public holidays not worked are remunerated at the usual rate if they are part of the usual mission days.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_link_text = () => `See our FAQ for more information.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_title = () => `Order summary`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_total_week = () => `Total Week`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_delegation_description = () => `You work with profiles recruited by our teams`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_delegation_title = () => `Delegation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_gestion_description = () => `You have found profiles by yourselves, we only manage the administration.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_gestion_title = () => `Management`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_title = () => `Order type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_workers_title = () => `Requested Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_workers_wa_warning = () => `Only Siders you have selected will be able to apply and be placed on the mission. Please confirm with them their availability. If they are not available, we will cancel the mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_yes = () => `Yes`


/**
 * @param {{ numberOfFilters: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_button_label = (params) => `Filters (${params.numberOfFilters})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_button_delete = () => `Reset all filters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_experience_label = () => `Experience`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_experience_placeholder = () => `Add a job title`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_false = () => `Display all Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_label = () => `Feedback`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_placeholder = () => `Select a feedback type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_true = () => `Only display Siders with a positive feedback`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_1 = () => `Less than a month`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_3 = () => `Less than 3 months`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_all = () => `Display all Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_label = () => `Last task done`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_placeholder = () => `Select a period`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_subtitle = () => `Use filters to refine your search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_title = () => `Filters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_button_label = () => `Edit filters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_subtitle = () => `Edit the filters to display more Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_title = () => `No result`


/**
 * @param {{ numberOfRemainingSlots: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_amount = (params) => `Select at least ${params.numberOfRemainingSlots} more Siders to continue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_noresults_subtitle = () => `Add your own profiles to work with them on this task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_noresults_title = () => `No Siders yet?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_button_label = () => `Add a profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_error = () => `This profile already has an account on Side. If you want to work with this profile, please contact Side customer support via the chat or email care@sidetemp.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_firstname_label = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_firstname_placeholder = () => `John`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_lastname_label = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_lastname_placeholder = () => `Doe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_phone_label = () => `Phone number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_phone_placeholder = () => `(0)6 07 08 09 10`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_subtitle = () => `Add the details of the profile you would like to work with, we will handle everything else.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_title = () => `Add a profile`


/**
 * @param {{ numberOfSharedWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_network_label = (params) => `My network (${params.numberOfSharedWorkers})`


/**
 * @param {{ numberOfOrganisationWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_orga_label = (params) => `My Siders (${params.numberOfOrganisationWorkers})`


/**
 * @param {{ numberOfSelectedWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_selected_label = (params) => `Selected (${params.numberOfSelectedWorkers})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_button_label = () => `Display Siders from my network`


/**
 * @param {{ numberOfWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_counter = (params) => `${params.numberOfWorkers} available Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_subtitle = () => `Ask for already trained Siders from your network!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_title = () => `No Siders yet?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_noresults_subtitle = () => `Edit your search to display more Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_noresults_title = () => `No result`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_placeholder = () => `Sider's name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_subtitle = () => `Work again with Sider you know`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_title = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_subtitle = () => `You chose to exclusively open the mission to requested Siders. Only those Siders will be able to apply and work on this mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_title = () => `Open the mission to requested Siders only`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_warning = () => `Be aware that this option requires that selected Siders are all available for the task. Do not confirm it unless you have spoken with them beforehand. If they are not available, no Sider will be selected.`


/**
 * @param {{ numberOfRemainingSlots: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_amount = (params) => `Select at least ${params.numberOfRemainingSlots} more Siders to use this option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_label = () => `Open the mission to requested Siders only`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_content_activated = () => `Only Siders you requested will be able to apply and be selected on this task. Side will not update the selection with other Siders if the one you requested are not available.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_content_deactivated = () => `By activating this option, only the siders that you have selected will be able to apply to your task. Tick this checkbox only if you have confirmed with those siders that they are available.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_title_activated = () => `Activated option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_title_deactivated = () => `Deactivated option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const per = () => `Persian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations = () => `Personal informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_email = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_email_placeholder = () => `youremail@domain.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_incorrect_password = () => `Incorrect password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password = () => `Invalid password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password_format = () => `Password must contain at least one special character`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password_length = () => `Password must be at least 9 characters long`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_phone = () => `Your phone number format is not valid.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_firstname = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_firstname_placeholder = () => `Your first name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_general_information_description = () => `This information is your contact information and allows us to communicate with you.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_general_information_title = () => `General information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_lastname = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_lastname_placeholder = () => `Your last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password = () => `New password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_confirmation = () => `Confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_confirmation_placeholder = () => `**********`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_placeholder = () => `**********`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_button = () => `Change password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_confirm_field = () => `Confirm password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_new_field = () => `New password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_old_field = () => `Old password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_save_button = () => `Save change`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_title = () => `Change password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_success = () => `Password has been updated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_tip = () => `The new password must be at least 9 characters long, including a special character.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_title = () => `Password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_phone = () => `Phone number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_phone_placeholder = () => `06 XX XX XX XX`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_profile_saved = () => `Informations saved`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_label = () => `Maximum time range`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_max_placeholder = () => `Maximum hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_min_placeholder = () => `Minimum hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_tip = () => `It is important that the working hours of a part-time employee remain reasonable, otherwise they risk being reclassified as full-time.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_info = () => `The mission will last`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_label = () => `Mission period`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_length_error = () => `The mission must last between 1 week and 18 months.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_reversed_error = () => `The start date cannot be later than the end date.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_tip = () => `It is important to always send the precise slots to employees <i>a minima</i> 7 days before they start, in order to respect the legal notice period.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_error_empty = () => `At least one day type must be selected.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_error_more = () => `At least one other day type must be selected.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_holidays = () => `Holidays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_label = () => `Type(s) of days included in the period`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_saturday = () => `Saturday`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_sunday = () => `Sunday`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_week = () => `Week`


/**
 * @param {{ downloadFormat: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_download_as = (params) => `Download as ${params.downloadFormat}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_amplitude_error = () => `The time range is incompatible with the hourly volume and/or the type of days selected.`


/**
 * @param {{ hours: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_error = (params) => `The hourly volume cannot exceed ${params.hours}h considering the days included.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_label = () => `Hourly volume per Sider per week`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_legal_tip_1 = () => `It is important to always respect the legal working and rest periods, as soon as your slots become clearer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_legal_tip_2 = () => `Check our FAQ for more information.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_select_current_week = () => `Today`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sheet_empty_user = () => `Selection ongoing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sheet_view_task = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_short_week = () => `W`


/**
 * @param {{ siderName: NonNullable<unknown>, workedHoursInWeek: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sider_worked_hours_week = (params) => `${params.siderName} is working ${params.workedHoursInWeek} hour${params.plural} this week`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_siders_error = () => `The number of Siders must be between 1 and 50.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_siders_label = () => `Number of Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_desc = () => `Make recruiting easier by creating a precise schedule based on these slots.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_subtitle = () => `You know all your niches`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_title = () => `Precise Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_desc = () => `Integrate a precise or flexible schedule into your mission, depending on your needs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_button_create = () => `Create`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_desc = () => `Stay agile by creating a flexible schedule from an hourly volume.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_subtitle = () => `You don't know all your niches`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_title = () => `Flexible Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_title = () => `Create your planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_volume_day_type_error = () => `The hourly volume relative to the types of days is incompatible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_volume_error = () => `The hourly volume must be between 7h and 48h.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pol = () => `Polish`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const por = () => `Portuguese`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_approve = () => `Approve candidate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_download = () => `Download CV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_refuse = () => `Refuse candidate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_upload = () => `Upload CV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_availabilities_from = () => `From`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_availabilities_until = () => `Until`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_cv_uploaded = () => `CV successfully uploaded`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_no_result_client = () => `We are preparing your mission and will get back to you very soon with candidates selected by us.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_no_result_logas = () => `Selection ongoing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_button = () => `Refuse this candidate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_description = () => `Please tell us why this candidate is not a good fit so we can refine our search. (eg: this candidate has already been presented to me)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_placeholder = () => `Explain to us...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_title = () => `This candidate is not right for you?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_textarea_hint = () => `Max 500 characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_textarea_placeholder = () => `Type a note...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_tip_no_visible = () => `Please note, no candidates are visible to your client! To give visibility to your client, you must click on the “eye” icon located to the left of the table.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_h1 = () => `Preselected candidates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_approved = () => `Approved`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_refused = () => `Refused`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_sent = () => `To validate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_applicant = () => `Applicant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_availabilities = () => `Availability`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_notes = () => `Notes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_visibility = () => `Visibility`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const que = () => `Quechua`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rename = () => `Rename`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rename_task = () => `Rename the mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rum = () => `Romanian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rus = () => `Russian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const save = () => `Save`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const save_quit = () => `Save and quit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search = () => `Search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const send = () => `Send`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_content = () => `You edited some information, what do you want to do?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_discard = () => `Discard changes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_save = () => `Save`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_code_error_max = () => `The analytical code must not exceed 20 characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_description = () => `This information will allow you to categorize your missions in your invoices. Depending on how your company is organized, a service could correspond to a department, a site, or any other segmentation of your activity.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_dialog_code_label = () => `Analytical code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_dialog_code_placeholder = () => `Ex: 4047`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_dialog_code_tooltip = () => `This code identifies the service and will appear on your invoice.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_dialog_description = () => `Depending on how your business is organized, a service could correspond to a department, a site, or any other segmentation of your activity.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_dialog_name_label = () => `Name of service`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_dialog_name_placeholder = () => `Ex: Preparation Service, HR Department, Center Store`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_dialog_title = () => `Add a service`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_error = () => `An error has occurred.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_error_special_chars = () => `The field must not contain special characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_name_error_duplicate = () => `This name is already used for another department`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_name_error_max = () => `The service name must not exceed 60 characters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_name_error_required = () => `Service name is required`


/**
 * @param {{ count: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_remaining_chars = (params) => `${params.count} remaining character${params.plural}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_success = () => `A new service has been added.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_departments_title = () => `Services`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape = () => `Code APE`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape_placeholder = () => `0000A`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape_tip = () => `the APE code is a 4 digits and 1 letter idenfier of the type of activity of your business. Also called NAF, it can be easily found in the \\"activity\\" section on https://www.societe.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name = () => `Legal entity`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_detail = () => `(if different)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_placeholder = () => `Legal entity`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_tip = () => `It is the legal entity which will appear on the contracts and will be invoiced`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_error_ape_format = () => `The APE code format is 4 digits followed by one letter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_error_siret_format = () => `Wrong format`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_name = () => `Company name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_name_placeholder = () => `Company name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret = () => `SIRET`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret_placeholder = () => `000 000 000 00000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret_tip = () => `The SIRET is a 14 digits identifier of your company, which can be easily found on https://www.societe.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_subtitle = () => `This information will be displayed on the contracts `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_title = () => `Legal information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_account = () => `My profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_company_info = () => `Company profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_legal_info = () => `Legal information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info = () => `Payment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_accounting = () => `Billing contact`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_billing = () => `Billing address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_method = () => `Payment method`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration = () => `Remuneration policy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration_compensations = () => `Allowances and compensations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration_hours = () => `Hour types`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_team = () => `Team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_email = () => `Email address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_email_placeholder = () => `name@company.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_firstname = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_firstname_placeholder = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_name = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_name_placeholder = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_phone_number = () => `Phone number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_phone_number_placeholder = () => `06 00 00 00 00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_subtitle = () => `This is the contact of the person who will receive the invoice`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_title = () => `Billing contact`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_api_error = () => `An error occured, please try again later or contact the customer service.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_address = () => `Address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_address_placeholder = () => `Address
`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_city = () => `City`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_city_placeholder = () => `City`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_country = () => `Country`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_country_placeholder = () => `Select a country`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_postal_code = () => `Postal code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_postal_code_placeholder = () => `00000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_subtitle = () => `This information will be issued to issue your invoices`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_title = () => `Billing address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_intro = () => `Your billing address is outside France, you need to provide a Intra-Community VAT number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_label = () => `Intra-Community VAT number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_placeholder = () => `XX 0000000000000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_tip = () => `This number allows a good VAT declaration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_card_check = () => `There's an issue with this bank card.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_card_format = () => `This card number is not valid.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_city_format = () => `Wrong format`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_country_format = () => `Wrong format`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_digits_only_format = () => `This field can only contain numbers.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_email_format = () => `Wrong format`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_exp_date_format = () => `Please enter a valid date.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_iban_format = () => `This IBAN is not valid.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_phone_number_format = () => `Wrong format`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_postal_code_format = () => `The zip code is a 5 digits number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_tos = () => `Please accept the term of services.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_tva_number_format = () => `Wrong format`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_cvv = () => `CVV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_cvv_placeholder = () => `213`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_desc = () => `The money will be taken from the account linked to this credit card.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_exp_date = () => `Expiration date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_exp_date_placeholder = () => `MM/YY`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_label = () => `Credit card`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_modify = () => `Edit the card`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_number = () => `Card number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_number_placeholder = () => `0000 - 0000 - 0000 - 0000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_saved = () => `Saved card`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_cs_desc = () => `You are responible of the payment of the invoice`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_cs_label = () => `Bank transfer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_label = () => `I would like to pay by...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_order_form = () => `I would like to get pre-invoices`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa = () => `IBAN`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_confirmation = () => `By clicking here, you accept <a href=\\"https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/sepa-mandate-en.pdf\\" target=\\"_blank\\">Stripe SEPA Direct Debit</a> terms of use.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_country = () => `Country`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_country_placeholder = () => `Select a country`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_currency = () => `Currency`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_currency_placeholder = () => `Select a currency`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_desc = () => `The money will be taken from your bank account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_direct_debit_mandate = () => `By providing your payment information, you authorise (A) Side and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_euros = () => `Euros`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_france = () => `France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_gb = () => `Great Britain`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_label = () => `Direct debit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_modify = () => `Edit the IBAN`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_placeholder = () => `XX00 0000 0000 0000 0000 0000 000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_pounds = () => `Pounds`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_saved = () => `Saved IBAN`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_stripe = () => `Payment processing and data storage are entirely handled by our payment partner Stripe - we do not store any data.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_subtitle = () => `We need this information to start the selection of your Siders. But no worries, you'll only be charged once the task is done.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_title = () => `Payment method`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_title = () => `Payment information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_email = () => `Email address`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_email_placeholder = () => `name@company.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_firstname = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_firstname_placeholder = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_lastname = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_lastname_placeholder = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_confirm = () => `Confirm new passoward`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_new = () => `New passwoard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_placeholder = () => `•••••••••••`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_phonenumber = () => `Phone number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_phonenumber_placeholder = () => `06 00 00 00 00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_subtitle = () => `These are your credentials that allow you to connect to the platform`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_title = () => `My account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_profile_title = () => `Company profile`


/**
 * @param {{ months: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_after_months_seniority = (params) => `<b>After ${params.months} months</b> of seniority.`


/**
 * @param {{ months: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_before_months_seniority = (params) => `<b>up to ${params.months} months</b> of seniority.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_edit_tooltip = () => `Edit hour type parameters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_extra_content = () => `Rate that applies when a Sider works:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_extra_title = () => `Overtime`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_help = () => `You don't find your hour type in the list above. Feel free to contact us on billing@sidetemp.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_content = () => `Rate that applies when a Sider works on bank holidays.`


/**
 * @param {{ solidarity: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_solidarity = (params) => `<b>Solidarity day:</b>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_solidarity_undefined = () => `unspecified`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_title = () => `Bank holidays worked hours`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_hour_threshold = (params) => `<b>More than ${params.count}h</b> in a week.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_in_zti = () => `When the task location is inside a <b>touristic area</b>.`


/**
 * @param {{ startTime: NonNullable<unknown>, endTime: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_night_content = (params) => `Rate that applies when a Sider works <b>between ${params.startTime} and ${params.endTime}</b>.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_night_title = () => `Night hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_normal_content = () => `This is the standard hour type rate that applies when no other hour type rate does. The Sider will be paid on the basis of the reference salary without pay increase.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_normal_title = () => `Standard hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_not_zti = () => `When the task location is outside a <b>touristic area</b>.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_see_holidays = () => `See all bank holidays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_subtitle = () => `You can define here the remuneration policy that applies in your company. If it differs from the default configuration, you can modify the parameters of each hour type.
`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_sunday_content = () => `Rate that applies when a Sider works on Sundays.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_sunday_title = () => `Sunday hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_table_type = () => `Hour type and conditions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_table_value = () => `Rate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_title = () => `Hour types`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_types_custom_disclaimer = () => `Your configuration is very specific and had to be inserted manually (this is the reason why you don't see it here). It has been taken into account though. Should you have any question, feel free to contact us`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_button = () => `Confirm`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_content = () => `You need to check and confirm your hour types configuration before being able to post a task.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_label = () => `Hour type configuration are correct`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_title = () => `Confirm your hour types configuration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_label = () => `Which type of benefit do you want to add? `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_lunch = () => `Lunch allowance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_other = () => `Other`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_transport = () => `Transportation compensation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_placeholder = () => `Select a type of benefit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_title = () => `Add a new benefit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_hours_rate_error_max = () => `the rate cannot exceed +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_hours_rate_error_min = () => `the rate must be at least 0%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_rate = () => `Pay increase (vs standard hours)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_solidarity = () => `Select your solidarity day (holiday paid at normal hours rate)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_solidarity_placeholder = () => `Solidarity day`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_subtitle = () => `The rate you will define will apply for every hour worked on a bank Holiday`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_title = () => `Worked bank holidays hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_custom_disclaimer = () => `Your configuration is very specific and had to be inserted manually (this is the reason why you don't see it here). It has been taken into account though. Should you have any question, feel free to contact us`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_edit_tooltip = () => `Edit `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_empty = () => `No benefit has been defined yet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_increase_error_max = () => `the rate cannot exceed +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_increase_error_min = () => `the rate must be at least 0%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_min = () => `You need to define at least one threshold`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_threshold_format = () => `the threshold should be between 35 and 48 hours
`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_threshold_must_be_higher = () => `This threshold  must be higher than the previous one`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_extra_threshold = (params) => `Extra hours threshold n°${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_rate = () => `Pay rate (vs standard hours rate)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_subtitle = () => `Extra hours rate will apply when a Sider exceeds the weekly amount of working hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_threshold = () => `Overtime threshold`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_threshold_unit = () => `worked hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_title = () => `Overtime`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_help = () => `You don't manage to configure one of your benefits with our tool? Contact us on billing@sidetemp.com and we will configure it for you!`


/**
 * @param {{ amount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_equivalent_amount = (params) => `i.e ${params.amount}€`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indeminity_option = () => `Lunch allowance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indemnity_label = () => `Amount of the allowance (per day)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indemnity_type = () => `Lunch allowance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_max_error = () => `This value should be lesser than 30`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_min_error = () => `This value should be greater than 0`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_subtitle = () => `Rules for lunch allowance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_ticket_type = () => `Meal voucher (given by the company)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_title = () => `Lunch`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_option = () => `Meal voucher`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_rate = () => `Employer's contribution`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_rate_format_error = () => `Employer's contribution should be a percentage (between 0 and 100)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_tip = () => `Meal vouchers have to be given to the employee by the user undertaking. Side will deduce the employee's part from the temp worker's salary and from your invoice. If you can give them yourself, please select the lunch allowance option.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_value_label = () => `Value of the meal voucher (per day)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_type_error = () => `Please select a type of lunch allowance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_cancel = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_delete = () => `Delete this benefit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_save = () => `Save`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end = () => `Night hours end time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end_before_start_error = () => `Night hours end time cannot be before night hours start time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end_error = () => `Night hours cannot end after 9:00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_increase_error = () => `the rate cannot exceed +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_rate = () => `Pay rate (vs standard hours rate)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_start = () => `Night hours start time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_start_error = () => `Night hours cannot start before 18:00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_subtitle = () => `The rate you will define will apply for every hour worked during the night (as you will define it)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_title = () => `Night hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_option_button = () => `Add`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_amount = () => `Amount`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_category = () => `Benefit related to`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_category_select_placeholder = () => `Select a category`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_christmas_bonus_indemnity_title = () => `Christmas bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_indemnity_select_label = () => `an event`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_indemnity_title = () => `Event`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_other_bonus_indemnity_title = () => `Other`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_year_end_bonus_indemnity_title = () => `End of year bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency = () => `Frequency`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_daily = () => `Daily`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_monthly = () => `Monthly`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_once = () => `Once`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_select_placeholder = () => `Select a frequency`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_weekly = () => `Weekly`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_holiday_bonus_indemnity_title = () => `Holiday bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_indemnity_select_label = () => `Collective agreement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_indemnity_title = () => `Collective agreement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_other_bonus_indemnity_title = () => `Other`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_thirteenth_month_bonus_indemnity_title = () => `13th month bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_indemnity_select_label = () => `something else`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_indemnity_title = () => `Other`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_other_bonus_indemnity_title = () => `Special bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day = () => `The benefit is conditional on the number of worked hours per day`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day_label = () => `Threshold (number of worked hours)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit = () => `worked hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_jobtype = () => `The benefit depends on the type of task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_jobtype_label = () => `Select a type of task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority = () => `The benefit is conditional on the seniority`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority_threshold = () => `Threshold`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit = () => `days worked for the company (seniority)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_switch = () => `The benefit is conditional on other parameters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_zti = () => `The benefit applies if the task is located in a touristic area`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_hours_per_day_error = () => `This number should be between 1 and 12`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_job_types_error = () => `You need to select at least one type of task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0 = () => `this number should be greater than 0
`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_diligence_bonus_indemnity_title = () => `Presence bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_indemnity_select_label = () => `Performance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_indemnity_title = () => `Performance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_other_bonus_indemnity_title = () => `Other`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_productivity_bonus_indemnity_title = () => `Achievement/performance bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_target_based_bonus_indemnity_title = () => `Objectives/performance bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_subtitle = () => `Please define the parameters of your benefit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_title = () => `Add a benefit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type = () => `Name of the benefit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type_error_already_exists = () => `You cannot add this benefit because one of the same kind already exists.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type_select_placeholder = () => `Select a type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_cold_bonus_indemnity_title = () => `Cold conditions bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_hardship_bonus_indemnity_title = () => `Hardship allowance
`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_indemnity_select_label = () => `Job type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_indemnity_title = () => `Job type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_other_bonus_indemnity_title = () => `Other`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_risk_bonus_indemnity_title = () => `Risk bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_unhealthy_bonus_indemnity_title = () => `Dirty conditions bonus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_per_day = () => `/day`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_per_month = () => `/month`


/**
 * @param {{ duration: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_condition_after = (params) => `After`


/**
 * @param {{ duration: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_condition_before = (params) => `Before`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_rate = () => `Pay rate (vs standard hours rate)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_switch = () => `the rate depends on the seniority`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_threshold = () => `Threshold`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_unit = () => `days worked for the company (seniority)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_subtitle = () => `If your remuneration policy includes benefits, allowances, compensations or bonuses, please define them here.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate = () => `Pay increase (vs standard hours)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate_error_max = () => `the rate cannot exceed +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate_error_min = () => `the rate must be at least 0%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_subtitle = () => `The rate you will define will apply for every hour worked on Sundays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_title = () => `Sunday hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_zti = () => `A special pay rate applies for tourisitic areas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_table_type = () => `Benefit and parameters`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_table_value = () => `Value`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_addition = () => `Add a threshold`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_deletion_tooltip = () => `Remove this threshold`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_number = () => `Threshold #`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_title = () => `Benefits`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_switch = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_tip = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_type = () => `Personal transportation compensation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate = () => `Contribution to a public transportation subscription`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred = () => `Your contribution cannot exceed 100%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty = () => `Your contribution should be greater than 50%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_type = () => `Public transportation compensation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_subtitle = () => `Define here your contribution to the transportation costs of your temp workers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_title = () => `Transportation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_button = () => `Confirm`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_content = () => `You need to check and confirm your benefits configuration before being able to post a task.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_label = () => `Benefits configuration is correct`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_title = () => `Confirm your benefits configuration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_zti_condition = () => `if task location in in a touristic area`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_help = () => `Non worked bank holidays are due to temp workers on a basis of 7h maximum paid at normal hours rate.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_help_link = () => `See details here.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_title = () => `Non worked bank holidays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_title = () => `Remuneration policy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_submit_success = () => `Your changes were saved!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user = () => `Invite a user`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_email = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_email_placeholder = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_firstname = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_firstname_placeholder = () => `First name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_lastname = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_lastname_placeholder = () => `Last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_role = () => `Role`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_role_placeholder = () => `Role`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_success = () => `User invited !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user = () => `Remove user from your team`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user_dialog_content = (params) => `Do you really wish to remove ${params.name} from your team ? They won't be able to to manage your current missions nor validate your temp workers timesheets.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user_success = () => `User removed from your team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_role = () => `Change the user's role`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user = () => `Change the user's role`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_role = () => `Role`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_role_placeholder = () => `Role`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_success = () => `User updated !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_description = () => `Description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_description_placeholder = () => `Give a short description of your business`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media = () => `Media`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_picture = () => `Use a picture`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_upload = () => `Upload a file`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_valid_formats = () => `Valid file formats: .jpg, .png`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_video = () => `Use a youtube video`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_name = () => `Company name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_name_placeholder = () => `My company`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_saved = () => `Your changes has been saved`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo = () => `Upload a picture`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo_label = () => `Logo of your company`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo_search_tooltip = () => `Search with google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website = () => `Website`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website_error = () => `Your website must be a valid secured URL (https)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website_placeholder = () => `https://`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url = () => `Your video's youtube URL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url_error = () => `The URL must be a valid youtube link`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url_placeholder = () => `https://youtube.com...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_reinvited_success = () => `Link re-sent !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_resend_activation_link = () => `Resend activation link`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role = () => `Role`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_admin = () => `Admin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_admin_description = () => `All rights`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_collaborator = () => `Collaborator`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_collaborator_description = () => `Hours validation and Siders evaluation on all tasks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_description_title = () => `Role description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_missionManager = () => `User`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_missionManager_description = () => `Configuration of account settings and administration of tasks he manages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_title = () => `My team`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_user = () => `User`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_title = () => `Settings`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_content = () => `Your changes will be lost if you leave the page without saving.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_discard = () => `Leave`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_save = () => `Save`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_title = () => `You didn't save your changes!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shift_warning_holiday_FAQ_link = () => `https://support.side.co/b2b/fr/articles/9113087-jours-feries-travailles-et-non-travailles-comment-ca-marche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_add_shift = () => `Create a shift`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_105_label = () => `1h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_120_label = () => `2h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_135_label = () => `2h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_150_label = () => `2h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_165_label = () => `2h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_180_label = () => `3h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_195_label = () => `3h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_20_label = () => `20 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_210_label = () => `3h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_225_label = () => `3h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_240_label = () => `4h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_255_label = () => `4h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_270_label = () => `4h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_285_label = () => `4h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_300_label = () => `5h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_30_label = () => `30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_45_label = () => `45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_60_label = () => `60 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_75_label = () => `1h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_90_label = () => `1h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_label = () => `Break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_none_label = () => `None`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_placeholder = () => `Duration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_date_label = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_subtitle = () => `Select the days for which you want to copy this shift`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_success = () => `The slot(s) have been successfully duplicated.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_title = () => `Copy this shift`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_endTime_label = () => `End`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_siders_count = () => `Nb of Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_startTime_label = () => `Start`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_break = () => `Break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_date = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_total_hours = () => `Hours total`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_total_number = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_time_from = () => `from`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_time_to = () => `to`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_7_consecutive = () => `You've added shifts for 7 days in a row. We won't be able to select the same person on all of these shifts - only 6 days in a row can be worked.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_breaktime_subtitle = () => `The law provides for a break of 11 hours between 2 working days (excluding specific agreement). If necessary, we can position two different profiles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_breaktime_title = () => `Mandatory time off`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_new_year_day_subtitle = () => `New year's a bank holiday`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_new_year_day_title = () => `New year's day`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_public_holiday_period = () => `This task includes a bank holiday. Bank holidays are paid even though they haven't been worked in case they are part of the usual working days.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_public_holiday_selected = () => `You've added a shift on a bank holiday. Pay is increased according to your remuneration policy. Check that this is a work day in your company.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_card_ongoing = () => `Ongoing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_copy_email = () => `Copy the Sider's email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_email_copied = () => `The email was successfully copied`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_educations = () => `Education`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences = () => `Experiences`


/**
 * @param {{ plural: NonNullable<unknown>, industryName: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences_industry = (params) => `Past experience${params.plural} in ${params.industryName} (${params.count})`


/**
 * @param {{ plural: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences_other = (params) => `Past experience${params.plural} (${params.count})`


/**
 * @param {{ numberOfHours: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_hours = (params) => `${params.numberOfHours} hour${params.plural}`


/**
 * @param {{ numberOfMonths: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_months = (params) => `${params.numberOfMonths} month${params.plural}`


/**
 * @param {{ plural: NonNullable<unknown>, numberOfTasks: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks = (params) => `task${params.plural}`


/**
 * @param {{ plural: NonNullable<unknown>, companyName: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_company = (params) => `Past task${params.plural} at ${params.companyName} (${params.count})`


/**
 * @param {{ plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_ongoing = (params) => `Ongoing task${params.plural}`


/**
 * @param {{ plural: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_other = (params) => `Past task${params.plural} (${params.count})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_side = () => `Side tasks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_weeks = () => `< 1 Month`


/**
 * @param {{ numberOfYears: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_years = (params) => `${params.numberOfYears} year${params.plural}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_create_account = () => `Create a company account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_email_placeholder = () => `you@company.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_forgot_password = () => `I forgot my password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_form_title = () => `Sign in`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_log_in = () => `Log in`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_logo_company = () => `Company`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_no_account_yet = () => `Don't have a company account yet?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_or = () => `or`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_password_placeholder = () => `Password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_title = () => `Sign in to Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_with_google = () => `Log in with google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_work_on_side = () => `I am a talent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_accept = () => `I accept`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_label = () => `Company name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_message_label = () => `Do you have a message for our team?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_label = () => `Your need`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_learn_about_side = () => `I want to know more about Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_less_than_7_days = () => `I am looking to recruit quickly (< 7 days)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_more_than_7_days = () => `I am looking to recruit soon (> 7 days)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_placeholder = () => `I want to know more about Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_placeholder = () => `Your company's name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_size_label = () => `Number of employees`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_size_placeholder = () => `Number of employees`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_create_account = () => `Create a company account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_email_label = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_email_placeholder = () => `you@company.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_subtitle_second_step = () => `Tell us a bit more about you and your company before you post a task
`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_title = () => `Create your company account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_title_second_step = () => `One last thing...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_fullname_label = () => `First and last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_fullname_placeholder = () => `Your first and last name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_fashion = () => `Fashion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_gsa = () => `Supermarkets`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_gss = () => `Specialized supermarkets`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_label = () => `industry`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_logistics = () => `Logistics/Transports`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_other = () => `Others`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_placeholder = () => `Your industry`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_services = () => `Services`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_tech = () => `Technology`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_log_in = () => `Sign in`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_logo_company = () => `Company`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_no_account_yet = () => `Already have a company account?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_or = () => `or`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_password_label = () => `Password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_password_placeholder = () => `Choose a password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_phone_label = () => `Phone number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_phone_placeholder = () => `Your phone number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_size_to = () => `to`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_temp_worker = () => `Sign up as a temp worker`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_terms_and_conditions = () => `the terms and conditions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_title = () => `Create your company account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_with_google = () => `Create a company account with Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_work_on_side = () => `Work on Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_error_invalid_email = () => `Use a valid email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slo = () => `Slovak`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slv = () => `Slovenian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smo = () => `Samoan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sot = () => `Sotho`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spa = () => `Spanish`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sr_alert_disputed = () => `You disputed this time entry, it’s currently being reviewed by the Sider.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sr_alert_forgotten = (params) => `${params.name} changed the hours manually, make sure to double-check this entry.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const srp = () => `Serbian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit = () => `Submit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swa = () => `Swahili`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swe = () => `Swedish`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_end_date = () => `End date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_start_date = () => `Start date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_task = () => `Task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tam = () => `Tamil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_contract_warning = () => `Siders have already been recruited for this mission.
Most of the information can no longer be modified.
Please contact customer support for any questions.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_desc = () => `If you have not found the equipment corresponding to your need in the list, you can add a new one below.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_name_placeholder = () => `Visor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_other_desc = () => `Select the equipment that the Siders will need to bring.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_providedBy_placeholder = () => `Indicate who should provide this equipment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_title = () => `Adding a custom element`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_dress_code_desc = () => `Please provide any information regarding the dress code.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_experiences_placeholder = () => `Ex: We are preferably looking for profiles with knowledge of the industry of...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_languages_no_result = () => `Change your search to show more languages.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_desc = () => `You can edit the job location information that will help Siders navigate to the location.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_desc_placeholder = () => `Specify information that will be useful for Siders to find their way on the first day.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_workplace_title = () => `Address - Workplace`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_motorized_desc = () => `Please indicate if the Sider should be motorized.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_remote_desc = () => `Please indicate whether the Sider has the possibility of teleworking.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_requested_siders_warning = () => `The address cannot be changed because Siders are required on this mission. To be able to change the address, remove the requested Siders first.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_invalid_decimal_error = () => `Salary must have at most 2 decimal places`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_invalid_number_error = () => `Invalid number`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_too_low_error = () => `Unable to decrease the salary. Please enter an amount equal to or greater than the initial amount`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_sidenote_desc = () => `Please fill in all information intended for Side.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_success_toast = () => `The field has been successfully updated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_suffix_desc = () => `You can rename your mission to differentiate it from others, this new name will be used as a reference on your invoices.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_suffix_title = () => `Mission Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_tools_custom_label = () => `Tool's name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workconditions_desc = () => `Please specify additional risk-related information.`


/**
 * @param {{ numberOfRemainingSlots: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workers_wao_hint = (params) => `Add ${params.numberOfRemainingSlots} Sider${params.plural} to enable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workers_wao_label = () => `Exlusive to selected Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_add = () => `Add shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_cost_estimation = () => `Total estimate excluding VAT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_dialog_message = () => `This action would impact the mission slots.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_dialog_title = () => `Are you sure ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_footer_delete_button = () => `Delete shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_label = () => `Break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_placeholder = () => `None`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_tip_label = () => `(unpaid)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_all_shifts = () => `All the shifts of the repetition`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_label_delete = () => `Which shift(s) would you like to delete?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_label_edit = () => `Which shift(s) would you like to edit?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_this_shift_and_following = () => `This shift and the following shifts of the repetition`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_this_shift_only = () => `This shift only`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_creation_title = () => `Add one or more shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_date_label = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_days_error = () => `At least one day must be selected.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_deletion_title = () => `Delete one or more shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_duration_error = () => `The actual working time must be between 2 and 10 hours.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_edition_title = () => `Edit one or more shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_each = () => `Each`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_every = () => `Every`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_until = () => `Until`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_option_monthly = () => `Month`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_option_weekly = () => `Week`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_1_of_the_month = () => `First`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_2_of_the_month = () => `Second`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_3_of_the_month = () => `Third`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_4_of_the_month = () => `Fourth`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_last_of_the_month = () => `Last`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_of_the_month = () => `of the month`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_schedule_end_label = () => `End time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_schedule_start_label = () => `Start time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_siders_label = () => `Number of Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_slots_error = () => `The number of slots must be between 1 and 50.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_switch_holidays = () => `Includes holidays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_switch_repeat = () => `Repeating itself`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_until_error = () => `The end date must be later than the start date.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_no_shifts_description = () => `Add your shifts, individually or in series.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_no_shifts_title = () => `No shift at the moment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_creation = () => `The slot(s) have been created successfully.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_deletion = () => `The slot(s) have been deleted.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_edition = () => `The slot(s) have been modified.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_break = () => `Break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_hours = () => `Schedules`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_shifts = () => `Shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_title = () => `Shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_closed = () => `Over`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_done = () => `Over`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_draft = () => `Draft`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_new = () => `New`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_ongoing = () => `Ongoing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_upcoming = () => `Upcoming`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_add_break = () => `Add a break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_break = () => `Breaks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_cancel = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work = () => `The Sider didn't work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work_tip_after_confirmed = () => `You declared that this Sider didn't work on this shift. Side's team has been informed.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work_tip_confirmation = () => `You are about to contest all hours reported by the Sider on this shift. This action cannot be undone.`


/**
 * @param {{ managerName: NonNullable<unknown>, date: NonNullable<unknown>, hour: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_review_info = (params) => `Reviewed and confirmed by ${params.managerName} on ${params.date} at ${params.hour}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_send = () => `Send`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift = () => `Clock in & out`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift_end = () => `End`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift_start = () => `Start`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_subtitle = () => `Edit the incorrect informations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_title = () => `Time entries`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_disputed_content = () => `You can’t dispute twice the same time entry.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_edit_content = () => `Simply edit the hours if you don’t agree with them.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_howto_content = (params) => `Your edits will be sent to ${params.name} to accept.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_review_content = () => `You disputed this time entry, it’s currently being reviewed.`


/**
 * @param {{ s: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const textarea_counter_label = (params) => `character${params.s} left`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tha = () => `Thai`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_dropdown_default = () => `All teammates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_all = () => `All`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_all_invoices = () => `All invoices`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_completed = () => `Completed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_draft = () => `Drafts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_ongoing = () => `Ongoing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_paid_ts = () => `Paid`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_siders = () => `All Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_to_be_paid = () => `Open for review`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_to_review = () => `Open for review`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_upcoming = () => `Upcoming`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_validated = () => `Validated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_attendance_disputed_success = () => `Attendance was successfully modified`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_confirm_attendances = () => `Confirm attendances`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_confirm_attendances_mobile = () => `Confirm`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_error_after = () => `Breaks ends after the shift, please edit your entry`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_error_before = () => `Breaks starts before the shift, please edit your entry.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_title = () => `Entered breaks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_breaks_add = () => `Add a break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_day_timerange_error = () => `You cannot enter a time range longer than 24 hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_description = () => `Modify any information that is not correct.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_end_before_start_date_error = () => `The start date must be before the end date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_end_before_start_time_error = () => `The start time must be before the end time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_enddate_from_initial_error = () => `End date cannot be more than 1 day from the initial date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_enddate_label = () => `End date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_enddate_required_error = () => `The end date is required`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_endtime_label = () => `End time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_endtime_required_error = () => `The end time is required`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_24_hours_error = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_label = () => `Start & end`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_title = () => `Entered hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_startdate_from_initial_error = () => `Start date cannot be more than 1 day from the initial date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_startdate_label = () => `Start date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_startdate_required_error = () => `The start date is required`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_starttime_label = () => `Start time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_starttime_required_error = () => `The start time is required`


/**
 * @param {{ amplitude: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_timerange_tip = (params) => `The time range is ${params.amplitude}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv = () => `Export Timesheets as csv`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv_error_body = () => `Please contact our online support for more information.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv_error_title = () => `The CSV file couldn't be created.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_button = () => `Import`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_button = () => `Upload a file`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_hint = () => `Download a csv, maximum 1MB.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_text = () => `or drang and drop here`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_all_error_title = () => `Your entire file contains errors`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_button = () => `Import`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_cancel = () => `Cancel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_column_error = () => `Missing columns:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_db_att_duplicate_error = () => `The following lines have already been imported:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_description = () => `The file must be in csv format and contain the following columns: siderId, startDate, endDate, breakDuration.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_empty_error = () => `The following lines contain empty cells:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_end_before_start_error = () => `The following lines contain an end date earlier than the start date:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_error_all_lines = () => `You cannot continue with the import because all lines in your file are in error.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_file_att_duplicate_error = () => `The following lines are duplicates of another line in the file, please delete them before reimporting:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_format_error = () => `The following lines contain invalid formats:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_import = () => `Import`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_import_success = (params) => `${params.count} attendances have been successfully imported`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_partial_error_title = () => `This file contains errors. Continue importing?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_rest_of_lines = () => `The rest of the lines in the file are ready to be imported.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_shifts_not_found_error = () => `The following lines do not correspond to any configured shift:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_sider_id_error = () => `The following lines contain invalid siderIds:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_template_link = () => `Access the file template`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_title = () => `Import Excel file (.csv only)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_with_error_main_button = () => `Continue importing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_with_error_secondary_button = () => `Import another file`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_all = () => `All dates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_day = () => `Day`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_month = () => `Month`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_item_empty = () => `No results found with this query`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_manager = () => `Search by task manager name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_sider = () => `Search by Sider name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type = () => `Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_different_description = () => `Only Siders who entered mismatching hours will be displayed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_different_label = () => `Hours not matching scheduled shift hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_similar_description = () => `Siders who entered matching hours will be displayed`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_similar_label = () => `Hours matching scheduled shift hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_week = () => `Week`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_absence_justification_tooltip = () => `Please select working slots only`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_paragraph = () => `Select one or several Siders to validate their hours or state that they didn't work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_selected_sider = () => `1 Shift selected`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_selected_siders = (params) => `${params.count} shifts selected`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_sider_didnt_work = () => `Sider did not work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_siders_didnt_work = () => `Siders did not work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_tovalidate_attendance = () => `Validate 1 attendance`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_tovalidate_attendances = (params) => `Validate ${params.count} attendances`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_validate_hours = () => `Validate hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_faq_label = () => `Why do I need to confirm attendances?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_faq_link = () => `https://support.side.co/b2b/fr/articles/9113131-comment-verifier-les-heures-declarees-par-les-siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_agreement = () => `I agree to confirm selected attendances.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_attendances_count = () => `Attendances to confirm`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_failure = (params) => `${params.count} attendance could not be validated. We invite you to try to confirm it again in a few minutes.`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_failure_plural = (params) => `${params.count} attendances could not be validated. We invite you to try to confirm them again in a few minutes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_submit_button = () => `Confirm and send`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_submit_button_mobile = () => `Confirm`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_success = (params) => `${params.count} attendance was validated successfully.`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_success_plural = (params) => `${params.count} attendances were validated successfully.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_tip_content = () => `Once a timesheet is confirmed, you won’t be able to edit its hours anymore and you have to pay them`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_tip_title = () => `Confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_title = () => `Timesheets confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_worked_hours = () => `Total worked hours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_content = () => `You are about to contest each hours declared on the selected slots`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_tip = () => `Your statement will be sent to the Sider for approval. This action cannot be undone.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_title = () => `Confirm Sider(s) didn't work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_submit = () => `Confirm and submit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_content = () => `Please confirm that you want to validate the selected shift(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_tip = () => `You will not be able to edit the slots validated. They will show up on your next bill.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_title = () => `Confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_add_break = () => `Add a break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_imported_hours = () => `Hours imported by the company`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_scheduled_hours = () => `Scheduled hours`


/**
 * @param {{ plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_breaks = (params) => `Break${params.plural}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_didnt_work = () => `Sider didn't work`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_didnt_work_dayOff = () => `Day off`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_didnt_work_other = () => `Absent Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_didnt_work_placeholder = () => `Select a reason`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_hours = () => `Hours entered by the Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_worked = () => `Sider worked`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_submit = () => `Send my updates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_select = () => `Select`


/**
 * @param {{ count: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_selected_attendances_count = (params) => `${params.count} attendance${params.plural} <span>selected</span>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break = () => `break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break_hour = () => `1 hour break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break_mins = () => `mins break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_deadline = () => `Deadline:`


/**
 * @param {{ hours: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_expected_hours = (params) => `${params.hours} hours approx.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_hide_entries = () => `Hide already reviewed time entries`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_in = () => `Shifts in`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_mark_seen = () => `Mark as seen`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_no_time_day = (params) => `No time entries for ${params.date}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_no_time_shift = () => `No new time entries`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_show_entries = () => `View already reviewed time entries`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_unplanned_day = () => `Unplanned shifts on`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_unplanned_in = () => `Unplanned shifts in`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_add_comment = () => `Add a comment on this Sider.`


/**
 * @param {{ siderName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_company_description = (params) => `You just modified the hours declared on this shift. This version is pending until validated by ${params.siderName}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_company_title = () => `Validation pending`


/**
 * @param {{ siderName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_insider_description = (params) => `You just modified the hours declared on this shift. This version is pending until validated by ${params.siderName}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_insider_title = () => `Validation pending`


/**
 * @param {{ siderName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_sider_description = (params) => `${params.siderName} refused your rectification for this shift. Please contact support to solve the conflict.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_sider_title = () => `Contested version`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_break_description = (params) => `There is a ${params.delta} difference between the scheduled breaks and what was entered by the Sider.`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_break_label = (params) => `${params.delta} difference`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_hours_description = (params) => `There is a ${params.delta} difference with the scheduled hours.`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_hours_label = (params) => `${params.delta} difference`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_edit_comment = () => `Edit comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_duration = () => `Duration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_duration_tag = () => `Total duration entered by Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_mission = () => `Task`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_sider_breaks = () => `Break`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_sider_hours = () => `Hours entered`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_start_end = () => `Start & End`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_no_attendances_description = () => `Please modify your search query or come back later.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_no_attendances_title = () => `No shift to display`


/**
 * @param {{ validationDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_content = (params) => `Shift validated at ${params.validationDate}.`


/**
 * @param {{ validatedBy: NonNullable<unknown>, validationDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_content_validated_by = (params) => `Shift validated by ${params.validatedBy} at ${params.validationDate}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_title = () => `Validated shift`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_disputed = () => `Contested`


/**
 * @param {{ date: NonNullable<unknown>, time: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_event_log = (params) => `File imported on ${params.date} at ${params.time}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_external_file_origin = () => `External file`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_validated = () => `Validated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_waiting = () => `To validate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_archives_content = () => `Here is the recap of all the payments sent to Siders. If you don't agree on time entries, you can edit them. As a reminder, payments are all triggered on Tuesdays.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_archives_title = () => `It's been paid!`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_date_review = (params) => `You have until ${params.date} at 10:00pm to review the timesheets below before payments are triggered!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_hours_validation_content = () => `You have to check and validate all the hours Siders have declared so they can be paid.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_hours_validation_title = () => `Reviewing timesheets`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_review_content = () => `Keep you timesheets organised by using the tick<i class=\\"icon-checkmark\\"></i>when you're done with an entry.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_review_title = () => `Mark as seen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_unplanned_content = () => `This section gathers entries from Siders who weren't selected by Side that day or shifts that weren't entered on the platform.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_unplanned_title = () => `Unplanned shifts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_to_validate_blank_state_content = () => `You have no pending timesheet to review`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_to_validate_blank_state_title = () => `All good here!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_unselect_all_attendances = () => `Unselect all`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_update = () => `Update`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_validated_blank_state_content = () => `You don’t have any confirmed timesheets.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_validated_blank_state_title = () => `Nothing to see here!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_content = () => `Some Siders didn’t log their hours on time. Make sure you check all time entries tagged with `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tab_tooltip = () => `Some hours were logged afterwards, make sure to double-check them.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tag = () => `Logged afterwards`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tag_tooltip = () => `These hours weren’t logged the same day.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_title = () => `Some hours were logged afterwards`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tur = () => `Turkish`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ukr = () => `Ukrainian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const undo = () => `Undo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const until = () => `until`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const until_date = () => `until`


/**
 * @param {{ numberOfNotDisplayedUpcoming: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_button_view_more = (params) => `View all upcoming missions (${params.numberOfNotDisplayedUpcoming})`


/**
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_contact_plural = (params) => `Contact the ${params.siderNumber} Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_contact_singular = () => `Contact the Sider`


/**
 * @param {{ hiringEndDateDay: NonNullable<unknown>, hiringEndDateHour: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_selection_ongoing = (params) => `End scheduled for ${params.hiringEndDateDay} at ${params.hiringEndDateHour}`


/**
 * @param {{ selectedSiderNumber: NonNullable<unknown>, totalSidersNumberExpected: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_almost_done = (params) => `${params.selectedSiderNumber}/${params.totalSidersNumberExpected} selected Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_done = () => `Selection done`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_ongoing = () => `Selection ongoing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_task_cell_managed_by = () => `Managed by `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_title = () => `Upcoming tasks`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_manager = () => `Change mission manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const urd = () => `Urdu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const use = () => `Use`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uzb = () => `Uzbek`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_geolocation_multiple_places = () => ` different locations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_geolocation_online = () => `Online`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_time_deadline = () => `Deadline`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vie = () => `Vietnamese`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_invoices = () => `View invoices & payments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_siders = () => `View Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_task = () => `View mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warning_is_google_login = () => `You are logged in via Google, you cannot change the password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warning_is_log_as = () => `You are logged in as Log as, you cannot change the password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const week = () => `Week`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yes = () => `Yes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zgh = () => `Tamazight (berber)`
