import { useEffect, useState } from "react";
import { TextArea } from "saphir";

import { i18n } from "@lib/i18n";

import { useTimer } from "../../../routes/JobDescriptions/hooks/useTimer";

const AddressDescription = ({ description, submitAddress }) => {
    // derive state for when in edit mode
    const [addressDescription, setAddressDescription] = useState(description);
    const [state, setState] = useState({ touched: false });

    // re-render when description ref changes
    useEffect(() => {
        if (description) {
            setAddressDescription(description);
        }
    }, [description]);

    // Since there's no submit on this component, we don't want to update the store on each keystroke
    // BUT we still want the state to update in real time (not debounced)
    // here we make use of a timer to achieve this
    useTimer(
        {
            timing: 300,
            callback: () => {
                if (state.touched) {
                    submitAddress({ description: addressDescription });
                }
            },
        },
        [addressDescription],
    );

    return (
        <TextArea
            label={i18n.location_form_workplace_label2()}
            name='description'
            placeholder={i18n.location_form_workplace_placeholder2()}
            className='h-32'
            onChange={({ currentTarget }) => {
                setAddressDescription(currentTarget.value);
                setState({ touched: true });
            }}
            value={addressDescription}
        />
    );
};

export default AddressDescription;
