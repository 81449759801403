import { useContext, useEffect, useMemo } from "react";
import {
    add,
    differenceInMinutes,
    endOfDay,
    formatDuration,
    getYear,
    intervalToDuration,
    set,
    startOfDay,
    sub,
} from "date-fns";
import { useSetAtom } from "jotai";
import { CircleAlert, FileText, Info } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import { ComboBox, DatePicker, Label, Select, TextField, Tip } from "sui";
import { ListItem } from "sui/dist/components/ComboBox/types";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { getLocale } from "@lib/utils/getLocale";
import { parseStringWithFontStyle } from "@lib/utils/parseStringWithFontStyle";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

import { PostingTrackingContext } from "../context/PostingTrackingContext";
import { taskFormRoute } from "../route";
import { disabledSubmitAtom, orderDataToSendAtom } from "../TaskFormFooter";

import { TimeField } from "./TimeField";

const customFormatDuration = (start, end) => {
    const locale = getLocale();
    const { years, months, days } = intervalToDuration({
        start,
        end: add(end, { seconds: 1 }),
    });
    return formatDuration(
        {
            years,
            months,
            weeks: days ? Math.floor(days / 7) : undefined,
            days: days ? days % 7 : undefined,
        },
        { locale },
    );
};

export function FlexiblePlanningStep() {
    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useSuspenseQuery(queries.task.detail(taskId));
    const { data: planning } = useSuspenseQuery({
        ...queries.task.planning(taskId),
        queryKey: [...queries.task.planning(taskId).queryKey, task.flexiblePlanningId],
        queryFn: () => (task.flexiblePlanningId ? queries.task.planning(taskId).queryFn() : null),
    });
    const setOrderDataToSend = useSetAtom(orderDataToSendAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const { data: holidaysN } = useQuery(queries.holiday.list(getYear(new Date())));
    const { data: holidaysN1 } = useQuery(queries.holiday.list(getYear(new Date()) + 1));
    const holidays = useMemo(
        () => [...(holidaysN || []), ...(holidaysN1 || [])].map(({ startDate }) => startDate),
        [holidaysN, holidaysN1],
    );
    const optionsValues = {
        weekDays: { value: "weekDays", label: i18n.planning_day_type_week() },
        saturday: { value: "saturday", label: i18n.planning_day_type_saturday() },
        sunday: { value: "sunday", label: i18n.planning_day_type_sunday() },
        holidays: { value: "holidays", label: i18n.planning_day_type_holidays() },
    };
    const optionList = Object.values(optionsValues);

    const weeklyVolumeOptions = [7, 14, 21, 28, 35, 42, 48].map((value) => ({
        value,
        label: value.toString(),
    }));

    const schema = z
        .object({
            startDate: z.date().optional(),
            endDate: z.date().optional(),
            weeklyVolume: z
                .number()
                .min(7, i18n.planning_volume_error())
                .max(48, i18n.planning_volume_error()),
            siders: z.number(),
            include: z
                .object({
                    weekDays: z.boolean(),
                    holidays: z.boolean(),
                    saturday: z.boolean(),
                    sunday: z.boolean(),
                })
                .partial(),
            amplitudeStart: z
                .string()
                .regex(/^(?:[01]\d|2[0-3]):[0-5]\d/, i18n.error_invalid_format())
                .or(z.literal("")),
            amplitudeEnd: z
                .string()
                .regex(/^(?:[01]\d|2[0-3]):[0-5]\d/, i18n.error_invalid_format())
                .or(z.literal("")),
        })
        .superRefine((values, ctx) => {
            if (values.siders < 1 || values.siders > 50) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["siders"],
                    message: i18n.planning_siders_error(),
                });
            }
            if (!Object.values(values.include).some((value) => value)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["include"],
                    message: i18n.planning_day_type_error_empty(),
                });
            }

            if (
                !!values.include.holidays &&
                Object.values(values.include).filter((value) => value).length === 1
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["include"],
                    message: i18n.planning_day_type_error_more(),
                });
            }

            if (values.startDate && values.endDate) {
                if (values.endDate < values.startDate) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ["startDate"],
                        message: i18n.planning_dates_reversed_error(),
                    });
                } else {
                    const minEndDate = sub(add(values.startDate, { days: 7 }), { seconds: 1 });
                    const maxEndDate = add(values.startDate, { months: 18 });

                    if (values.endDate < minEndDate || values.endDate > maxEndDate) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            path: ["startDate"],
                            message: i18n.planning_dates_length_error(),
                        });
                    }
                }
            }

            if (
                !values.include.weekDays &&
                values.include.saturday &&
                values.include.sunday &&
                values.weeklyVolume > 20
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["weeklyVolume"],
                    message: i18n.planning_hourly_volume_error({ hours: 20 }),
                });
            } else if (
                !values.include.weekDays &&
                ((values.include.saturday && !values.include.sunday) ||
                    (!values.include.saturday && values.include.sunday)) &&
                values.weeklyVolume > 10
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["weeklyVolume"],
                    message: i18n.planning_hourly_volume_error({ hours: 10 }),
                });
            }

            if (values.amplitudeStart && values.amplitudeEnd && values.weeklyVolume) {
                const [startHour, startMinute] = values.amplitudeStart.split(":").map(Number);
                const [endHour, endMinute] = values.amplitudeEnd.split(":").map(Number);
                const startTotalMinutes = startHour * 60 + startMinute;
                const endTotalMinutes = endHour * 60 + endMinute;
                const start = set(new Date(), {
                    hours: startHour,
                    minutes: startMinute,
                    seconds: 0,
                    milliseconds: 0,
                });
                const end = set(
                    endTotalMinutes < startTotalMinutes ? add(new Date(), { days: 1 }) : new Date(),
                    {
                        hours: endHour,
                        minutes: endMinute,
                        seconds: 0,
                        milliseconds: 0,
                    },
                );

                let volumePerDay;
                if (values.include.weekDays && values.include.saturday && values.include.sunday) {
                    volumePerDay = (values.weeklyVolume / 7) * 60;
                } else if (
                    values.include.weekDays &&
                    (values.include.saturday || values.include.sunday)
                ) {
                    volumePerDay = (values.weeklyVolume / 6) * 60;
                } else {
                    volumePerDay = (values.weeklyVolume / 5) * 60;
                }

                if (Math.abs(differenceInMinutes(start, end)) < volumePerDay) {
                    ["amplitudeStart", "amplitudeEnd"].forEach((value) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            path: [value],
                            message: i18n.planning_hourly_volume_amplitude_error(),
                        });
                    });
                }
            }

            if (!values.amplitudeStart || !values.amplitudeEnd) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Amplitude is required",
                });
            }
        });

    type Inputs = z.infer<typeof schema>;

    const { watch, control, formState, trigger } = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: planning
            ? {
                  ...planning,
                  startDate: startOfDay(planning.startDate),
                  endDate: endOfDay(planning.endDate),
              }
            : {
                  startDate: startOfDay(new Date()),
                  endDate: endOfDay(add(new Date(), { days: 6 })),
                  weeklyVolume: 35,
                  siders: 1,
                  include: { weekDays: true, holidays: true },
                  amplitudeStart: "",
                  amplitudeEnd: "",
              },
    });

    const { startDate, endDate, include, siders, weeklyVolume, amplitudeStart, amplitudeEnd } =
        watch();

    useEffect(() => {
        // Need to use trigger to make a proper fields validation
        trigger();
        setOrderDataToSend({
            startDate,
            endDate,
            include,
            siders,
            weeklyVolume,
            amplitudeStart,
            amplitudeEnd,
            isDirty: formState.isDirty,
            isValid: formState.isValid,
        });

        setDisabledSubmit(
            !!(
                (!task.flexiblePlanningId && !formState.isValid) ||
                (task.flexiblePlanningId && formState.isDirty && !formState.isValid)
            ),
        );
    }, [
        startDate,
        endDate,
        include,
        siders,
        weeklyVolume,
        amplitudeStart,
        amplitudeEnd,
        formState.isDirty,
        formState.isValid,
    ]);

    // Send tracker for VMS task posting edited
    const { setShouldSendTracker } = useContext(PostingTrackingContext);
    useEffect(() => {
        setShouldSendTracker(true);
        return () => {
            setShouldSendTracker(false);
        };
    }, [formState.isDirty]);

    return (
        <div className='max-w-[642px] px-12 py-8'>
            <div className='mb-8 flex items-center gap-4 text-gray-900'>
                <div className='grid h-[32px] w-[32px] place-items-center rounded-full bg-blue-50'>
                    <FileText className='h-4 w-4 text-blue-500' />
                </div>
                <h2 className='typography-heading-m-semibold'>Planning</h2>
            </div>
            <div className='flex flex-col gap-6'>
                <div className='flex flex-col gap-3'>
                    <Label>{i18n.planning_dates_label()}</Label>
                    <div className='flex items-center gap-1 [&>[class*="TextField"]]:w-1/2'>
                        <Controller
                            control={control}
                            name='startDate'
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value || new Date()}
                                    onSelect={(date) => {
                                        field.onChange(date ? startOfDay(date) : undefined);
                                    }}
                                    size='small'
                                    portal={false}
                                    required
                                    modifiers={{
                                        holidays,
                                        weekEnd: { dayOfWeek: [0, 6] },
                                    }}
                                    modifiersClassNames={{
                                        holidays: "text-red-500",
                                        weekEnd:
                                            'bg-blue-20 [&:not([class*="Selected"])]:rounded-none',
                                    }}
                                    fixedWeeks={true}
                                    fromDate={new Date()}
                                    error={!!formState.errors.startDate || !startDate}
                                />
                            )}
                        />
                        -
                        <Controller
                            control={control}
                            name='endDate'
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value || new Date()}
                                    onSelect={(date) => {
                                        field.onChange(date ? endOfDay(date) : undefined);
                                    }}
                                    size='small'
                                    portal={false}
                                    required
                                    modifiers={{
                                        holidays,
                                        weekEnd: { dayOfWeek: [0, 6] },
                                    }}
                                    modifiersClassNames={{
                                        holidays: "text-red-500",
                                        weekEnd:
                                            'bg-blue-20 [&:not([class*="Selected"])]:rounded-none',
                                    }}
                                    fixedWeeks={true}
                                    error={!!formState.errors.startDate || !endDate}
                                />
                            )}
                        />
                    </div>
                    <ErrorComponent error={formState.errors.startDate} />
                    {!formState.errors.startDate && startDate && endDate && (
                        <div className='flex items-center text-gray-300 typography-body-s-medium'>
                            <Info className='mr-1 h-5 w-5' />
                            {i18n.planning_dates_info()}
                            <span className='ml-1 text-green-500'>
                                {customFormatDuration(startDate, endDate)}
                            </span>
                        </div>
                    )}
                    <Tip>{parseStringWithFontStyle(i18n.planning_dates_tip())}</Tip>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-end gap-2'>
                        <Controller
                            control={control}
                            name='weeklyVolume'
                            render={({ field }) => (
                                <ComboBox
                                    className='w-1/2'
                                    placeholder=''
                                    size='small'
                                    label={i18n.planning_hourly_volume_label()}
                                    selection={
                                        weeklyVolumeOptions.find(
                                            (option) => option.label === weeklyVolume.toString(),
                                        ) || { label: "", value: "" }
                                    }
                                    options={weeklyVolumeOptions}
                                    inputValue={field.value.toString()}
                                    onInputChange={(value) => {
                                        field.onChange(Number(value));
                                    }}
                                    onChange={(value: ListItem) => {
                                        field.onChange(Number(value.value));
                                    }}
                                    allowCustomEntry={true}
                                    filterMethod='none'
                                    openOnInitialClick={true}
                                    error={!!formState.errors.weeklyVolume}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='siders'
                            render={({ field }) => (
                                <TextField
                                    className='w-1/2'
                                    label={i18n.planning_siders_label()}
                                    size='small'
                                    min={1}
                                    max={50}
                                    type='number'
                                    value={field.value}
                                    onChange={(event) => {
                                        const value = Number(event.target.value);
                                        if (!Number.isNaN(value)) {
                                            field.onChange(value);
                                        }
                                    }}
                                    error={!!formState.errors.siders}
                                />
                            )}
                        />
                    </div>
                    <ErrorComponent error={formState.errors.weeklyVolume} />
                    <ErrorComponent error={formState.errors.siders} />
                </div>
                <div className='flex flex-col gap-2'>
                    <Controller
                        control={control}
                        name='include'
                        render={({ field }) => (
                            <Select
                                label={i18n.planning_day_type_label()}
                                size='small'
                                options={optionList}
                                selection={Object.entries(field.value).reduce(
                                    (acc, [key, value]) => {
                                        if (value) {
                                            acc.push(optionsValues[key]);
                                        }
                                        return acc;
                                    },
                                    [] as typeof optionList,
                                )}
                                onChange={(values) => {
                                    field.onChange(
                                        values.reduce(
                                            (acc, value) => {
                                                acc[value.value] = true;
                                                return acc;
                                            },
                                            {} as Inputs["include"],
                                        ),
                                    );
                                }}
                                error={!!formState.errors.include}
                            />
                        )}
                    />
                    <ErrorComponent error={formState.errors.include} />
                </div>
                <div className='flex flex-col gap-2'>
                    <Label>{i18n.planning_amplitude_label()}</Label>
                    <div className='flex w-full gap-1'>
                        <Controller
                            control={control}
                            name='amplitudeStart'
                            render={({ field }) => (
                                <TimeField
                                    className='w-1/2'
                                    placeholder={i18n.planning_amplitude_min_placeholder()}
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                    error={!!formState.errors.amplitudeStart}
                                />
                            )}
                        />
                        <span className='mt-[10px]'>-</span>
                        <Controller
                            control={control}
                            name='amplitudeEnd'
                            render={({ field }) => (
                                <TimeField
                                    className='w-1/2'
                                    placeholder={i18n.planning_amplitude_max_placeholder()}
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                    error={!!formState.errors.amplitudeEnd}
                                />
                            )}
                        />
                    </div>
                    <ErrorComponent
                        error={formState.errors.amplitudeStart || formState.errors.amplitudeEnd}
                    />
                </div>
                <Tip>{i18n.planning_amplitude_tip()}</Tip>
                <Tip>
                    {i18n.planning_legal_tip_1()}
                    <br />
                    <a
                        href='https://support.side.co/b2b/fr/articles/9113080-quelles-sont-les-durees-legales-de-travail-et-repos'
                        target='_blank'
                        rel='noreferrer'
                        className='underline'
                    >
                        {i18n.planning_legal_tip_2()}
                    </a>
                </Tip>
            </div>
        </div>
    );
}

function ErrorComponent({ error }: { error?: { message?: string } }) {
    if (error?.message)
        return (
            <div className='text-red-500 typography-body-s-medium'>
                <p className='flex'>
                    <CircleAlert className='mr-1 h-5 w-5 shrink-0' />
                    {error.message}
                </p>
            </div>
        );
    return null;
}
