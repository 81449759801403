import { useContext, useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import { Box, LucideUsers2, MapPinned, MessageCircleWarning } from "lucide-react";
import { cn, Select, TextArea } from "saphir";
import { Loader } from "sui";

import LocationForm from "@components/LocationForm/LocationForm";
import { SectionHeader } from "@components/SectionHeader";
import * as backend from "@lib/api";
import { useCompanyManagers } from "@lib/hooks/useCompanyManagers";
import { useLocation } from "@lib/hooks/useLocation";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { taskFormRoute } from "@routes/TaskPosting/route";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";

import { PostingTrackingContext } from "../context/PostingTrackingContext";
import { disabledSubmitAtom, orderDataToSendAtom } from "../TaskFormFooter";

import "./DetailsStep.scss";

export function DetailsStep() {
    const isDepartmentsEnabled = useFlag("services-departments");
    const setOrderDataToSend = useSetAtom(orderDataToSendAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const { patchTask: updateTask } = usePatchTask();

    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useSuspenseQuery(queries.task.detail(taskId));
    // TODO: use useSuspenseQuery after isDepartmentsEnabled feature flag is removed
    const { data: departments, isLoading: isDepartmentsLoading } = useQuery({
        ...queries.company.departments(),
        enabled: isDepartmentsEnabled,
    });

    const [remoteLocation, setRemoteLocation] = useState<backend.CreateLocation | null>(null);
    const [orderLocation, setOrderLocation] = useState(task?.location ?? null);
    const [state, setState] = useState({
        remote: task?.location?.remote || false,
        motorized: task?.location?.motorized || false,
        managerId: task?.manager?.id || "",
        missionInformation: task?.missionInformation || "",
        address: task?.location?.address || "",
        departmentId: (() => {
            if (task.departmentId) return task.departmentId;
            if (!isDepartmentsEnabled) return undefined;
            if (departments?.length && departments.length === 1) return departments[0].id;
            return undefined;
        })(),
    });

    useEffect(() => {
        if (departments?.length && departments.length === 1) {
            setState((prevState) => ({
                ...prevState,
                departmentId: departments[0].id,
            }));
        }
    }, [departments]);

    const companyManagers = useCompanyManagers();

    useEffect(() => {
        if (task?.id && companyManagers.length) {
            const isManagerExist = companyManagers.some(({ id }) => id === task?.manager?.id);
            if (!isManagerExist) {
                setState((prevState) => ({
                    ...prevState,
                    managerId: "",
                }));
            }
        }
    }, [companyManagers.length, task?.id]);

    // enabled scrolling to a specific element, cf. Summary
    const { hash } = useLocation();
    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);

            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);

    useEffect(() => {
        setDisabledSubmit(
            !state.address || !state.managerId || (isDepartmentsEnabled && !state.departmentId),
        );
    }, [state.address, state.managerId, state.departmentId]);

    // create the new location if it doesn't exist in order props
    const createNewLocation = async (addressLocation) => {
        try {
            const res = await backend.createLocation({
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`) ?? "",
                location: addressLocation,
            });
            if (res) setRemoteLocation(res);
        } catch (e) {
            return e;
        }

        return null;
    };

    // component mounts
    //    -> if location is favorite or not created yet, duplicate / create it
    //    -> create a new location
    useEffect(() => {
        if (orderLocation?.favorite === true || (!!orderLocation && !orderLocation.id)) {
            createNewLocation({
                ...orderLocation,
                favorite: false,
            });
        }
    }, [orderLocation?.address]);

    // when remoteLocation's reference updates...
    useEffect(() => {
        if (remoteLocation) {
            // ...sync parent's state with new location data + state...
            setOrderDataToSend((prevState) => ({
                ...prevState,
                location: { ...remoteLocation, ...state },
            }));
            // ...and finally PATCH the order with the same data.
            updateTask({ id: taskId, location: { title: null, ...remoteLocation, ...state } });
            setOrderLocation({ title: null, ...remoteLocation, ...state });
        }
    }, [remoteLocation?.address]);

    // update parent's state necessary to update the task
    useEffect(() => {
        setOrderDataToSend(() => ({
            location: {
                ...orderLocation,
                remote: state.remote,
                motorized: state.motorized,
            },
            managerId: state.managerId,
            missionInformation: state.missionInformation,
            ...(isDepartmentsEnabled ? { departmentId: state.departmentId } : {}),
        }));
    }, [
        state.remote,
        state.motorized,
        state.managerId,
        state.missionInformation,
        state.departmentId,
    ]);

    // Send tracker for VMS task posting edited
    const { setShouldSendTracker } = useContext(PostingTrackingContext);

    return (
        <div className='order-form__details-step'>
            <div className='order-form__details-step__content px-10 py-8'>
                {isDepartmentsEnabled ? (
                    <div className='max-w-[560px] border-b border-gray-20 pb-8'>
                        <SectionHeader
                            title={i18n.order_details_service_title()}
                            description={
                                <p
                                    className='[&>a:focus]:focus-ring [&>a]:underline'
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.order_details_service_description(),
                                    }}
                                />
                            }
                            icon={<Box />}
                            className='mb-6 max-w-[720px]'
                        />
                        {isDepartmentsLoading ? (
                            <Loader className='text-blue-500' />
                        ) : (
                            <Select
                                required
                                disabled={departments?.length === 1}
                                label={i18n.order_details_service_title()}
                                placeholder={i18n.order_details_service_placeholder()}
                                value={state.departmentId}
                                onValueChange={(departmentId) => {
                                    setState((prev) => ({ ...prev, departmentId }));
                                }}
                                className='group'
                            >
                                {departments?.map(({ id, name, code }) => (
                                    <Select.Item key={id} value={id} className='p-0'>
                                        <div className='flex max-w-[480px] flex-col items-start break-all text-left'>
                                            <p className='typography-body-m-regular'>{name}</p>
                                            {code ? (
                                                <p className='text-gray-300 typography-body-s-medium group-disabled:text-current'>
                                                    {code}
                                                </p>
                                            ) : null}
                                        </div>
                                    </Select.Item>
                                ))}
                            </Select>
                        )}
                    </div>
                ) : null}
                <div
                    className={cn(
                        "max-w-[560px] border-b border-gray-20",
                        !isDepartmentsEnabled ? "pb-8" : "py-8",
                    )}
                    id='address'
                >
                    <SectionHeader
                        title={i18n.location_form_workplace_title()}
                        description={i18n.location_form_workplace_subtitle()}
                        icon={<MapPinned />}
                        className='max-w-[720px]'
                    />
                    <LocationForm
                        location={{
                            ...orderLocation,
                            remote: state.remote,
                            vehicle: state.motorized,
                        }}
                        setLocationState={(locationData) => {
                            // API is not consistent, LocationForm is used both for task and job desc, for job desc key is called "vehicle" while for task it's "motorized"
                            locationData.motorized = locationData.vehicle;
                            delete locationData.vehicle;

                            setOrderLocation((prevState) => ({
                                ...prevState,
                                ...locationData,
                            }));

                            setState((prevState) => ({
                                ...prevState,
                                ...locationData,
                            }));

                            setShouldSendTracker(true);
                        }}
                    />
                </div>
                <div className='max-w-[560px] border-b border-gray-20 py-8' id='manager'>
                    <SectionHeader
                        title={i18n.manager_form_title()}
                        description={i18n.manager_form_subtitle()}
                        icon={<LucideUsers2 />}
                        className='max-w-[720px]'
                    />
                    <Select
                        label='Manager'
                        required
                        placeholder={i18n.manager_form_label()}
                        value={state.managerId}
                        onValueChange={(selectedItem) => {
                            setState((prevState) => ({
                                ...prevState,
                                managerId:
                                    companyManagers.find(({ value }) => value === selectedItem)
                                        ?.id ?? "",
                            }));
                            setShouldSendTracker(true);
                        }}
                    >
                        {companyManagers.map(({ id, label }) => (
                            <Select.Item key={id} value={id}>
                                {label}
                            </Select.Item>
                        ))}
                    </Select>
                </div>
                <div className='max-w-[560px] pt-8'>
                    <SectionHeader
                        title={i18n.order_details_title()}
                        description={i18n.order_details_subtitle()}
                        icon={<MessageCircleWarning />}
                        className='max-w-[720px]'
                    />
                    <div id='additional-informations'>
                        <TextArea
                            rows={4}
                            label={i18n.order_details_label()}
                            placeholder={i18n.order_details_placeholder()}
                            value={state.missionInformation}
                            onChange={(event) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    missionInformation: event.target.value,
                                }));
                                setShouldSendTracker(true);
                            }}
                            required={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
