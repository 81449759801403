import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { DepartmentSchema } from "./getCompanyDepartments";
import { apiConf } from "./index";

export type PostDepartmentResponse = z.infer<typeof DepartmentSchema>;

export type PostCompanyDepartmentParams = Pick<PostDepartmentResponse, "code" | "name">;

const message = "Post Company Department";
export async function postCompanyDepartment(
    department: PostCompanyDepartmentParams,
): Promise<PostDepartmentResponse> {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: { ...department },
    });

    const organisationId = localStorage.getItem(`side_team_activeOrganisationId`) as string;

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/organisations/${organisationId}/departments`, {
                method: `POST`,
                body: JSON.stringify({ ...department, organisationId }),
            }),
        );

        if (resp.status >= 400) {
            return Promise.reject(new Error(message));
        }

        return DepartmentSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(e);
    }
}
