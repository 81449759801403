import { Loader } from "sui";
import { z } from "zod";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { globalLayoutRoute } from "@routes";
import { allowAdminAndUser, allowCompany } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import { DetailsStep } from "./DetailsStep/DetailsStep";
import { FlexiblePlanningStep } from "./FlexiblePlanningStep/FlexiblePlanningStep";
import { MotiveStep } from "./MotiveStep/MotiveStep";
import { PlanningTypeStep } from "./PlanningTypeStep/PlanningTypeStep";
import { ShiftsStep } from "./ShiftsStep/ShiftsStep";
import { Summary } from "./Summary/Summary";
import { WorkersStep } from "./WorkersStep/WorkersStep";
import TaskForm from "./index";

import styles from "./TaskForm.module.css";

export const taskFormRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `taskPosting/$taskId`,
    component: TaskForm,
    beforeLoad: async ({ params, context }) => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() && allowedAdminAndUser)) {
            throw redirect({ to: `/` });
        }
        context.queryClient.prefetchQuery(queries.task.detail(params.taskId));

        return { getTitle: () => i18n.doc_title_posting() };
    },
});

export const motiveStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `motive`,
    component: MotiveStep,
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className='text-blue-500' />
        </div>
    ),
});

export const planningTypeStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `planning-type`,
    component: PlanningTypeStep,
    beforeLoad: async (args) => {
        const task = await args.context.queryClient.fetchQuery(
            queries.task.detail(args.params.taskId),
        );
        // Do not allow to go to the planning type step if the task has shifts
        // Because Flexible Planning shifts cannot be mixed with regular shifts
        if (task?.shifts?.length > 0) {
            throw redirect({
                to: `/taskPosting/${args.params.taskId}/${!task.flexiblePlanning ? "shifts" : "flexible-planning"}`,
            });
        }
    },
});

export const flexiblePlanningStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `flexible-planning`,
    component: FlexiblePlanningStep,
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className='text-blue-500' />
        </div>
    ),
});

const shiftStepSearchSchema = z
    .object({
        create: z.boolean().optional(),
    })
    .partial();
type ShiftStepSearch = z.infer<typeof shiftStepSearchSchema>;

export const shiftsStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `shifts`,
    component: ShiftsStep,
    validateSearch: (search: ShiftStepSearch) => shiftStepSearchSchema.parse(search),
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className='text-blue-500' />
        </div>
    ),
});

export const detailsStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `details`,
    component: DetailsStep,
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className='text-blue-500' />
        </div>
    ),
});

export const workersStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `workers`,
    component: WorkersStep,
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className='text-blue-500' />
        </div>
    ),
});

export const summaryRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `summary`,
    component: Summary,
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className='text-blue-500' />
        </div>
    ),
});
