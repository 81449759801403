import { Card, Checkbox, Label } from "saphir";

import { i18n } from "@lib/i18n";

import AddressForm from "./AddressForm/AddressForm";

const LocationForm = ({ location, setLocationState }) => (
    <div className='location-form'>
        <div className='flex flex-col gap-2'>
            <AddressForm
                location={location}
                submitAddress={(address) => setLocationState({ ...address })}
            />

            <Card variant='interactive'>
                <Label className='flex cursor-pointer gap-4 p-4'>
                    <Checkbox
                        checked={location?.remote}
                        onCheckedChange={(checked) => {
                            setLocationState({ ...location, remote: checked });
                        }}
                    />
                    {i18n.location_form_workplace_option1()}
                </Label>
            </Card>
            <Card variant='interactive'>
                <Label className='flex cursor-pointer gap-4 p-4'>
                    <Checkbox
                        checked={location?.vehicle}
                        onCheckedChange={(checked) => {
                            setLocationState({ ...location, vehicle: checked });
                        }}
                    />
                    {i18n.location_form_workplace_option2()}
                </Label>
            </Card>
        </div>
    </div>
);
export default LocationForm;
