import { useEffect, useState } from "react";
import {
    addDays,
    differenceInCalendarDays,
    differenceInMinutes,
    getHours,
    getMinutes,
    isBefore,
    isDate,
    setHours,
    setMinutes,
} from "date-fns";
import { fr } from "date-fns/locale";
import { ArrowRight, ChevronRight, Pencil } from "lucide-react";
import { Card, cn } from "saphir";
import { ModalSimple, ModalsService, TimePicker } from "side-ui";

import { i18n } from "@lib/i18n";

import { isLongerThan24Hours, shouldSubtractOneDay } from "../../../../../../utils/dates";

import "../AttendanceEdit.scss";

export default function AttendanceEditHours({
    startTime,
    endTime,
    editHours,
    slotConflict,
    readOnly = false,
}) {
    // keep a reference of startTime for comparison
    const initialState = {
        startTime,
        endTime,
        startTimeFocused: false,
        endTimeFocused: false,
        touched: false,
        error: false,
        plusOneDay: differenceInCalendarDays(endTime, startTime) === 1,
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        // reset state after editHours has been called
        setState((prevState) => ({
            ...prevState,
            startTime,
            endTime,
        }));
    }, [editHours]);

    return (
        <>
            <Card
                className={cn(
                    `attendance-edit attendance-edit--hours ${readOnly ? "attendance-edit--read-only" : ""} `,
                    "!shadow-none",
                )}
                onClick={
                    readOnly
                        ? () => null
                        : () => {
                              ModalsService.openModal({
                                  id: `EDIT_HOURS`,
                                  content: (
                                      <AttendanceEditHoursModal
                                          state={state}
                                          setState={setState}
                                          initialState={initialState}
                                          editHours={editHours}
                                      />
                                  ),
                              });
                          }
                }
                role='button'
            >
                <div className='attendance-edit__wrapper'>
                    <div className='attendance-edit__label__wrapper'>
                        <p className='timesheets-table__panel__subtitle'>
                            {i18n.ts_edit_form_hours_label()}
                        </p>

                        <Pencil className='h-4 w-4 text-gray-300' />
                    </div>

                    <div className='attendance-edit__values'>
                        <div className='attendance-edit__values__wrapper'>
                            <p
                                className={`timesheets-table__panel__title ${slotConflict.startDate ? "conflicting" : ""} `}
                            >
                                {startTime.toLocaleTimeString().slice(0, 5)}
                            </p>

                            <ChevronRight className='h-4 w-4 text-gray-300' />

                            <p
                                className={`timesheets-table__panel__title ${slotConflict.endDate ? "conflicting" : ""} `}
                            >
                                {endTime.toLocaleTimeString().slice(0, 5)}
                            </p>
                        </div>

                        {state.plusOneDay ||
                        differenceInCalendarDays(state.endTime, state.startTime) === 1 ? (
                            <span className='attendance-edit__values--additional-day'>+1</span>
                        ) : null}
                    </div>
                </div>
            </Card>
        </>
    );
}

const AttendanceEditHoursModal = ({ state, setState, initialState, editHours }) => {
    const [stateCopy, setStateCopy] = useState(state);

    // When dates are inputted via the keyboard, ReactDatepicker shifts the date to the current day
    // and updates only the time
    // so we need to make sure to extract the initial date first
    // and add hours and minutes to it
    const setStartTime = (time) => {
        if (!isDate(time)) {
            return false;
        }
        const shiftStartTime = new Date(initialState.startTime);
        const updateStartTime = setHours(shiftStartTime, getHours(time));
        const timeResult = setMinutes(updateStartTime, getMinutes(time));

        setStateCopy((prevState) => ({
            ...prevState,
            startTime: timeResult,
            startTimeFocused: false,
            endTimeFocused: false,
        }));

        return setState((prevState) => ({
            ...prevState,
            startTime: timeResult,
            touched: true,
            startTimeFocused: false,
            endTimeFocused: false,
            siderDidntWork: false,
            error: differenceInMinutes(state.endTime, timeResult) > 1440, // 24 hours
            plusOneDay: differenceInCalendarDays(state.endTime, timeResult) === 1,
        }));
    };

    // When dates are inputted via the keyboard, ReactDatepicker shifts the date to the current day
    // and updates time only.
    // so we need to make sure to extract the initial date first
    // and add hours and minutes to it
    // if shifts spans over one day we need to subtract one day
    // if user inputs hours in the same's day evening
    const setEndTime = (time) => {
        if (!isDate(time)) {
            return false;
        }

        const shiftEndDate = new Date(initialState.endTime);
        const setEndTimeHours = setHours(shiftEndDate, getHours(time));
        // dealing with day + 1 case
        const computedEndTime = shouldSubtractOneDay(
            state.startTime,
            state.endTime,
            setMinutes(setEndTimeHours, getMinutes(time)),
        );
        const endTimeResult = isBefore(computedEndTime, state.startTime)
            ? addDays(computedEndTime, 1)
            : computedEndTime;

        setStateCopy((prevState) => ({
            ...prevState,
            endTime: endTimeResult,
            startTimeFocused: false,
            endTimeFocused: false,
        }));

        return setState((prevState) => ({
            ...prevState,
            endTime: endTimeResult,
            touched: true,
            startTimeFocused: false,
            endTimeFocused: false,
            siderDidntWork: false,
            error: isLongerThan24Hours(state.startTime, computedEndTime),
            plusOneDay: differenceInCalendarDays(endTimeResult, state.startTime) === 1,
        }));
    };

    const handleFocus = (focusedInput) => {
        setStateCopy((prevState) => ({
            ...prevState,
            [focusedInput]: true,
        }));
    };

    const handleBlur = (blurredInput) => {
        setStateCopy((prevState) => ({
            ...prevState,
            [blurredInput]: false,
        }));
    };

    return (
        <ModalSimple
            title={i18n.ts_edit_form_hours_title()}
            validateLabel={i18n.save()}
            hideModal={() => {
                ModalsService.closeModal("EDIT_HOURS");
            }}
            withCloseButton={true}
            cancelLabel={i18n.cancel()}
            action={() => {
                editHours({
                    startDate: stateCopy.startTime,
                    endDate: stateCopy.endTime,
                });
                ModalsService.closeModal("EDIT_HOURS");
            }}
            customClass='attendance-edit-hours-modal'
        >
            <section
                className={`attendance-edit__content ${
                    state?.startTimeFocused || state?.endTimeFocused
                        ? "attendance-edit__content--focused"
                        : ""
                } ${state?.error ? "attendance-edit__content--error" : ""} `}
            >
                <header className='attendance-edit__content__header'>
                    <p className='timesheets-table__panel__subtitle'>
                        {i18n.ts_edit_form_description()}
                    </p>
                </header>

                <div className='attendance-edit__content__form'>
                    <span className='sui-timepicker__label'>{i18n.ts_table_head_start_end()}</span>

                    <div className='attendance-edit__content__form__wrapper'>
                        <TimePicker
                            selectedTime={stateCopy.startTime}
                            onChange={setStartTime}
                            onFocus={() => handleFocus("startTimeFocused")}
                            onBlur={() => handleBlur("startTimeFocused")}
                            hasFocus={stateCopy.startTimeFocused}
                            timeIntervals={15}
                            locale={fr}
                            id='timepicker-1'
                        />
                        <ArrowRight className='h-4 w-4 text-gray-300' />
                        <TimePicker
                            selectedTime={stateCopy.endTime}
                            onChange={setEndTime}
                            onFocus={() => handleFocus("endTimeFocused")}
                            onBlur={() => handleBlur("endTimeFocused")}
                            hasFocus={stateCopy.endTimeFocused}
                            timeIntervals={15}
                            locale={fr}
                            id='timepicker-2'
                        />

                        {state.plusOneDay ? (
                            <span className='attendance-edit__content__form--additional-day'>
                                +1
                            </span>
                        ) : null}
                    </div>

                    {state.error && (
                        <span className='input-error'>
                            {i18n.ts_edit_form_hours_24_hours_error()}
                        </span>
                    )}
                </div>
            </section>
        </ModalSimple>
    );
};
