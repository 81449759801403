import { Plus } from "lucide-react";
import { Button, Card } from "saphir";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useSuspenseQuery } from "@tanstack/react-query";

import { DepartmentDialog } from "./DepartmentDialog";

export function Departments() {
    const { data: departments } = useSuspenseQuery(queries.company.departments());

    return (
        <div className='relative flex-grow overflow-hidden'>
            <div className='absolute inset-0 flex h-full w-full justify-center overflow-hidden pt-10'>
                <div className='flex max-h-fit w-full max-w-[660px] flex-col overflow-y-auto rounded bg-white pb-10'>
                    <div className='sticky top-0 flex flex-col gap-6 rounded-t bg-white p-10'>
                        <div className='flex justify-between'>
                            <h1 className='text-gray-900 typography-heading-xl-semibold'>
                                {i18n.settings_departments_title()}
                                <span className='ml-1 text-gray-300'>({departments.length})</span>
                            </h1>
                            <DepartmentDialog
                                departments={departments}
                                trigger={
                                    <Button variant='primary-outlined'>
                                        <Plus />
                                        {i18n.add()}
                                    </Button>
                                }
                            />
                        </div>
                        <p className='text-gray-300 typography-body-m-regular'>
                            {i18n.settings_departments_description()}
                        </p>
                    </div>
                    {departments.length ? (
                        <div className='flex flex-col gap-3 px-10'>
                            {departments.map((service) => (
                                <Card key={service.id} className='flex flex-col gap-1 p-4'>
                                    <p className='typography break-words typography-body-m-semibold'>
                                        {service.name}
                                    </p>
                                    {service.code ? (
                                        <p className='text-gray-300 typography-body-s-medium'>
                                            {service.code}
                                        </p>
                                    ) : null}
                                </Card>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
