import { useEffect } from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { ArrowLeft } from "lucide-react";
import { ModalSimple, ModalsService, SectionHeader, SummaryTableRow } from "side-ui";
import { Button, fonts, Loader, Tag, Tip } from "sui";

import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { getLocale } from "@lib/utils/getLocale";
import { customFormatDuration, planningDaysSorted } from "@routes/TaskPosting/Summary/Summary";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

import ShiftsTable from "../../../components/ShiftsTable";
import UsersList from "../../../components/UsersList";
import Quotation from "../../TaskPosting/Quotation/Quotation";
import DuplicateModal from "../containers/DuplicateModal";
import { taskViewRoute } from "../route";

import { EditTaskMotiveDialog } from "./EditTaskMotiveDialog/EditTaskMotiveDialog";
import { EditTaskAddressDetailsDialog } from "./EditTaskAddressDetailsDialog";
import { EditTaskAddressDialog } from "./EditTaskAddressDialog";
import { EditTaskDressCodeDialog } from "./EditTaskDressCodeDialog";
import { EditTaskEquipmentsOtherDialog } from "./EditTaskEquipmentsOtherDialog";
import { EditTaskEquipmentsSafetyDialog } from "./EditTaskEquipmentsSafetyDialog";
import { EditTaskExecutiveStatusDialog } from "./EditTaskExecutiveStatusDialog";
import { EditTaskExperiencesDialog } from "./EditTaskExperiencesDialog";
import { EditTaskLanguagesDialog } from "./EditTaskLanguagesDialog";
import { EditTaskLicencesDialog } from "./EditTaskLicencesDialog";
import { EditTaskManagerDialog } from "./EditTaskManagerDialog";
import { EditTaskMissionInformationDialog } from "./EditTaskMissionInformationDialog";
import { EditTaskMotorizedDialog } from "./EditTaskMotorizedDialog";
import { EditTaskPurposeDialog } from "./EditTaskPurposeDialog";
import { EditTaskRemoteDialog } from "./EditTaskRemoteDialog";
import { EditTaskRisksDialog } from "./EditTaskRisksDialog";
import { EditTaskSalaryDialog } from "./EditTaskSalaryDialog";
import { EditTaskSideNoteDialog } from "./EditTaskSideNoteDialog";
import { EditTaskSubtasksDialog } from "./EditTaskSubtasksDialog";
import { EditTaskSuffixDialog } from "./EditTaskSuffixDialog";
import { EditTaskToolsDialog } from "./EditTaskToolsDialog";
import { EditTaskWorkConditionsDialog } from "./EditTaskWorkConditionsDialog";
import { EditTaskWorkersDialog } from "./EditTaskWorkersDialog";

import "./TaskView.scss";

const taskStatus = {
    DRAFT: "draft",
    NEW: "new",
    ONGOING: "ongoing",
    UPCOMING: "upcoming",
    CLOSED: "closed",
};

const tagColors = {
    [taskStatus.DRAFT]: "orange",
    [taskStatus.NEW]: "grey",
    [taskStatus.ONGOING]: "green",
    [taskStatus.UPCOMING]: "blue",
    [taskStatus.CLOSED]: "grey",
} as const;

const TaskView = () => {
    const locale = getLocale();
    const { taskId } = taskViewRoute.useParams();
    const search = taskViewRoute.useSearch();
    const { data: task } = useSuspenseQuery({
        ...queries.task.detail(taskId),
    });
    const { data: planning } = useQuery({
        ...queries.task.planning(taskId),
        queryKey: [...queries.task.planning(taskId).queryKey, task.flexiblePlanningId],
        queryFn: () => (task.flexiblePlanningId ? queries.task.planning(taskId).queryFn() : null),
    });
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");
    const isDepartmentsEnabled = useFlag("services-departments");
    // TODO: use useSuspenseQuery after isDepartmentsEnabled feature flag is removed
    const { data: departments, isLoading: isDepartmentsLoading } = useQuery({
        ...queries.company.departments(),
        enabled: isDepartmentsEnabled,
    });
    const { data: currentYearHolidays } = useQuery(queries.holiday.list(new Date().getFullYear()));
    const { data: nextYearHolidays } = useQuery(queries.holiday.list(new Date().getFullYear() + 1));
    const holidays = [...(currentYearHolidays ?? []), ...(nextYearHolidays ?? [])];
    const { history } = useRouter();

    const navigateBack = () => history.back();
    const navigate = useNavigate();

    const { data: quotation } = useQuery(queries.quotation.detail(taskId));

    const orderSections = [
        {
            label: i18n.order_summary_name_title(),
            content: task.name || "-",
            dialog: EditTaskSuffixDialog,
        },
        {
            label: i18n.job_descriptions_summary_jobTitle_title(),
            content: task.jobTitle || "-",
        },
        {
            label: i18n.job_descriptions_summary_subtasks_title(),
            content: task.subtasks ? (
                <ul className='list-disc pl-4'>
                    {task.subtasks.map((subtask) => (
                        <li key={subtask.id}>{subtask.name}</li>
                    ))}
                </ul>
            ) : (
                "-"
            ),
            dialog: !task.hasContracts && EditTaskSubtasksDialog,
        },
        {
            label: i18n.job_descriptions_creation_salary_input_label(),
            content: task.hourlyRate ? `${task.hourlyRate}€` : "-",
            dialog: !task.hasContracts && EditTaskSalaryDialog,
        },
        ...(isDepartmentsEnabled
            ? [
                  {
                      label: i18n.order_details_service_title(),
                      action: () =>
                          navigate({
                              to: "/taskPosting/$taskId/details",
                              params: { taskId },
                          }),
                      content: isDepartmentsLoading ? (
                          <Loader className='text-blue-500' />
                      ) : task.departmentId && departments ? (
                          (() => {
                              const department = departments.find(
                                  (department) => department.id === task.departmentId,
                              );
                              return department ? (
                                  <div className='flex flex-col gap-1'>
                                      <p>{department.name}</p>
                                      <p className='text-gray-300'>{department.code}</p>
                                  </div>
                              ) : (
                                  "-"
                              );
                          })()
                      ) : (
                          "-"
                      ),
                  },
              ]
            : []),
        {
            label: i18n.job_descriptions_summary_status_title(),
            content: task.executiveStatus
                ? i18nDK(`job_descriptions_status_${task.executiveStatus}`)
                : "-",
            dialog: !task.hasContracts && EditTaskExecutiveStatusDialog,
        },
        {
            label: i18n.order_summary_address_title(),
            content: task.location?.address || "-",
            dialog: !task.hasContracts && !task.requestedSiders && EditTaskAddressDialog,
        },
        {
            label: i18n.order_summary_address_additional_infos(),
            content: task.location?.description || "-",
            dialog: EditTaskAddressDetailsDialog,
        },
        {
            label: i18n.order_summary_remote_title(),
            content: task.location?.remote ? i18n.order_summary_yes() : "-",
            dialog: !task.hasContracts && EditTaskRemoteDialog,
        },
        {
            label: i18n.order_summary_motorized_title(),
            content: task.location?.motorized ? i18n.order_summary_yes() : "-",
            dialog: !task.hasContracts && EditTaskMotorizedDialog,
        },
        {
            label: i18n.order_summary_manager_title(),
            content: task.manager ? `${task.manager.firstName} ${task.manager.lastName}` : "-",
            dialog: !task.hasContracts && EditTaskManagerDialog,
        },
        // START FLEXIBLE PLANNING
        ...(isFlexiblePlanningEnabled && planning
            ? [
                  {
                      label: i18n.order_summary_planning_title(),
                      content: (
                          <div>
                              <p>
                                  {i18n.order_summary_planning_sentence_1({
                                      siders:
                                          planning.siders +
                                          (planning.siders > 1 ? " Siders" : " Sider"),
                                      weeklyVolume: planning.weeklyVolume,
                                  })}
                                  <br />
                                  {i18n.order_summary_planning_sentence_2({
                                      startDate: format(new Date(planning.startDate), "P", {
                                          locale,
                                      }),
                                      endDate: format(new Date(planning.endDate), "P", { locale }),
                                  })}
                                  <br />
                                  {`${planningDaysSorted
                                      .reduce((acc, value) => {
                                          if (planning.include[value]) {
                                              return [
                                                  ...acc,
                                                  i18nDK(
                                                      `order_summary_planning_sentence_${value}`,
                                                  ),
                                              ];
                                          }
                                          return acc;
                                      }, [])
                                      .map((day, index, self) => {
                                          if (index === self.length - 1) {
                                              return day;
                                          } else if (index === self.length - 2) {
                                              return `${day} ${i18n.order_summary_planning_sentence_and()} `;
                                          }
                                          return `${day}, `;
                                      })
                                      .join("")}${
                                      planning.include.holidays
                                          ? ` ${i18n.order_summary_planning_sentence_holidays()}`
                                          : ""
                                  }`}
                                  <br />
                                  {i18n.order_summary_planning_sentence_3({
                                      amplitudeStart: planning.amplitudeStart,
                                      amplitudeEnd: planning.amplitudeEnd,
                                  })}
                              </p>
                              <p className='mt-1 text-gray-300 typography-body-s-medium'>
                                  {(() => {
                                      const sentence = i18n.order_summary_planning_sentence_figures(
                                          {
                                              total: quotation?.assignments.reduce(
                                                  (acc, assignment) => {
                                                      return acc + Number(assignment.quantity);
                                                  },
                                                  0,
                                              ),
                                          },
                                      );
                                      return `${sentence} ${customFormatDuration(new Date(planning.startDate), new Date(planning.endDate))}`;
                                  })()}
                              </p>
                          </div>
                      ),
                  },
              ]
            : [
                  {
                      label: isFlexiblePlanningEnabled
                          ? i18n.order_summary_planning_title()
                          : i18n.order_summary_shifts_title(),
                      content: task.shifts ? (
                          <ShiftsTable shifts={task.shifts || []} holidays={holidays} />
                      ) : (
                          "-"
                      ),
                      layout: "block",
                  },
              ]),
        // END FLEXIBLE PLANNING
        {
            label: i18n.order_summary_workers_title(),
            content: task.requestedSiders ? <UsersList users={task.requestedSiders} /> : "-",
            contentBelow: task.requestedSidersOnly ? (
                <Tip intention='warning'>{i18n.order_summary_workers_wa_warning()}</Tip>
            ) : null,
            dialog: !task.hasContracts && EditTaskWorkersDialog,
        },
        {
            label: i18n.order_preliminary_details_type_label(),
            content: i18nDK(`order_preliminary_details_type_${task.type}_label`) || "-",
        },
        {
            label: i18n.order_summary_motive_title(),
            content: task.motive
                ? i18nDK(
                      `order_motives_label_${task.motive?.reason
                          .split(/(?=[A-Z])/)
                          .join("_")
                          .toLowerCase()}`,
                  )
                : "-",
            dialog: !task.hasContracts && EditTaskMotiveDialog,
        },
        {
            label: i18n.order_summary_justification_title(),
            content: task.motive?.justification || "-",
            dialog: !task.hasContracts && EditTaskMotiveDialog,
        },
        {
            label: i18n.job_descriptions_summary_context_title(),
            content: task.purpose || "-",
            dialog: !task.hasContracts && EditTaskPurposeDialog,
        },
        {
            label: i18n.job_descriptions_summary_safetyEquipment_title(),
            content: (() => {
                const safetyEquipments = task.equipments?.filter(
                    (equipment) => equipment.group === "Safety",
                );

                return safetyEquipments?.length ? (
                    <ul className='w-full list-disc pl-4'>
                        {safetyEquipments.map((equipment) => (
                            <li key={equipment.id}>
                                <div className='mb-2 flex flex-col'>
                                    <span>{i18nDK(equipment.name)}</span>
                                    <span className='text-blue-500'>
                                        {equipment.providedBy === "sider"
                                            ? i18n.job_descriptions_creation_conditions_equipment_provider_sider()
                                            : i18n.job_descriptions_creation_conditions_equipment_provider_company()}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    "-"
                );
            })(),
            dialog: !task.hasContracts && EditTaskEquipmentsSafetyDialog,
        },
        {
            label: i18n.job_descriptions_summary_devices_title(),
            content: (() => {
                const otherEquipments = task.equipments?.filter(
                    (equipment) => equipment.group === "Other",
                );

                return otherEquipments?.length ? (
                    <ul className='list-disc pl-4'>
                        {otherEquipments.map((equipment) => (
                            <li key={equipment.id}>
                                <div className='mb-2 flex flex-col'>
                                    <span>{i18nDK(equipment.name)}</span>
                                    <span className='text-blue-500'>
                                        {equipment.providedBy === "sider"
                                            ? i18n.job_descriptions_creation_conditions_equipment_provider_sider()
                                            : i18n.job_descriptions_creation_conditions_equipment_provider_company()}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    "-"
                );
            })(),
            dialog: !task.hasContracts && EditTaskEquipmentsOtherDialog,
        },
        {
            label: i18n.job_descriptions_summary_dresscode_title(),
            content: task.dressCode || "-",
            dialog: EditTaskDressCodeDialog,
        },
        {
            label: i18n.job_descriptions_summary_risks_title(),
            content: task.risks ? (
                <ul className='list-disc pl-4'>
                    {task.risks.map((risk) => (
                        <li key={risk.id}>
                            {i18nDK(risk.name)}
                            {risk.value && ` - ${i18nDK(risk.value)}`}
                        </li>
                    ))}
                </ul>
            ) : (
                "-"
            ),
            dialog: !task.hasContracts && EditTaskRisksDialog,
        },
        {
            label: i18n.order_summary_risks_additionnal_infos(),
            content: task.workConditions || "-",
            dialog: !task.hasContracts && EditTaskWorkConditionsDialog,
        },
        {
            label: i18n.job_descriptions_summary_languages_title(),
            content: task.languages ? (
                <ul className='list-disc pl-4'>
                    {task.languages.map((language) => (
                        <li key={language.id}>
                            <div className='mb-2 flex flex-col'>
                                <span>{i18nDK(language.ISOCode)}</span>
                                <span className='text-blue-500'>{i18nDK(language.level)}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                "-"
            ),
            dialog: !task.hasContracts && EditTaskLanguagesDialog,
        },
        {
            label: i18n.job_descriptions_summary_tools_title(),
            content: task.tools ? (
                <ul className='list-disc pl-4'>
                    {task.tools.map((tool) => (
                        <li key={tool.id}>
                            <div className='mb-2 flex flex-col'>
                                <span>{i18nDK(tool.name)}</span>
                                {tool.level ? (
                                    <span className='text-blue-500'>{i18nDK(tool.level)}</span>
                                ) : null}
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                "-"
            ),
            dialog: !task.hasContracts && EditTaskToolsDialog,
        },
        {
            label: i18n.job_descriptions_summary_licences_title(),
            content: task.licences ? (
                <ul className='list-disc pl-4'>
                    {task.licences.map((licence) => (
                        <li key={licence.id}>{i18nDK(licence.name)}</li>
                    ))}
                </ul>
            ) : (
                "-"
            ),
            dialog: !task.hasContracts && EditTaskLicencesDialog,
        },
        {
            label: i18n.job_descriptions_summary_experiences_title(),
            content: task.experiences || "-",
            dialog: !task.hasContracts && EditTaskExperiencesDialog,
        },
        {
            label: i18n.order_summary_confirmation_sidenote(),
            content: task.sideNote || "-",
            dialog: EditTaskSideNoteDialog,
        },
        {
            label: i18n.order_summary_info_title(),
            content: task.missionInformation || "-",
            dialog: EditTaskMissionInformationDialog,
        },
    ];

    function openQuotationModal() {
        ModalsService.openModal({
            id: `PRICE_ESTIMATION`,
            content: (
                <ModalSimple
                    title={
                        <SectionHeader title={i18n.order_summary_estimate_title()} icon='Trophy' />
                    }
                    hideModal={() => ModalsService.closeModal(`PRICE_ESTIMATION`)}
                    withCloseButton={true}
                >
                    <Quotation quotation={quotation} withTaxes={true} taskId={taskId} />
                </ModalSimple>
            ),
        });
    }

    useEffect(() => {
        trackEvent({
            name: `team - recap page seen`,
            params: {
                logAs: !!localStorage.getItem("side_team_logas_email"),
                taskId,
                orgId: localStorage.getItem("side_team_activeOrganisationId"),
                origin: search.origin,
            },
        });
    }, []);

    return (
        <div className='task-view flex h-full flex-col'>
            <header className='order-form__header task-view__header !p-4'>
                <div className='task-view__header__details'>
                    <Button
                        iconDisposition='icon-only'
                        onClick={navigateBack}
                        shape='outlined'
                        intention='secondary'
                        icon={<ArrowLeft className='h-5 w-5' />}
                    />

                    <p className={clsx(fonts.sans22Medium, "text-gray-900")} title={task.name}>
                        {task.name}
                    </p>
                    <Tag color={tagColors[task.progressStep]}>
                        {i18nDK(`task_status_${task.progressStep}`)}
                    </Tag>
                    <Tag color={task.type === "delegation" ? "blue" : "purple"}>{task.type}</Tag>
                </div>
            </header>
            <div className='relative flex-grow overflow-y-auto'>
                <div className='absolute inset-0'>
                    <section className='task-view__content'>
                        <section className='task-view__summary__wrapper'>
                            <div className='task-view__summary'>
                                {task.hasContracts ? (
                                    <div className='p-10 pb-0'>
                                        <Tip intention='warning' highlight>
                                            {i18n
                                                .task_edit_contract_warning()
                                                .split("\n")
                                                .map((line, index) => {
                                                    return line ? (
                                                        <p key={index}>{line.trim()}</p>
                                                    ) : null;
                                                })}
                                        </Tip>
                                    </div>
                                ) : null}
                                {task.requestedSiders ? (
                                    <div className='p-10 pb-0'>
                                        <Tip intention='warning' highlight>
                                            {i18n.task_edit_requested_siders_warning()}
                                        </Tip>
                                    </div>
                                ) : null}
                                <SectionHeader title={i18n.order_summary_title()} icon='Trophy' />

                                <div className='order-form__summary__order__items !pb-6'>
                                    {orderSections.map((section) => {
                                        if (!section?.content || !section?.label) return;

                                        if (section.dialog) {
                                            const Dialog = section.dialog;

                                            return (
                                                <Dialog
                                                    key={section.label}
                                                    taskId={taskId}
                                                    trigger={
                                                        <div>
                                                            <SummaryTableRow
                                                                label={section.label}
                                                                content={section.content}
                                                                action={() => {}}
                                                                actionIcon='Pen'
                                                                layout={section.layout}
                                                            />
                                                        </div>
                                                    }
                                                    tracker={() => {
                                                        trackEvent({
                                                            name: `team - update task from task summary`,
                                                            params: {
                                                                logAs: !!localStorage.getItem(
                                                                    "side_team_logas_email",
                                                                ),
                                                                taskId,
                                                                orgId: localStorage.getItem(
                                                                    "side_team_activeOrganisationId",
                                                                ),
                                                                field: section.label,
                                                            },
                                                        });
                                                    }}
                                                />
                                            );
                                        } else
                                            return (
                                                <SummaryTableRow
                                                    key={section.label}
                                                    label={section.label}
                                                    content={section.content}
                                                    actionIcon='Pen'
                                                    layout={section.layout}
                                                />
                                            );
                                    })}
                                </div>
                            </div>
                        </section>

                        <section className='task-view__quotation'>
                            <SectionHeader
                                title={i18n.order_summary_estimate_title()}
                                icon='EuroSign'
                            />

                            <div className='task-view__price-estimate'>
                                <div className='task-view__price-estimate__label'>
                                    {i18n.order_summary_estimate_total_without_taxes()}
                                </div>
                                <div className='task-view__price-estimate__amount'>
                                    {quotation?.price
                                        ? `${parseFloat(quotation.price).toFixed(2)}€`
                                        : "-"}
                                </div>
                            </div>
                            <div className='task-view__price-estimate-tax'>
                                <div className='task-view__price-estimate-tax__label'>
                                    {i18n.order_summary_estimate_total_with_taxes()}
                                </div>
                                <div className='task-view__price-estimate-tax__amount'>
                                    {quotation?.price
                                        ? `${(parseFloat(quotation.price) * 1.2).toFixed(2)}€`
                                        : "-"}
                                </div>
                            </div>

                            <button
                                className='task-view__confirmation__price__button'
                                onClick={() => openQuotationModal()}
                                type='button'
                                disabled={!task.shifts}
                            >
                                {i18n.order_summary_estimate_details()}
                            </button>
                        </section>
                    </section>
                </div>
            </div>

            <footer className='flex h-[73px] justify-end border-t border-gray-50 bg-white p-4'>
                {task.status !== taskStatus.DRAFT ? (
                    <Button
                        onClick={() => {
                            ModalsService.openModal({
                                id: `DUPLICATE_TASK`,
                                content: <DuplicateModal task={task} />,
                            });
                        }}
                    >
                        {i18n.duplicate_draft()}
                    </Button>
                ) : (
                    <Button
                        onClick={() =>
                            navigate({
                                to: `/taskPosting/$taskId/motive`,
                                params: { taskId },
                            })
                        }
                    >
                        {i18n.finish_draft()}
                    </Button>
                )}
            </footer>
        </div>
    );
};

export default TaskView;
