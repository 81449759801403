import { useEffect, useState } from "react";
import { startOfDay } from "date-fns";
import { chain } from "lodash";
import { toast } from "saphir";
import { Blank, Dialog, Loader, Tip } from "side-ui";

import { queryClient } from "@App";
import { Attendance, GetUserInfoResponse, sendDispute, validateAttendances } from "@lib/api";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { timesheetsRoute } from "@routes/Timesheets/route";
import { useMutation, useQuery } from "@tanstack/react-query";

import trackEvent from "../../../../../lib/trackers";
import { ATT_WAITING, sumAttendancesNetDuration } from "../../../utils";

import TimesheetsTableActionBar from "./TimesheetsTableActionBar";
import TimesheetsTableBody from "./TimesheetsTableBody";
import TimesheetsTableHeader from "./TimesheetsTableHeader";

import "./TimesheetsTable.scss";

const TimesheetsTable = ({ companyId, navigationMode, logAs, setPageCount }) => {
    const filters = timesheetsRoute.useSearch();
    const { data: attendancesData, isLoading } = useQuery(queries.attendance.list(filters));
    const [attendances, setAttendances] = useState<Attendance[]>([]);

    const attendancesGroupedByDates = chain(attendances)
        .orderBy("history[0].startDate", ["desc", "asc"])
        .groupBy(({ history }) => startOfDay(new Date(history[0].startDate)))
        .value();

    const { mutate: validateAttendancesMutation } = useMutation({
        mutationFn: (arg: Parameters<typeof validateAttendances>[0]) => {
            return validateAttendances(arg);
        },
        onSuccess: (data, req) => {
            if (data) {
                const numberOfNonValidated = data.rejectedAttendanceIds.length;
                const numberOfValidated = req.length - numberOfNonValidated;

                toast.success(
                    numberOfValidated > 1
                        ? i18n.ts_hours_validation_modal_success_plural({
                              count: numberOfValidated,
                          })
                        : i18n.ts_hours_validation_modal_success({
                              count: numberOfValidated,
                          }),
                );

                if (numberOfNonValidated !== 0) {
                    toast.warning(
                        numberOfNonValidated > 1
                            ? i18n.ts_hours_validation_modal_failure_plural({
                                  count: numberOfNonValidated,
                              })
                            : i18n.ts_hours_validation_modal_failure({
                                  count: numberOfNonValidated,
                              }),
                    );
                }

                queryClient.invalidateQueries(queries.attendance.toValidateCount());
                queryClient.invalidateQueries(queries.attendance.list(filters));

                const userInfo = queryClient.getQueryData<GetUserInfoResponse>(
                    queries.user.detail().queryKey,
                );
                const role = userInfo?.role;

                // send trackers on success
                trackEvent({
                    name: "timesheet - validates attendance",
                    params: {
                        organisationId: companyId,
                        organisationName: companyName,
                        multiple: selectedAttendances.length > 1,
                        allSelected:
                            selectedAttendances.length > 0 &&
                            selectedAttendances.length ===
                                attendances.filter((a) => a.status === ATT_WAITING).length,
                        role,
                    },
                });
            }
        },
    });

    const { mutate: sendDisputeMutation } = useMutation({
        mutationFn: (arg: Parameters<typeof sendDispute>[0]) => {
            return sendDispute(arg);
        },
        onSuccess: (_, req) => {
            toast.success(i18n.ts_attendance_disputed_success());

            queryClient.invalidateQueries(queries.attendance.list(filters));

            trackEvent({
                name: req.trackerName,
                params: {
                    companyId: req.companyId,
                    companyName: req.companyName,
                    shiftId: req.shiftId,
                    source: req.source,
                    multiple: req.multiple,
                },
            });
        },
    });

    // put attendances in state to show previous data while loading
    useEffect(() => {
        if (!isLoading) {
            if (!attendancesData?.attendances) return;
            setAttendances(attendancesData?.attendances);
            const count = Math.ceil(attendancesData?.total / 50) || 0;
            if (Number.isInteger(count)) {
                setPageCount(count);
            }
        }
    }, [attendancesData]);

    const [selectedAttendances, setSelectedAttendances] = useState<string[]>([]);
    const initialDialogState = {
        validation: false,
        siderDidntWork: false,
        attendanceId: null,
        absenceJustification: null,
    };
    const [dialogState, setDialogState] = useState(initialDialogState);
    const { data: companyInfo } = useQuery(queries.company.detail());
    const companyName = companyInfo?.organisation.name ?? "";

    useEffect(() => {
        // unselecting attendances if attendances change
        // so that user don't get the action bar
        setSelectedAttendances([]);
    }, [attendancesData]);

    const selectAll = () => {
        const selectableAttendances = attendances.filter(
            (a) => a.status === ATT_WAITING && a.history[1].origin !== "company",
        );

        if (selectedAttendances.length === selectableAttendances.length) {
            return setSelectedAttendances([]);
        }
        setSelectedAttendances(selectableAttendances.map(({ id }) => id));

        return trackEvent({
            name: "timesheet - select all",
            params: {
                organisationId: companyId,
                organisationName: companyName,
                logAs,
            },
        });
    };

    const selectAttendance = (attendanceId) => {
        if (selectedAttendances.includes(attendanceId)) {
            return setSelectedAttendances(selectedAttendances.filter((id) => id !== attendanceId));
        }
        return setSelectedAttendances(selectedAttendances.concat(attendanceId));
    };

    const setSiderDidntWork = () => {
        const attendancesToDispute = attendances
            .filter((a) => {
                if (dialogState.attendanceId) {
                    return a.id === dialogState.attendanceId;
                } else return selectedAttendances.includes(a.id);
            })
            .map(({ shiftId, id, history, task, sider }) => ({
                siderId: sider?.id,
                attendanceId: id,
                shiftId,
                startDate: history[0]?.startDate,
                endDate: history[0]?.startDate,
                managerId: task?.manager?._id,
                taskId: task?.id,
                breakDates: [],
                // for trackers purpose
                companyId,
                companyName,
                trackerName: "timesheet - sider did not work",
                source: "footer",
                multiple: selectedAttendances.length > 1,
                absenceJustification: dialogState.absenceJustification ?? "other",
            }));

        attendancesToDispute?.forEach((attendance) => {
            sendDisputeMutation(attendance);
        });

        // unselect attendances
        setSelectedAttendances([]);
        // hide dialog
        setDialogState(initialDialogState);
    };

    const disputeAttendance = (attendanceId, { startDate, endDate, breaks }, trackerName) => {
        const attendancetoDispute = attendances
            .filter((a) => a.id === attendanceId)
            .map(({ shiftId, id, task, sider }) => ({
                siderId: sider?.id,
                attendanceId: id,
                shiftId,
                managerId: task?.manager?._id,
                taskId: task?.id,
                startDate,
                endDate,
                breakDates: breaks,
                // for trackers purpose
                source: "panel",
                companyId,
                companyName,
                trackerName,
            }));

        attendancetoDispute?.forEach((attendance) => {
            sendDisputeMutation(attendance);
        });
    };

    const validateSelectedAttendances = () => {
        validateAttendancesMutation(selectedAttendances);

        // unselect attendances
        setSelectedAttendances([]);
        // hide dialog
        setDialogState(initialDialogState);
    };

    const displayDialog = (dialogName, attendanceId = null, absenceJustification = null) => {
        setDialogState((prevState) => ({
            ...prevState,
            [dialogName]: !prevState[dialogName],
            attendanceId,
            absenceJustification,
        }));
    };

    if (!isLoading && !attendances.length) {
        return (
            <div className='blank--centered'>
                <Blank
                    title={i18n.ts_table_no_attendances_title()}
                    subtitle={i18n.ts_table_no_attendances_description()}
                    icon='Glasses'
                    iconColor='blue'
                />
            </div>
        );
    }

    return (
        <>
            {isLoading ? (
                <div className='loader--centered'>
                    <Loader />
                </div>
            ) : null}

            <section className='timesheets-table'>
                <TimesheetsTableHeader
                    selectAll={selectAll}
                    attendancesTotalDuration={sumAttendancesNetDuration(attendances)}
                    allSelected={
                        selectedAttendances?.length ===
                            attendances.filter(
                                (a) =>
                                    a.status === ATT_WAITING && a.history[0].origin !== "company",
                            ).length && selectedAttendances?.length > 0
                    }
                />
                <TimesheetsTableBody
                    attendances={attendancesGroupedByDates}
                    selectedAttendances={selectedAttendances}
                    companyName={companyName}
                    selectAttendance={selectAttendance}
                    setSiderDidntWork={(attendanceId, absenceJustification) =>
                        displayDialog("siderDidntWork", attendanceId, absenceJustification)
                    }
                    disputeAttendance={(startDate, endDate, breaks) =>
                        disputeAttendance(startDate, endDate, breaks)
                    }
                    readOnly={logAs}
                />

                <TimesheetsTableActionBar
                    attendances={attendances}
                    selectedAttendances={selectedAttendances}
                    setSiderDidntWork={() => displayDialog("siderDidntWork")}
                    validateAttendances={() => displayDialog("validation")}
                    navigationMode={navigationMode}
                    readOnly={logAs}
                />

                {/*
        dialogs are responsible for submitting
        siderDidntWork & validateAttendance requests
      */}
                {dialogState.validation ? (
                    <Dialog
                        title={i18n.ts_modal_confirm_validation_title()}
                        validateLabel={i18n.ts_modal_confirm_submit()}
                        cancelLabel={i18n.cancel()}
                        cancelAction={() => displayDialog("validation")}
                        validateAction={validateSelectedAttendances}
                        className='timesheets-table__dialog'
                    >
                        <span className='timesheets-table__dialog__content'>
                            {i18n.ts_modal_confirm_validation_content()}
                        </span>

                        <br />

                        <Tip isWarning={true} content={i18n.ts_modal_confirm_validation_tip()} />
                    </Dialog>
                ) : null}

                {dialogState.siderDidntWork ? (
                    <Dialog
                        title={i18n.ts_modal_confirm_didntwork_title()}
                        validateLabel={i18n.ts_modal_confirm_submit()}
                        cancelLabel={i18n.cancel()}
                        cancelAction={() => displayDialog("siderDidntWork")}
                        validateAction={setSiderDidntWork}
                        className='timesheets-table__dialog'
                    >
                        <span className='timesheets-table__dialog__content'>
                            {i18n.ts_modal_confirm_didntwork_content()}
                        </span>

                        <br />

                        <Tip isWarning={true} content={i18n.ts_modal_confirm_didntwork_tip()} />
                    </Dialog>
                ) : null}
            </section>
        </>
    );
};

export default TimesheetsTable;
