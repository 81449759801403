import React from "react";
import { BlockFeedback, HeadingWithIcon, ModalsService, ValidationBlock } from "side-ui";

import { i18n } from "@lib/i18n";

import FormCard from "../../../../components/FormCard";
import { numberToHours } from "../../../../lib/utils/dates";
import RemunerationTableSection from "../partials/RemunerationTableSection";

import ExtraHours from "./ExtraHoursContainer";
import HolidaysHours from "./HolidaysHoursContainer";
import NightHours from "./NightHoursContainer";
import SundayHours from "./SundayHoursContainer";

const holidays = {
    "armistice": "Armistice",
    "assomption": "Assomption",
    "fete-de-la-victoire": "Fête de la victoire",
    "fete-du-travail": "Fête du travail",
    "fete-nationale-francaise": "Fête nationale française",
    "jeudi-de-l-ascension": "Jeudi de l'ascension",
    "jour-de-l-an": "Jour de l'an",
    "lundi-de-pentecote": "Lundi de pentecôte",
    "lundi-de-paques": "Lundi de Pâques",
    "noel": "Noël",
    "toussaint": "Toussaint",
};

class HoursTypes extends React.Component {
    getExtraSection() {
        const { remunerationInfo } = this.props;

        if (remunerationInfo.hoursTypes) {
            const extraHours = remunerationInfo.hoursTypes.extra;
            let items = [
                {
                    key: "extra_1",
                    content: i18n.settings_remuneration_hours_extra_content(),
                },
            ];
            if (extraHours.thresholds && extraHours.thresholds.length) {
                const sortedThresholds = extraHours.thresholds.sort((a, b) =>
                    a.threshold < b.threshold ? -1 : 1,
                );
                const thresholds = sortedThresholds.map((threshold) => ({
                    key: "extra" + threshold.threshold,
                    content: (
                        <ul>
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: i18n.settings_remuneration_hours_hour_threshold({
                                        count: threshold.threshold,
                                    }),
                                }}
                            />
                        </ul>
                    ),
                    value: `+${threshold.increase}%`,
                }));

                items = items.concat(thresholds);
            }

            return {
                key: `extra_hours`,
                title: i18n.settings_remuneration_hours_extra_title(),
                action: () =>
                    ModalsService.openModal({
                        id: "EXTRA_HOURS_FORM",
                        content: <ExtraHours extraHours={extraHours} />,
                    }),
                items,
            };
        }
    }

    getNightSection() {
        const { remunerationInfo } = this.props;

        if (remunerationInfo.hoursTypes) {
            const nightHours = remunerationInfo.hoursTypes.night;
            const dependsOnSeniority = nightHours.seniority && nightHours.seniority.daysThreshold;
            let items = [
                {
                    key: "seniority_1",
                    content: i18n.settings_remuneration_hours_night_content({
                        startTime: numberToHours(nightHours.startHour),
                        endTime: numberToHours(nightHours.endHour),
                    }),
                    value: !dependsOnSeniority ? `+${nightHours.increase}%` : ``,
                },
            ];

            if (dependsOnSeniority) {
                items = items.concat([
                    {
                        key: "seniority_2",
                        content:
                            nightHours.seniority &&
                            i18n.settings_remuneration_hours_before_months_seniority({
                                months: (nightHours.seniority.daysThreshold / 30)
                                    .toFixed(2)
                                    .replace(/([.,]00|0)$/, ""),
                            }),
                        value: nightHours.increase && `+${nightHours.increase}%`,
                    },
                    {
                        key: "seniority_3",
                        content:
                            nightHours.seniority &&
                            i18n.settings_remuneration_hours_after_months_seniority({
                                months: (nightHours.seniority.daysThreshold / 30)
                                    .toFixed(2)
                                    .replace(/([.,]00|0)$/, ""),
                            }),
                        value:
                            nightHours.seniority &&
                            nightHours.seniority.increase &&
                            `+${nightHours.seniority.increase}%`,
                    },
                ]);
            }
            return {
                key: `night_hours`,
                title: i18n.settings_remuneration_hours_night_title(),
                action: () => {
                    ModalsService.openModal({
                        id: "night_hours",
                        content: <NightHours nightHours={nightHours} />,
                    });
                },
                items,
            };
        }
    }

    getSundaySection() {
        const { remunerationInfo } = this.props;

        if (remunerationInfo.hoursTypes) {
            const sundayHours = remunerationInfo.hoursTypes.sundays;
            const dependsOnSeniority =
                sundayHours && sundayHours.seniority && sundayHours.seniority.daysThreshold;
            const dependsOnZti = !!sundayHours && !!sundayHours.ztiIncrease;
            let items = [
                {
                    key: "sunday_1",
                    content: i18n.settings_remuneration_hours_sunday_content(),
                    value: !dependsOnSeniority && !dependsOnZti ? `+${sundayHours.increase}%` : ``,
                },
            ];

            if (dependsOnSeniority) {
                items = items.concat([
                    {
                        key: "sunday_2",
                        content:
                            sundayHours &&
                            sundayHours.seniority &&
                            sundayHours.seniority.daysThreshold &&
                            i18n.settings_remuneration_hours_before_months_seniority({
                                months: (sundayHours.seniority.daysThreshold / 30)
                                    .toFixed(2)
                                    .replace(/([.,]00|0)$/, ""),
                            }),
                        value: `+${sundayHours.increase}%`,
                    },
                    {
                        key: "sunday_3",
                        content:
                            sundayHours &&
                            sundayHours.seniority &&
                            sundayHours.seniority.daysThreshold &&
                            i18n.settings_remuneration_hours_after_months_seniority({
                                months: (sundayHours.seniority.daysThreshold / 30)
                                    .toFixed(2)
                                    .replace(/([.,]00|0)$/, ""),
                            }),
                        value:
                            sundayHours &&
                            sundayHours.seniority &&
                            sundayHours.seniority.increase &&
                            `+${sundayHours.seniority.increase}%`,
                    },
                ]);
            }
            if (dependsOnZti) {
                items = items.concat([
                    {
                        key: "sunday_4",
                        content: i18n.settings_remuneration_hours_not_zti(),
                        value: sundayHours && `+${sundayHours.increase}%`,
                    },
                    {
                        key: "sunday_5",
                        content: i18n.settings_remuneration_hours_in_zti(),
                        value:
                            sundayHours &&
                            sundayHours.ztiIncrease &&
                            `+${sundayHours.ztiIncrease}%`,
                    },
                ]);
            }

            return {
                key: `sunday_hours`,
                title: i18n.settings_remuneration_hours_sunday_title(),
                action: () => {
                    ModalsService.openModal({
                        id: "sunday_hours",
                        content: <SundayHours sundayHours={sundayHours} />,
                    });
                },
                items,
            };
        }
    }

    getHolidaySection() {
        const { remunerationInfo } = this.props;

        if (remunerationInfo.hoursTypes) {
            const holidaysHours = remunerationInfo.hoursTypes.bankHolidays;
            const dependsOnSeniority =
                holidaysHours &&
                holidaysHours.seniority &&
                holidaysHours.seniority.daysThreshold &&
                holidaysHours.seniority.increase;
            let items = [
                {
                    key: "holiday_1",
                    content: i18n.settings_remuneration_hours_holiday_content(),
                    value: !dependsOnSeniority ? `+${holidaysHours.increase}%` : ``,
                },
            ];

            if (dependsOnSeniority) {
                items = items.concat([
                    {
                        key: "holiday_2",
                        content:
                            holidaysHours &&
                            holidaysHours.seniority &&
                            holidaysHours.seniority.daysThreshold &&
                            i18n.settings_remuneration_hours_before_months_seniority({
                                months: (holidaysHours.seniority.daysThreshold / 30)
                                    .toFixed(2)
                                    .replace(/([.,]00|0)$/, ""),
                            }),
                        value: holidaysHours && `+${holidaysHours.increase}%`,
                    },
                    {
                        key: "holiday_3",
                        content:
                            holidaysHours &&
                            holidaysHours.seniority &&
                            holidaysHours.seniority.daysThreshold &&
                            i18n.settings_remuneration_hours_after_months_seniority({
                                months: (holidaysHours.seniority.daysThreshold / 30)
                                    .toFixed(2)
                                    .replace(/([.,]00|0)$/, ""),
                            }),
                        value:
                            holidaysHours &&
                            holidaysHours.seniority &&
                            `+${holidaysHours.seniority.increase}%`,
                    },
                ]);
            }

            items = items.concat([
                {
                    key: "holiday_4",
                    content: i18n.settings_remuneration_hours_holiday_solidarity({
                        solidarity:
                            holidaysHours.solidarity && holidaysHours.solidarity?.length !== 0
                                ? holidays[holidaysHours.solidarity]
                                : i18n.settings_remuneration_hours_holiday_solidarity_undefined(),
                    }),
                },
            ]);

            return {
                key: `holiday_hours`,
                title: i18n.settings_remuneration_hours_holiday_title(),
                action: () => {
                    ModalsService.openModal({
                        id: "HOLIDAYS_HOURS_FORM",
                        content: <HolidaysHours holidaysHours={holidaysHours} />,
                    });
                },
                items,
            };
        }
    }

    getSections() {
        const extraSections = this.getExtraSection();
        const nightSections = this.getNightSection();
        const sundaySections = this.getSundaySection();
        const holidaySections = this.getHolidaySection();

        const indemnitiesSections = [
            {
                key: "normal_hours",
                title: i18n.settings_remuneration_hours_normal_title(),
                items: [
                    {
                        key: "normal",
                        content: i18n.settings_remuneration_hours_normal_content(),
                        value: "-",
                    },
                ],
                locked: true,
            },
            {
                key: "unworked_bank_holidays",
                title: i18n.settings_remuneration_non_worked_holidays_title(),
                items: [
                    {
                        key: "unworked_bank_holidays_normal",
                        content: (
                            <>
                                {i18n.settings_remuneration_non_worked_holidays_help()}&nbsp;
                                <a
                                    target='_blank'
                                    href='https://support.side.co/fr/articles/3665699-jours-feries-travailles-et-non-travailles-comment-ca-marche'
                                    rel='noreferrer'
                                >
                                    {i18n.settings_remuneration_non_worked_holidays_help_link()}
                                </a>
                            </>
                        ),
                        value: "-",
                    },
                ],
                locked: true,
            },
            extraSections,
            nightSections,
            sundaySections,
            holidaySections,
        ];

        return indemnitiesSections.map((section) => (
            <RemunerationTableSection
                key={section.key}
                tooltipContent={i18n.settings_remuneration_hours_edit_tooltip()}
                {...section}
            />
        ));
    }

    render() {
        const { remunerationInfo, confirmHoursTypes, areHoursTypesConfirmed } = this.props;

        const sections = this.getSections();

        if (remunerationInfo.hoursTypes && !remunerationInfo.hoursTypes.valid) {
            return (
                <div id='hour-types' data-hash='hour-types' className='settings__section'>
                    <HeadingWithIcon
                        icon='ClockXL'
                        label={i18n.settings_remuneration_hours_title()}
                    />
                    <p>{i18n.settings_remuneration_hours_subtitle()}</p>
                    <BlockFeedback
                        type='info'
                        content={i18n.settings_remuneration_hours_types_custom_disclaimer()}
                    />
                </div>
            );
        }

        return (
            <div
                id='hour-types'
                data-hash='hour-types'
                className='settings__section typography-body-m-regular'
            >
                <HeadingWithIcon icon='ClockXL' label={i18n.settings_remuneration_hours_title()} />
                <p className='mt-4'>{i18n.settings_remuneration_hours_subtitle()}</p>
                {!areHoursTypesConfirmed && (
                    <ValidationBlock
                        title={i18n.settings_remuneration_hours_validation_title()}
                        content={i18n.settings_remuneration_hours_validation_content()}
                        checkboxLabel={i18n.settings_remuneration_hours_validation_label()}
                        buttonLabel={i18n.settings_remuneration_hours_validation_button()}
                        buttonAction={confirmHoursTypes}
                    />
                )}
                <div className='remuneration-table'>
                    <div className='remuneration-table__thead'>
                        <div className='remuneration-table__tr'>
                            <div className='remuneration-table__th'>
                                {i18n.settings_remuneration_hours_table_type()}
                            </div>
                            <div className='remuneration-table__th'>
                                {i18n.settings_remuneration_hours_table_value()}
                            </div>
                        </div>
                    </div>
                    <div className='remuneration-table__tbody'>
                        {sections.length !== 0 && sections}
                    </div>
                </div>
                <FormCard>
                    <p>{i18n.settings_remuneration_hours_help()}</p>
                </FormCard>
            </div>
        );
    }
}

export default HoursTypes;
