import { Tabs } from "saphir";

import { i18n } from "@lib/i18n";
import { Link, Outlet, useLocation } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

export function ScrollableSettingsContent({ children }) {
    return (
        <div className='flex justify-center overflow-y-auto py-8'>
            <div className='min-h-fit w-full max-w-[660px] rounded bg-white'>{children}</div>
        </div>
    );
}
function Settings() {
    const { pathname } = useLocation();
    const isDepartmentsActive = useFlag("services-departments");

    const allowCompany = localStorage.getItem("side_team_activeOrganisationId") !== null;

    const items = [
        ...(allowCompany
            ? [
                  {
                      to: "/settings/legal",
                      label: i18n.settings_nav_legal_info(),
                  },
                  {
                      to: "/settings/payment",
                      label: i18n.settings_nav_payment_info(),
                  },
                  {
                      to: "/settings/remuneration",
                      label: i18n.settings_nav_payment_info_remuneration(),
                  },
                  {
                      to: "/settings/profile",
                      label: i18n.settings_nav_company_info(),
                  },
              ]
            : []),
        {
            to: "/settings/team",
            label: i18n.header_dropdown_team(),
        },
        ...(isDepartmentsActive
            ? [
                  {
                      to: "/settings/departments",
                      label: i18n.header_dropdown_departments(),
                  },
              ]
            : []),
    ];

    return (
        <div className='flex h-screen flex-col'>
            <Tabs.Root defaultValue={pathname} className='border-b border-blue-50 bg-white pl-4'>
                {items.map((item) => (
                    <Tabs.List key={item.to}>
                        <Link to={item.to}>
                            <Tabs.Trigger value={item.to} asChild>
                                {item.label}
                            </Tabs.Trigger>
                        </Link>
                    </Tabs.List>
                ))}
            </Tabs.Root>
            <Outlet />
        </div>
    );
}

export default Settings;
