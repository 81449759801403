import { differenceInMinutes } from "date-fns";
import { groupBy } from "lodash";

import { sumShiftBreaks } from "../../../utils";

import { DateCell, TaskCell } from "./TimesheetsTableCells";
import TimesheetsTableNestedRow from "./TimesheetsTableNestedRow";

const TimesheetsTableRow = ({
    startDate,
    companyName,
    attendances,
    selectAttendance,
    selectedAttendances,
    setSiderDidntWork,
    disputeAttendance,
    readOnly,
}) => {
    const attendancesGroupedByShiftId = groupBy(attendances, "shiftId");

    return (
        <section className='timesheets-table__row'>
            <div className='timesheets-table__cell--mobile'>
                <DateCell startDate={startDate} />
            </div>
            <div className='timesheets-table__row__wrapper'>
                <DateCell startDate={startDate} />

                <div className='timesheets-table__cell__wrapper--vertical'>
                    {Object.keys(attendancesGroupedByShiftId).map((shiftId) => (
                        <TaskCell
                            key={shiftId}
                            taskName={attendancesGroupedByShiftId[shiftId][0].task?.name}
                            startDate={attendancesGroupedByShiftId[shiftId][0].history[0].startDate}
                            endDate={attendancesGroupedByShiftId[shiftId][0].history[0].endDate}
                            breakSum={sumShiftBreaks(
                                attendancesGroupedByShiftId[shiftId][0].history[0].breaks,
                            )}
                            attendancesLength={attendancesGroupedByShiftId[shiftId].length}
                        />
                    ))}
                </div>

                <div className='timesheets-table__cell__wrapper--horizontal'>
                    {Object.keys(attendancesGroupedByShiftId).map((shiftId) =>
                        attendancesGroupedByShiftId[shiftId].map(
                            (
                                {
                                    id,
                                    sider,
                                    history,
                                    status,
                                    task,
                                    feedback,
                                    absenceJustification,
                                },
                                index,
                            ) => {
                                const shiftHistory = history.find((h) => h.origin === "shift");
                                const currentVersion = history[history.length - 1];
                                const { breaks: shiftBreaks } = currentVersion; // origin === shift history
                                const breakSumInMinutes =
                                    shiftBreaks && shiftBreaks.length
                                        ? sumShiftBreaks(shiftBreaks)
                                        : 0;

                                const companyValidatedSiderDidntWork = () => {
                                    // We comment this code because it blocks the flexible planning flow and it is not clear what it does

                                    // // find history actions validation by company
                                    // const companyUpdate = history.find(({ actions }) =>
                                    //     []
                                    //         .concat(actions)
                                    //         .reverse()
                                    //         .find(
                                    //             (action: { type?: string; origin?: string }) =>
                                    //                 action?.type === "modification" &&
                                    //                 action?.origin === "company",
                                    //         ),
                                    // );
                                    // if (!companyUpdate || status === ATT_VALIDATED) {
                                    //     return false;
                                    // }

                                    // checking if sider didn't work meaning if startDate === endDate
                                    const historyDidntWork = history.find(
                                        ({ startDate: slotStartDate, endDate }) =>
                                            slotStartDate === endDate,
                                    );
                                    if (historyDidntWork) {
                                        return {
                                            ...historyDidntWork,
                                            absenceJustification, // absenceJustification from attendance root is the one we need
                                        };
                                    }
                                    return false;
                                };

                                /** Compares breaks sums
                                 * @returns number 0 if none
                                 */
                                const hasBreakConflict = () =>
                                    Math.abs(
                                        sumShiftBreaks(currentVersion.breaks) -
                                            sumShiftBreaks(shiftHistory.breaks),
                                    );

                                /**
                                 * Compares origin shift with current version history
                                 * @returns Object
                                 */
                                const hasSlotConflict = () => ({
                                    startDate: Math.abs(
                                        differenceInMinutes(
                                            new Date(currentVersion.startDate),
                                            new Date(shiftHistory.startDate),
                                        ),
                                    ),
                                    endDate: Math.abs(
                                        differenceInMinutes(
                                            new Date(currentVersion.endDate),
                                            new Date(shiftHistory.endDate),
                                        ),
                                    ),
                                });

                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div
                                        className='timesheets-table__cell__wrapper--inner'
                                        key={`${id}-${index}`}
                                    >
                                        <div className='timesheets-table__cell__wrapper--vertical--mobile'>
                                            <TaskCell
                                                key={shiftId}
                                                taskName={
                                                    attendancesGroupedByShiftId[shiftId][0].task
                                                        ?.name
                                                }
                                                startDate={
                                                    attendancesGroupedByShiftId[shiftId][0]
                                                        .history[0].startDate
                                                }
                                                endDate={
                                                    attendancesGroupedByShiftId[shiftId][0]
                                                        .history[0].endDate
                                                }
                                                breakSum={sumShiftBreaks(
                                                    attendancesGroupedByShiftId[shiftId][0]
                                                        .history[0].breaks,
                                                )}
                                                attendancesLength={
                                                    attendancesGroupedByShiftId[shiftId].length
                                                }
                                            />
                                        </div>
                                        <TimesheetsTableNestedRow
                                            key={id}
                                            id={id}
                                            status={status}
                                            history={history}
                                            sider={sider}
                                            task={task}
                                            feedback={feedback}
                                            slotConflict={hasSlotConflict()}
                                            breakConflict={hasBreakConflict()}
                                            breakSumInMinutes={breakSumInMinutes}
                                            selectAttendance={selectAttendance}
                                            selectedAttendances={selectedAttendances}
                                            taskName={task?.name}
                                            siderDidntWork={companyValidatedSiderDidntWork()}
                                            setSiderDidntWork={setSiderDidntWork}
                                            disputeAttendance={disputeAttendance}
                                            // trackers prop
                                            companyName={companyName}
                                            shiftId={shiftId}
                                            // end trackers prop
                                            readOnly={readOnly}
                                        />
                                    </div>
                                );
                            },
                        ),
                    )}
                </div>
            </div>
        </section>
    );
};

export default TimesheetsTableRow;
