import { useEffect, useRef, useState } from "react";
import { Plus } from "lucide-react";
import { TextField, toast } from "saphir";
import { ModalsService } from "side-ui";

import * as backend from "@lib/api";
import { i18n } from "@lib/i18n";
import {
    addressAdapter,
    addressComponentsAdapter,
    keyPressHandler,
} from "@routes/JobDescriptions/utils";

import AddressDescription from "./AddressDescription";
import FavoriteLocationForm from "./FavoriteLocationForm";
import FavoriteLocationsList from "./FavoriteLocationsList";

import "./AddressForm.scss";

const AddressForm = ({ location = {}, submitAddress }) => {
    const initialState = {
        description: "",
        address: {
            placesSearchText: location ? addressAdapter(location) : "",
            formattedAddress: location ? addressAdapter(location) : "",
        },
        googlePlaces: [],
        suggestionsOpen: false,
        errors: null,
    };
    const [state, setState] = useState(initialState);
    const [displayFavoriteLocationDropdown, setDisplayFavoriteLocationDropdown] = useState(false);

    const [favoriteLocations, setFavoriteLocations] = useState([]);

    // getLocations and setFavorites
    useEffect(() => {
        backend
            .getLocations({
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
            })
            .then((res) => {
                if (res) {
                    setFavoriteLocations(
                        res.locations.filter((locationItem) => locationItem.favorite === true),
                    );
                }
            });
    }, []);

    // re-render when location
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            description: location?.description,
        }));
    }, [location]);

    const placesRef = useRef(null);
    const addressFormRef = useRef(null);

    function handleClickOutside({ target }) {
        if (!addressFormRef.current) {
            return;
        }
        // not ideal InputText doest not forward ref so it's necessary
        const suiInputContainer = document.querySelector(
            ".location-form__address .sui-input-container",
        );

        if (
            !addressFormRef.current.contains(target) ||
            target === addressFormRef.current ||
            target === suiInputContainer
        ) {
            // close suggestions if clicked outside the addressForm
            // or if target is the actual addressForm
            // or target is suiInputContainer (which happens)
            setDisplayFavoriteLocationDropdown(false);
        }
    }

    function createFavoriteLocation(locationData) {
        backend
            .createLocation({
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                location: locationData,
            })
            .then((res) => {
                if (res) {
                    backend
                        .getLocations({
                            organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                        })
                        .then((response) => {
                            if (response) {
                                setFavoriteLocations(
                                    response.locations.filter(
                                        (locationItem) => locationItem.favorite === true,
                                    ),
                                );
                                toast.success(i18n.location_form_favorite_list_addition());
                            }
                        });
                }
            });
    }

    function updateLocation(locationData, showToast = false) {
        backend
            .updateLocation({
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                location: locationData,
            })
            .then((res) => {
                if (res) {
                    backend
                        .getLocations({
                            organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                        })
                        .then((response) => {
                            if (response && showToast) {
                                setFavoriteLocations(
                                    response.locations.filter(
                                        (locationItem) => locationItem.favorite === true,
                                    ),
                                );
                                toast.success(i18n.location_form_favorite_list_update());
                            }
                        });
                }
            });
    }

    useEffect(() => {
        if (addressFormRef?.current) {
            document.addEventListener("click", handleClickOutside);
        }

        return () => {
            if (addressFormRef?.current) {
                return document.removeEventListener("click", handleClickOutside);
            }

            return null;
        };
    }, [addressFormRef.current, displayFavoriteLocationDropdown]);

    function handleAddressChange(event) {
        const { value } = event.target;

        if (!value) {
            setState((prevState) => ({
                ...prevState,
                address: {
                    address: "",
                    placesSearchText: "",
                },
                googlePlaces: [],
            }));

            return;
        }

        setState((prevState) => ({
            ...prevState,
            address: {
                ...prevState.address,
                placesSearchText: value,
                // set back formatted address to invalidate the form
                formattedAddress: "",
            },
        }));

        if (!window.google || !window.google.maps) {
            // set gmaps errors
            setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    gmaps: {
                        valid: false,
                        notLoaded: true,
                    },
                },
            }));

            return;
        }

        const query = {
            componentRestrictions: {
                country: ["fr"],
            },
            input: value,
        };
        const service = new window.google.maps.places.AutocompleteService();

        service.getPlacePredictions(query, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const result = predictions.map((prediction) => ({
                    value: prediction.description,
                    placeId: prediction.place_id,
                }));

                setState((prevState) => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                    },
                    googlePlaces: result,
                    suggestionsOpen: true,
                }));
            }
        });
    }

    function selectAddress(place) {
        // eslint-disable-line consistent-return
        try {
            const PlacesService = new window.google.maps.places.PlacesService(placesRef.current);

            PlacesService.getDetails(
                {
                    placeId: place.placeId,
                    fields: ["geometry", "address_component"],
                },
                (result) => {
                    const addressData = addressComponentsAdapter(result.address_components);
                    const formattedAddress = addressAdapter(addressData) || place.value;

                    setState((prevState) => ({
                        ...prevState,
                        address: {
                            ...prevState.address,
                            ...addressData,
                            placesSearchText: formattedAddress,
                            formattedAddress,
                            lat: result.geometry.location.lat(),
                            lng: result.geometry.location.lng(),
                        },
                        googlePlaces: [],
                        suggestionsOpen: false,
                        errors: null,
                    }));

                    backend
                        .createLocation({
                            organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                            location: {
                                ...addressData,
                                address: formattedAddress,
                                lat: result.geometry.location.lat(),
                                lng: result.geometry.location.lng(),
                                favorite: false,
                            },
                        })
                        .then((locRes) => {
                            submitAddress({
                                ...locRes,
                                address: formattedAddress,
                                lat: result.geometry.location.lat(),
                                lng: result.geometry.location.lng(),
                                favorite: false,
                            });
                        });

                    setDisplayFavoriteLocationDropdown(false);
                },
            );
        } catch (e) {
            toast.error(i18n.siderprofile_update_error_gmaps_place_not_found());
        }
    }

    function selectFavoriteAddress(favoriteLocation) {
        ["description", "favorite", "id", "online", "organisationId", "title"].forEach(
            (key) => delete favoriteLocation[key],
        );

        setState((prevState) => ({
            ...prevState,
            address: {
                ...favoriteLocation,
                placesSearchText: favoriteLocation.address,
                formattedAddress: favoriteLocation.address,
            },
            googlePlaces: [],
            suggestionsOpen: false,
            errors: null,
        }));

        backend
            .createLocation({
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                location: {
                    ...favoriteLocation,
                    favorite: false,
                },
            })
            .then((locRes) => {
                submitAddress({
                    ...locRes,
                    favorite: false,
                });
            });

        setDisplayFavoriteLocationDropdown(false);
    }

    function selectAddressOnKeyPress(event, place) {
        if (event.key === "Enter") {
            selectAddress(place);
        }
    }

    function handleEscapeKeyPress(event, callback) {
        if (event.key === "Escape") {
            callback();
        }
    }

    function displayFavoriteFormModal() {
        ModalsService.openModal({
            id: "FAVORITE_LOCATION_FORM_MODAL",
            content: (
                <FavoriteLocationForm
                    modalTitle={i18n.location_form_workplace_favorite_title()}
                    validateLabel={i18n.save()}
                    placesRef={placesRef}
                    hideModal={() => ModalsService.closeModal(`FAVORITE_LOCATION_FORM_MODAL`)}
                    submitAddress={(favoriteLocation) => {
                        createFavoriteLocation(favoriteLocation);
                        ModalsService.closeModal(`FAVORITE_LOCATION_FORM_MODAL`);
                        setDisplayFavoriteLocationDropdown(true);
                    }}
                    cancelAction={() => ModalsService.closeModal(`FAVORITE_LOCATION_FORM_MODAL`)}
                />
            ),
        });
    }

    function displayFavoriteListModal() {
        ModalsService.openModal({
            id: "FAVORITE_LOCATION_LIST_MODAL",
            content: (
                <FavoriteLocationsList
                    placesRef={placesRef}
                    hideModal={() => ModalsService.closeModal(`FAVORITE_LOCATION_LIST_MODAL`)}
                    favoriteLocations={favoriteLocations}
                    cancelAction={() => ModalsService.closeModal(`FAVORITE_LOCATION_LIST_MODAL`)}
                    submitAddress={(favoriteLocation) => {
                        updateLocation(favoriteLocation, true);
                        setDisplayFavoriteLocationDropdown(true);
                        ModalsService.closeModal(`FAVORITE_LOCATION_LIST_MODAL`);
                    }}
                    createAddress={(favoriteLocation) => {
                        createFavoriteLocation(favoriteLocation);
                        setDisplayFavoriteLocationDropdown(true);
                        ModalsService.closeModal(`FAVORITE_LOCATION_LIST_MODAL`);
                    }}
                />
            ),
        });
    }

    return (
        <div ref={addressFormRef} className='job-description__step location-form__address'>
            <div className='location-form__address__form__group'>
                {/*
          do not remove below span element

          strangely, calls to google.maps.places.PlacesService
          are impossible without a map or an HTML element
        */}
                <span ref={placesRef} />
                <TextField
                    label={i18n.location_form_workplace_label1()}
                    placeholder={i18n.location_form_workplace_placeholder1()}
                    onChange={(e) => {
                        handleAddressChange(e);

                        // display favorites back if value is deleted
                        if (!e.target.value.length) {
                            setDisplayFavoriteLocationDropdown(true);
                        }
                    }}
                    name='gmaps'
                    required={true}
                    error={state?.errors?.input}
                    smallInput={true}
                    value={state.address.placesSearchText}
                    onKeyDown={(e) =>
                        keyPressHandler(e, "Escape", () =>
                            setDisplayFavoriteLocationDropdown(false),
                        )
                    }
                    onFocus={
                        !state.address.placesSearchText.length
                            ? () => setDisplayFavoriteLocationDropdown(true)
                            : () => null
                    }
                />

                {state.suggestionsOpen || displayFavoriteLocationDropdown === true ? (
                    <div className='location-form__address__places'>
                        <>
                            <div
                                role='list'
                                className={`location-form__address__places__list ${
                                    state.suggestionsOpen ||
                                    displayFavoriteLocationDropdown === true
                                        ? // blame ESlint for this indentation ¯\_(ツ)_/¯
                                          "location-form__address__places__list--visible--favorite"
                                        : "location-form__address__places__list--hidden"
                                } `}
                            >
                                {/* no favorite locations: display the add button */}
                                {/* even if there are maps suggestions */}
                                {!favoriteLocations.length ? (
                                    <div
                                        className='location-form__address__favorite__item'
                                        role='listitem'
                                    >
                                        <button
                                            type='button'
                                            onClick={() => {
                                                displayFavoriteFormModal();
                                                setDisplayFavoriteLocationDropdown(false);
                                            }}
                                            onKeyDown={(e) =>
                                                handleEscapeKeyPress(e, () =>
                                                    setDisplayFavoriteLocationDropdown(false),
                                                )
                                            }
                                            className='location-form__address__favorite__button location-form__address__favorite__list__item location-form__address__favorite__button--add'
                                        >
                                            <Plus />
                                            <span className='button-label'>
                                                {i18n.location_form_workplace_favorite_title()}
                                            </span>
                                        </button>
                                    </div>
                                ) : null}

                                {/* if no search text and display favorite locations */}
                                {!state.address.placesSearchText.length &&
                                favoriteLocations.length ? (
                                    <>
                                        {/* favorite locations and search text => display the update button */}
                                        <div
                                            className='location-form__address__favorite__list__item location-form__address__favorite__button--update'
                                            role='button'
                                            onClick={() => displayFavoriteListModal()}
                                            tabIndex='0'
                                            onKeyDown={(e) => {
                                                keyPressHandler(e, "Enter", () =>
                                                    setDisplayFavoriteLocationDropdown(true),
                                                );
                                                keyPressHandler(e, "Escape", () =>
                                                    setDisplayFavoriteLocationDropdown(false),
                                                );
                                            }}
                                        >
                                            <span className='location-form__address__favorite__button__label'>
                                                {i18n.location_form_workplace_favorite_label()}
                                            </span>

                                            <span className='location-form__address__favorite__button__label'>
                                                {i18n.modify()}
                                            </span>
                                        </div>

                                        <div className='location-form__address__places__list location-form__address__places__list--visible location-form__address__places__list__scroller'>
                                            {favoriteLocations.map((favoriteLocation) => (
                                                <div key={favoriteLocation.id} role='listitem'>
                                                    <button
                                                        type='button'
                                                        className='location-form__address__favorite__list__item location-form__address__favorite__list__item__button'
                                                        onClick={() => {
                                                            selectFavoriteAddress({
                                                                ...favoriteLocation,
                                                            });
                                                        }}
                                                    >
                                                        {favoriteLocation.title && (
                                                            <div className='location-form__address__favorite-title'>
                                                                {favoriteLocation.title}
                                                            </div>
                                                        )}
                                                        <div>{favoriteLocation.address}</div>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : null}

                                {state.googlePlaces.length > 0
                                    ? state.googlePlaces.map((place) => (
                                          <div
                                              key={place.placeId}
                                              className='location-form__address__places__list__item'
                                              role='button' // eslint-disable-line
                                              onClick={() => selectAddress(place)}
                                              onKeyDown={(event) =>
                                                  selectAddressOnKeyPress(event, place)
                                              }
                                              tabIndex='0'
                                          >
                                              {place.value}
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </>
                    </div>
                ) : null}
            </div>

            <AddressDescription
                description={state.description}
                submitAddress={({ description }) => {
                    updateLocation({ ...location, description });
                }}
            />
        </div>
    );
};

export default AddressForm;
