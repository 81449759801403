import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export const DepartmentSchema = z.object({
    id: z.string(),
    organisationId: z.string(),
    name: z.string(),
    code: z.string().optional(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime().nullable().optional(),
    deletedAt: z.string().datetime().nullable().optional(),
});

const GetCompanyDepartmentsResponseSchema = DepartmentSchema.array();
export type GetCompanyDepartmentsResponse = z.infer<typeof GetCompanyDepartmentsResponseSchema>;

const message = "Get Company Departments";
export async function getCompanyDepartments(): Promise<GetCompanyDepartmentsResponse> {
    debug.addBreadcrumb({
        message,
        category: `action`,
    });
    const organisationId = localStorage.getItem(`side_team_activeOrganisationId`) as string;

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/organisations/${organisationId}/departments?status=active`,
            ),
        );

        if (resp.status >= 400) {
            return Promise.reject(new Error(message));
        }

        return GetCompanyDepartmentsResponseSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(e);
    }
}
